import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { GridOptions } from 'ag-grid-community';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { GridService } from 'src/app/shared/grid.service';
import { MessageService } from 'src/app/shared/message.service';
import { PermissionsService } from 'src/app/shared/permissions.service';
import { ViewDetailButtonComponent } from 'src/app/shared/view-detail-button/view-detail-button.component';
import { DeleteButtonComponent } from 'src/app/shared/delete-button/delete-button.component';
import { AuthenticationService } from 'src/app/core/authentication/shared/authentication.service';
import { AdminContactsService } from 'src/app/shared/admin-contacts.service';

@Component({
  selector: 'app-admin-admin-notification-contacts-list',
  templateUrl: './admin-admin-notification-contacts-list.component.html',
  styleUrl: './admin-admin-notification-contacts-list.component.css'
})
export class AdminAdminNotificationContactsListComponent {
  @ViewChild('agGrid') agGrid: AgGridAngular;

  adminDetailRef: MatDialogRef<AdminAdminNotificationContactsDetailDialog>;
  confirmationDialogRef: MatDialogRef<ConfirmationDialogComponent>;

  gridOptions: GridOptions;
  quickFilterText: string = '';
  context; //required by grid
  //frameworkComponents; //required by grid
  allPrivateLabels;
  permissions: any = {};
  myUser;
  userIdsInList = [];

  rowData = [];

  getColumnDefs(){
    return [
      {
        headerName: "",
        field: "action",
        width: 70,
        minWidth: 70,
        maxWidth: 90,
        cellClass: "action-col",
        cellRenderer: this.permissions.roleAllowsUserToEditAdminNotificationContacts ? "ViewDetailButtonComponent" : null,
        pinned: "left",
        sortable: false,
        filter: false,
        suppressHeaderMenuButton: true,
        suppressNavigable: true,
        hide: !this.permissions.roleAllowsUserToEditAdminNotificationContacts,
      },
			{
				headerName: "First Name",
				field: "firstName",
				cellClass: "firstName-col",
        filter: "agTextColumnFilter",
        get headerTooltip() { return this.headerName; }
			},
			{
				headerName: "Last Name",
				field: "lastName",
				cellClass: "lastName-col",
        filter: "agTextColumnFilter",
        get headerTooltip() { return this.headerName; }
			},
      {
        headerName: "Email",
        field: "emailAddress",
        cellClass: "email-col",
        filter: "agTextColumnFilter",
        get headerTooltip() { return this.headerName; }
      },
			{
				headerName: "Phone Number",
				field: "phoneNumber",
				cellClass: "phoneNumber-col",
        cellRenderer: this._gridService.phoneNumberRenderer,
        filter: "agTextColumnFilter",
        get headerTooltip() { return this.headerName; }
			},
			{
				headerName: "Active",
				field: "active",
				cellClass: "active-col tac",
        cellRenderer: this._gridService.checkRenderer,
        filter: "agSetColumnFilter",
        get headerTooltip() { return this.headerName; }
			},
			{
				headerName: "Was Manually Entered",
				field: "wasManuallyEntered",
				cellClass: "wasManuallyEntered-col tac",
        cellRenderer: this._gridService.checkRenderer,
        filter: "agSetColumnFilter",
        get headerTooltip() { return this.headerName; }
			},
      {
        headerName: "",
        field: "delete",
        width: 80,
        minWidth: 80,
        maxWidth: 80,
        cellClass: ["delete-col", "tac"],
        cellRenderer: this.permissions.roleAllowsUserToEditAdminNotificationContacts && !this.myUser.hasReadOnlyRestriction ? "DeleteButtonComponent" : null,
        sortable: false,
        filter: false,
        suppressHeaderMenuButton: true,
        suppressNavigable: true,
        hide: !this.permissions.roleAllowsUserToEditAdminNotificationContacts
      }

    ];
  }
  
  constructor(
    private _adminContactsService: AdminContactsService,
    private _authenticationService: AuthenticationService,
    private _gridService: GridService,
    private _dialog: MatDialog, 
    private _messageService: MessageService,
    private _permissionsService: PermissionsService,
    ) {
    this.myUser = this._authenticationService.getMyUser();
    this.permissions = this._permissionsService.getPermissions();
    this.gridOptions = <GridOptions> {
			columnDefs: this.getColumnDefs(),
			rowData: this.rowData,
      rowHeight: 35,    
      components: {
        ViewDetailButtonComponent: ViewDetailButtonComponent,
        DeleteButtonComponent: DeleteButtonComponent,
      },
      defaultColDef: {
        sortable: true,
        resizable: true,
        floatingFilter: true,
      }
    }
    this.context = { componentParent: this }
  }

  ngOnInit() {
    this.getAllAdminContacts();
  }

  getAllAdminContacts(){
    this._adminContactsService.getAllAdminContacts()
      .subscribe(
        (data: any) => {
          this.userIdsInList = data.filter(u => u.userId != null).map(u => u.userId);
          //this.userIdsInList = ["2e74cf5c-bcb5-45a7-826c-c9e329ef8bbc", "76a0ced3-308f-41fe-a601-0f23243e9af3"];
          this.formatandSetRowData(data);
        },
        (response) => {
          this._messageService.onFailure('Failed to get announcements.', response);
        })
  }

  formatandSetRowData(data) {
    var gridData = [];
    //data = this.mockData;
    for (var i=0; i<data.length; i++){
      var singleRowData = {};
      let contact = data[i].user != null ? data[i].user : data[i];
      singleRowData = {
        adminContactId: data[i].adminContactId,
        userId: data[i].userId,
        firstName: contact.firstName,
        lastName: contact.lastName,
        fullName: contact.fullName,
        phoneNumber: contact.phoneNumber,
        emailAddress: contact.emailAddress || contact.email,
        active: data[i].active,
        deleteButtonTooltip: contact.userId != null? 'Remove User From Contact List' : 'Delete User From Contact List',
        deleteButtonIcon: contact.userId != null? 'fas fa-minus' : 'fas fa-times',
        wasManuallyEntered: data[i].userId == null,
        // detailButtonTooltip: contact.userId != null? 'This user must be edited in the User Accounts section':'View Detail',
        // disableDetailButton: (contact.userId != null),
    };
      gridData.push(singleRowData);
    }
    this.rowData = gridData;
    //this.rowData = this.mockData;
    this._gridService.onWindowResize('#ag-grid-wrapper');
    setTimeout(() => {
      this.fitColumns();
      this._gridService.sizeGrid('#ag-grid-wrapper');
    }, 100); 
  }

  clearFilters(){
    this.quickFilterText = '';
    this.agGrid.api.setGridOption('quickFilterText', this.quickFilterText);
    this.agGrid.api.setFilterModel(null);
    this.agGrid.api.onFilterChanged();
  }

  resetColumns(){
    this.agGrid.api.resetColumnState();
    this.fitColumns(); //since this is done by default also
  }

  autoSizeColumns(){
    var columns = this.agGrid.api.getColumns();
    this.agGrid.api.autoSizeColumns(columns);
  }

  fitColumns(){
    this.agGrid.api.sizeColumnsToFit();
  }

  onQuickFilterChange(){
    this.agGrid.api.setGridOption('quickFilterText', this.quickFilterText);
  }

  onDetailButtonClick(params){
    this.adminDetailRef = this._dialog.open(AdminAdminNotificationContactsDetailDialog, {
      width: '950px',
      //height: '600px',
      data: {
        id: params.data.adminContactId,
        userId: params.data.userId,
      }
    });
    this.adminDetailRef.afterClosed().subscribe(result => {
      if(result === 'reload') {
        this.getAllAdminContacts();
      }
      this.adminDetailRef = null;
    });
  }

  onDeleteButtonClick(params){
    //Users with hasReadOnlyRestriction set to true should not even be allowed to invoke this action because it is hidden or disabled in the UI.
    //This is for extra protection. The back end will also be protected.
    if (this.myUser.hasReadOnlyRestriction){
      this._messageService.alertReadOnlyRestriction();
      return;
    }

    let msg = params.data.userId != null? 
      'Do you want to remove "' + params.data.fullName + '" from the Admin Contacts list?' : 
      'Do you want to delete "' + params.data.fullName + '" from the Admin Contacts list?';

    let confirmTxt = params.data.userId != null? 
      'Yes, remove it' : 
      'Yes, delete it';
    
    this.confirmationDialogRef = this._dialog.open(ConfirmationDialogComponent, {
      disableClose: false,
      //width: '600px',
      data: {
        title: 'Are you sure?',
        message: msg,
        confirmText: confirmTxt,
        cancelText: 'No, cancel this action!'
      }
    });
    this.confirmationDialogRef.afterClosed().subscribe(result => {
      if(result) {
        this._adminContactsService.deleteAdminContact(params.data.adminContactId)
          .subscribe(
            (data) => {
              this._messageService.onSuccess('Successfully removed contact.');
              this.getAllAdminContacts();
            },
            (response) => {
              this._messageService.onFailure('Failed to remove contact.', response);
            }
          )
      }
      this.confirmationDialogRef = null;
    });
  }

  goToCreateContact(){
    this.adminDetailRef = this._dialog.open(AdminAdminNotificationContactsDetailDialog, {
      width: '850px',
      //height: '600px',
      data: {
        id: 0,
        userIdsInList: this.userIdsInList,
      }
    });
    this.adminDetailRef.afterClosed().subscribe(result => {
      if(result === 'reload') {
        this.getAllAdminContacts();
      }
      this.adminDetailRef = null;
    });
  }

}

@Component({
  selector: 'app-admin-admin-notification-contacts-detail-dialog',
  template: `<rpms-admin-admin-notification-contacts-detail [id]="data.id" [userId]="data.userId" [userIdsInList]="data.userIdsInList"></rpms-admin-admin-notification-contacts-detail>`,
})
export class AdminAdminNotificationContactsDetailDialog {

  constructor(
    public dialogRef: MatDialogRef<AdminAdminNotificationContactsDetailDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

}