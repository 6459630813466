import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-delete-button',
  template: `<span><button style="height: 22px" (click)="invokeParentMethod()" class="btn btn-info" title="{{params.data.deleteButtonTooltip? params.data.deleteButtonTooltip:'Delete'}}"><i class="{{params.data.deleteButtonIcon? params.data.deleteButtonIcon:'fas fa-times'}}"></i></button></span>`,
  styles: [
    `.btn {
        line-height: 0.5;
        background:#f00 !important;
        color:#fff;
      }`
  ]
})
export class DeleteButtonComponent implements ICellRendererAngularComp  {

  public params: any;

  agInit(params: any): void {
      this.params = params;
  }

  public invokeParentMethod() {
      this.params.context.componentParent.onDeleteButtonClick(this.params);
  }

  refresh(): boolean {
      return false;
  }

}
