import { Component, Inject, Input, LOCALE_ID } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { AdminAdminNotificationContactsDetailDialog } from '../admin-admin-notification-contacts-list/admin-admin-notification-contacts-list.component';
import { MessageService } from 'src/app/shared/message.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { UserService } from 'src/app/shared/user.service';
import { AuthenticationService } from 'src/app/core/authentication/shared/authentication.service';
import { AdminContactsService } from 'src/app/shared/admin-contacts.service';

const EMAIL_REGEX = /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/

@Component({
  selector: 'rpms-admin-admin-notification-contacts-detail',
  templateUrl: './admin-admin-notification-contacts-detail.component.html',
  styleUrl: './admin-admin-notification-contacts-detail.component.css'
})
export class AdminAdminNotificationContactsDetailComponent {
  @Input() id: string; 
  @Input() userId: string; 
  @Input() userIdsInList: string[]; 

  confirmationDialogRef: MatDialogRef<ConfirmationDialogComponent>;

  mode: string;
  title: string;
  isManualEntry: boolean = false;
  isEditing: boolean = false;
  users = [];
  myUser;

  adminNotificationContactDetailForm = this._fb.group({
    radioButtonGroup: [''],
    userIds: [''],
    contactFirstName: [''],
    contactLastName: ['', Validators.required],
    contactPhone: [''],
    contactEmail: ['', [Validators.required, Validators.pattern(EMAIL_REGEX)]],
    active: [''],
  });

  constructor(
    private _fb: UntypedFormBuilder,
    private _adminContactsService: AdminContactsService,
    private _authenticationService: AuthenticationService,
    private _userService: UserService,
    private _messageService: MessageService,
    public dialogRef: MatDialogRef<AdminAdminNotificationContactsDetailDialog>,
    @Inject(LOCALE_ID) private locale: string) {
      this.myUser = this._authenticationService.getMyUser();
    }

    ngOnInit() {
      //console.log('init admin notification contacts detail');
      this.isEditing = (this.id != '0');
      this.title = this.id == '0'? 'Add Admin Notification Contact' : 'Edit Admin Notification Contact';
      this.adminNotificationContactDetailForm.get('radioButtonGroup').setValue('enter');
      this.isManualEntry = true;
      if (this.isEditing){ 
        if (this.userId !== null) { //was chosen from user list
          this.adminNotificationContactDetailForm.get('contactFirstName').disable();
          this.adminNotificationContactDetailForm.get('contactLastName').disable();
          this.adminNotificationContactDetailForm.get('contactPhone').disable();
          this.adminNotificationContactDetailForm.get('contactEmail').disable();
        }
        else {
          this.adminNotificationContactDetailForm.get('contactFirstName').enable();
          this.adminNotificationContactDetailForm.get('contactLastName').enable();
          this.adminNotificationContactDetailForm.get('contactPhone').enable();
          this.adminNotificationContactDetailForm.get('contactEmail').enable();
        }
        this.getAdminContactById(this.id);
      }
      else{ 
        this.adminNotificationContactDetailForm.patchValue({ active: true });
      }
    }

    getAdminContactById(adminContactId){
      this._adminContactsService.getAdminContactById(adminContactId)
        .subscribe(
          (data: any) => {
            let contact = data.user != null ? data.user : data;
            this.adminNotificationContactDetailForm.patchValue({
              active: data.active,
              contactFirstName: contact.firstName,
              contactLastName: contact.lastName,
              contactPhone: contact.phoneNumber,
              contactEmail: contact.emailAddress || contact.email,
            });
  
          },
          (response) => {
            this._messageService.onFailure('Failed to get admin contacts.', response);
          })
    }

    onRadioButtonChange(value){
      this.isManualEntry = (value == 'enter');
      if (this.isManualEntry){
        //this.adminNotificationContactDetailForm.get('contactFirstName').setValidators([Validators.required]);
        this.adminNotificationContactDetailForm.get('contactLastName').setValidators([Validators.required]);
        this.adminNotificationContactDetailForm.get('contactEmail').setValidators([Validators.required, Validators.pattern(EMAIL_REGEX)]);
        this.title = this.id == '0'? 'Add Admin Notification Contact' : 'Edit Admin Notification Contact';
      } 
      else{
        //this.adminNotificationContactDetailForm.get('contactFirstName').clearValidators();
        this.adminNotificationContactDetailForm.get('contactLastName').clearValidators();
        this.adminNotificationContactDetailForm.get('contactEmail').clearValidators();
        this.title = this.id == '0'? 'Add or Remove' : 'Edit';
        if (this.id == '0'){ //create
          if (this.userIdsInList && this.userIdsInList.length > 0){
            this.title = 'Add or Remove Admin Notification Contacts';
          }
          else{
            this.title = 'Add Admin Notification Contacts';
          }
        }
        else{ //edit
          this.title = 'Edit Admin Notification Contact';
        }
        if (this.users.length < 1) this.getUsers();
      }
      this.adminNotificationContactDetailForm.get('contactFirstName').updateValueAndValidity();
      this.adminNotificationContactDetailForm.get('contactLastName').updateValueAndValidity();
      this.adminNotificationContactDetailForm.get('contactEmail').updateValueAndValidity();
    }

    getUsers(){
      this._userService.getActiveManageableUsers()
        .subscribe(
          (data: any) => {
            this.users = data.sort((a, b) => (a.fullName.toLowerCase() > b.fullName.toLowerCase()) ? 1 : -1);
            this.adminNotificationContactDetailForm.get('userIds').setValue(this.userIdsInList);
          },
          (response) => {
            this._messageService.onFailure('Failed to get users.', response);
          })
    }

    onSubmit(){
      //Users with hasReadOnlyRestriction set to true should not even be allowed to invoke this action because it is hidden or disabled in the UI.
      //This is for extra protection. The back end will also be protected.
      if (this.myUser.hasReadOnlyRestriction){
        this._messageService.alertReadOnlyRestriction();
        return;
      }

      //console.log(this.adminNotificationContactDetailForm.value);

      if (this.isManualEntry){
        let contact = {
          userId: this.userId,
          firstName: this.adminNotificationContactDetailForm.get('contactFirstName').value,
          lastName: this.adminNotificationContactDetailForm.get('contactLastName').value,
          phoneNumber: this.adminNotificationContactDetailForm.get('contactPhone').value,
          emailAddress: this.adminNotificationContactDetailForm.get('contactEmail').value,
          active: this.adminNotificationContactDetailForm.get('active').value
        }

        //if this contact was picked from a user account, clear values per JL validations
        if (contact.userId != null) {
          contact.firstName = null;
          contact.lastName = null;
          contact.phoneNumber = null;
          contact.emailAddress = null;
        }

        if (this.isEditing) this.updateContact(contact);
        else this.createContact(contact);
      }
      else {
        let userIds = this.adminNotificationContactDetailForm.get('userIds').value;
        this.addOrRemoveContacts(userIds);
      }
    }

    // getUserFromId(userId){
    //   return this.users.find(u => u.userId === userId);
    // }

    createContact(contact){
      this._adminContactsService.createAdminContact(contact)
      .subscribe(
        () => {
          this.dialogRef.close('reload');
          this._messageService.onSuccess('Successfully created admin contact');
        },
        (response) => {
          this._messageService.onFailure('Failed to create admin contact.', response)
        }
      );
    }

    updateContact(contact){
      //console.log(contact);
      this._adminContactsService.updateAdminContact(this.id, contact)
      .subscribe(
        () => {
          this.dialogRef.close('reload');
          this._messageService.onSuccess('Successfully updated admin contact');
        },
        (response) => {
          this._messageService.onFailure('Failed to update admin contact.', response)
        }
      );
    }

    addOrRemoveContacts(userIds){
      //console.log(userIds);
      this._adminContactsService.setUserAccountBasedAdminContacts(userIds)
      .subscribe(
        () => {
          this.dialogRef.close('reload');
          this._messageService.onSuccess('Successfully updated admin contacts');
        },
        (response) => {
          this._messageService.onFailure('Failed to update admin contacts.', response)
        }
      );
    }

    closeDialog(){
      this.dialogRef.close();
    }
  
}