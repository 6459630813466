import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
//import { environment } from 'src/environments/environment';
import { AppConfig } from '../app.config';
import { MessageService } from './message.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {
  private config = AppConfig.settings;

  permissions: any = {
    // roleAllowsUserToViewNotifications: false,

    // // Price Management
    // roleAllowsUserToViewPriceManagement: false,
    // roleAllowsUserToEditPricing: false,
    // roleAllowsUserToSaveOfflinePricing: false,
    // roleAllowsUserToExportPricing: false,
    // roleAllowsUserToOrderTprTags: false,
    // roleAllowsUserToOrderShelfTags: false,
    // roleAllowsUserToCreateItemFilter: false,
    // roleAllowsUserToCreateItemList: false,
    // roleAllowsUserToCreateKeyword: false,
  
    // // Ad Week
    // roleAllowsUserToViewAdWeek: false,
  
    // // Reports and Exports
    // roleAllowsUserToViewReportsAndExports: false,
    // roleAllowsUserToViewCashRegisterReport: false,
    // roleAllowsUserToExportCashRegisterFile: false,
    // roleAllowsUserToViewWarehouseCostChangeReport: false,
    // roleAllowsUserToViewNowCostChangesReport: false,
    // roleAllowsUserToViewFirstTimeBuysReport: false,
    // roleAllowsUserToViewStoreItemPriceChangesReport: false,
    // roleAllowsUserToViewPreviewStoreItemPriceChangesReport: false,
    // roleAllowsUserToViewMeatPreviewStoreItemPriceChangesReport: false,
    // roleAllowsUserToViewProducePreviewStoreItemPriceChangesReport: false,
    // roleAllowsUserToViewLogActionsReport: false,
    // roleAllowsUserToViewRequestAndResponse: false,
    // roleAllowsUserToViewLogEntriesReport: false,
    // roleAllowsUserToViewBillingProfitReport: false,
    // roleAllowsUserToViewArchivalPreviewPriceChangesReport: false,
    // roleAllowsUserToViewPriceChangeStatsReport: false,
    // roleAllowsUserToViewReportingAuditReport: false,
  
    // // Admin
    // roleAllowsUserToViewAdmin: false,
  
    // // Admin Users
    // roleAllowsUserToViewAdminUsers: false,
    // roleAllowsUserToCreateUser: false,
    // roleAllowsUserToEditUser: false,
    // roleAllowsUserToDeleteUser: false,
    // roleAllowsUserToViewUserAssets: false,
    // roleAllowsUserToTransferUserAssets: false,
    // roleAllowsUserToSetDeleteAndTransferPrivileges: false,

    // // Admin Store Groups
    // roleAllowsUserToViewAdminStoreGroups: false,
    // roleAllowsUserToCreateStoreGroup: false,
    // roleAllowsUserToEditStoreGroup: false,
    // roleAllowsUserToDeleteStoreGroup: false,
    // roleAllowsUserToViewStoreGroupAssets: false,
    // roleAllowsUserToTransferStoreGroupAssets: false,

    // // Admin Stores
    // roleAllowsUserToViewAdminStores: false,
    // roleAllowsUserToCreateStore: false,
    // roleAllowsUserToEditStore: false,
    // roleAllowsUserToDeleteStore: false,
    // roleAllowsUserToViewStoreAssets: false,
    // roleAllowsUserToTransferStoreAssets: false,
  
    // // Admin Schedule
    // roleAllowsUserToViewAdminSchedule: false,
    // roleAllowsUserToEditAdminSchedule: false,
  
    // // Admin Item Filters
    // roleAllowsUserToViewAdminItemFilters: false,
    // roleAllowsUserToDeleteItemFilter: false,
  
    // // Admin Lists
    // roleAllowsUserToViewAdminItemLists: false,
    // roleAllowsUserToDeleteItemList: false,
  
    // // Admin Keywords
    // roleAllowsUserToViewAdminKeywords: false,
    // roleAllowsUserToEditKeyword: false,
    // roleAllowsUserToDeleteKeyword: false,
  
    // // Admin Tag Orders
    // roleAllowsUserToViewAdminTagOrders: false,
    // roleAllowsUserToDeleteTprTag: false,
  
    // // Admin POS Export
    // roleAllowsUserToViewAdminPosExport: false,
    // roleAllowsUserToEditPosExportSettings: false,
    // roleAllowsUserToClonePosExportSettingsAndDeptAsscs: false,
    // roleAllowsUserToEditPosExportDeptAsscs: false,
  
    // // Admin Pricing Rules
    // roleAllowsUserToViewAdminPricingRules: false,
    // roleAllowsUserToCreatePricingRule: false,
    // roleAllowsUserToDeletePricingRule: false,
    // roleAllowsUserToEditPricingRule: false,
    // roleAllowsUserToPrioritizePricingRules: false,
  
    // // Admin Private Labels
    // roleAllowsUserToViewAdminPrivateLabels: false,
    // roleAllowsUserToCreatePrivateLabel: false,
    // roleAllowsUserToDeletePrivateLabel: false,
    // roleAllowsUserToEditPrivateLabel: false,

    // // Admin Application
    // roleAllowsUserToLogOutInactiveUsers: false,
    // roleAllowsUserToCreateAnnouncements: false,
  }
  
  role;

  superAdminAdmin;
  superAdminAdminSuperStoreGroupAdminStoreGroupAdmin;
  superAdminAdminStoreGroupPersonnel;
  superAdminAdminStorePersonnelExcludingPricingAndViewerRoles;

  permissions$: Observable<any>;
  private _permissionsSubject = new BehaviorSubject<any>(this.permissions);

  constructor(
    private _userService: UserService,
    private _messageService: MessageService,
  ) {
    this.permissions$ = this._permissionsSubject.asObservable();
  }

  // returns SuperAdmin, Admin
  getSuperAdminAdminRoles() {
    this._userService.getRoleNamesByCollectionName('SuperAdminAdmin')
      .subscribe(
        (data: any) => {
          this.superAdminAdmin = data;
          this.getSuperAdminAdminSuperStoreGroupAdminStoreGroupAdminRoles();
        },
        (response) => {
          this._messageService.onFailure('Failed to get roles.', response);
        })
  }

  getSuperAdminAdminSuperStoreGroupAdminStoreGroupAdminRoles() {
    this._userService.getRoleNamesByCollectionName('SuperAdminAdminSuperStoreGroupAdminStoreGroupAdmin')
      .subscribe(
        (data: any) => {
          this.superAdminAdminSuperStoreGroupAdminStoreGroupAdmin = data;
          this.getSuperAdminAdminStoreGroupPersonnelRoles();
        },
        (response) => {
          this._messageService.onFailure('Failed to get roles.', response);
        })
  }

  getSuperAdminAdminStoreGroupPersonnelRoles() {
    this._userService.getRoleNamesByCollectionName('SuperAdminAdminStoreGroupPersonnel')
      .subscribe(
        (data: any) => {
          this.superAdminAdminStoreGroupPersonnel = data;
          this.getSuperAdminAdminStorePersonnelExcludingPricingAndViewerRoles();
        },
        (response) => {
          this._messageService.onFailure('Failed to get roles.', response);
        })
  }

  // returns SuperAdmin, Admin, SuperStoreGroupAdmin, StoreGroupAdmin, StoreGroupSupervisingManager, StoreSupervisingManager
  getSuperAdminAdminStorePersonnelExcludingPricingAndViewerRoles() {
    this._userService.getRoleNamesByCollectionName('SuperAdminAdminStorePersonnelExcludingPricingAndViewerRoles')
      .subscribe(
        (data: any) => {
          this.superAdminAdminStorePersonnelExcludingPricingAndViewerRoles = data;
          this.setPermissionsForRole(this.role);
        },
        (response) => {
          this._messageService.onFailure('Failed to get roles.', response);
        })
  }

  initRolePermissions(role){
    this.role = role;
    this.getSuperAdminAdminRoles();
  }

  getPermissions(){
    return this.permissions;
  }

  setPermissionsForRole(role) {
    if (this.config.logInits) console.log('set permissions called. ' + role);
    function ifUserIsInRole(roleList) {
      roleList = roleList.replace(/ /g, '');
      var roleArray = roleList.split(',');
      return roleArray.indexOf(role) !== -1;
    }

    // Notifications
    this.permissions.roleAllowsUserToViewNotifications = ifUserIsInRole('SuperAdmin, Admin, Mainframe, SuperStoreGroupAdmin, StoreGroupAdmin, StoreGroupSupervisingManager, StoreGroupPricingManager, StoreSupervisingManager, StorePricingManager, StorePricingViewerAndReporter, StorePricingViewer');

    // Price Management
    this.permissions.roleAllowsUserToViewPriceManagement = ifUserIsInRole('SuperAdmin, Admin, Mainframe, SuperStoreGroupAdmin, StoreGroupAdmin, StoreGroupSupervisingManager, StoreGroupPricingManager, StoreSupervisingManager, StorePricingManager, StorePricingViewerAndReporter, StorePricingViewer');
    this.permissions.roleAllowsUserToEditPricing = ifUserIsInRole('SuperAdmin, Admin, Mainframe, SuperStoreGroupAdmin, StoreGroupAdmin, StoreGroupSupervisingManager, StoreGroupPricingManager, StoreSupervisingManager, StorePricingManager'); //StorePricingViewerAndReporter, StorePricingViewer
    this.permissions.roleAllowsUserToSaveOfflinePricing = ifUserIsInRole('SuperAdmin, Admin, Mainframe, SuperStoreGroupAdmin, StoreGroupAdmin, StoreGroupSupervisingManager, StoreGroupPricingManager, StoreSupervisingManager, StorePricingManager, StorePricingViewerAndReporter, StorePricingViewer');
    this.permissions.roleAllowsUserToExportPricing = ifUserIsInRole('SuperAdmin, Admin, Mainframe, SuperStoreGroupAdmin, StoreGroupAdmin, StoreGroupSupervisingManager, StoreGroupPricingManager, StoreSupervisingManager, StorePricingManager, StorePricingViewerAndReporter, StorePricingViewer');
    this.permissions.roleAllowsUserToOrderTprTags = ifUserIsInRole('SuperAdmin, Admin, Mainframe, SuperStoreGroupAdmin, StoreGroupAdmin, StoreGroupSupervisingManager, StoreGroupPricingManager, StoreSupervisingManager, StorePricingManager'); //StorePricingViewerAndReporter, StorePricingViewer
    this.permissions.roleAllowsUserToOrderShelfTags = ifUserIsInRole('SuperAdmin, Admin, Mainframe, SuperStoreGroupAdmin, StoreGroupAdmin, StoreGroupSupervisingManager, StoreGroupPricingManager, StoreSupervisingManager, StorePricingManager'); //StorePricingViewerAndReporter, StorePricingViewer

    // Ad Week
    this.permissions.roleAllowsUserToViewAdWeek = ifUserIsInRole('SuperAdmin, Admin, Mainframe, SuperStoreGroupAdmin, StoreGroupAdmin, StoreGroupSupervisingManager, StoreGroupPricingManager, StoreSupervisingManager, StorePricingManager, StorePricingViewerAndReporter, StorePricingViewer');

    // Reports and Exports
    this.permissions.roleAllowsUserToViewReportsAndExports = ifUserIsInRole('SuperAdmin, Admin, Mainframe, SuperStoreGroupAdmin, StoreGroupAdmin, StoreGroupSupervisingManager, StoreGroupPricingManager, StoreSupervisingManager, StorePricingManager, StorePricingViewerAndReporter'); //StorePricingViewer
    this.permissions.roleAllowsUserToViewCashRegisterReport = ifUserIsInRole('SuperAdmin, Admin, Mainframe, SuperStoreGroupAdmin, StoreGroupAdmin, StoreGroupSupervisingManager, StoreGroupPricingManager, StoreSupervisingManager, StorePricingManager, StorePricingViewerAndReporter'); //StorePricingViewer
    this.permissions.roleAllowsUserToExportCashRegisterFile = ifUserIsInRole('SuperAdmin, Admin, Mainframe, SuperStoreGroupAdmin, StoreGroupAdmin, StoreGroupSupervisingManager, StoreGroupPricingManager, StoreSupervisingManager, StorePricingManager, StorePricingViewerAndReporter'); //StorePricingViewer
    this.permissions.roleAllowsUserToViewWarehouseCostChangeReport = ifUserIsInRole('SuperAdmin, Admin, Mainframe, SuperStoreGroupAdmin, StoreGroupAdmin, StoreGroupSupervisingManager, StoreGroupPricingManager, StoreSupervisingManager, StorePricingManager'); //StorePricingViewerAndReporter, StorePricingViewer
    this.permissions.roleAllowsUserToViewNowCostChangesReport = ifUserIsInRole('SuperAdmin, Admin, Mainframe, SuperStoreGroupAdmin, StoreGroupAdmin, StoreGroupSupervisingManager, StoreGroupPricingManager, StoreSupervisingManager, StorePricingManager'); //StorePricingViewerAndReporter, StorePricingViewer
    this.permissions.roleAllowsUserToViewFirstTimeBuysReport = ifUserIsInRole('SuperAdmin, Admin, Mainframe, SuperStoreGroupAdmin, StoreGroupAdmin, StoreGroupSupervisingManager, StoreGroupPricingManager, StoreSupervisingManager, StorePricingManager'); //StorePricingViewerAndReporter, StorePricingViewer
    this.permissions.roleAllowsUserToViewStoreItemPriceChangesReport = ifUserIsInRole('SuperAdmin, Admin, Mainframe, SuperStoreGroupAdmin, StoreGroupAdmin, StoreGroupSupervisingManager, StoreGroupPricingManager, StoreSupervisingManager, StorePricingManager'); //StorePricingViewerAndReporter, StorePricingViewer
    this.permissions.roleAllowsUserToViewPreviewStoreItemPriceChangesReport = ifUserIsInRole('SuperAdmin, Admin, Mainframe, SuperStoreGroupAdmin, StoreGroupAdmin, StoreGroupSupervisingManager, StoreGroupPricingManager, StoreSupervisingManager, StorePricingManager'); //StorePricingViewerAndReporter, StorePricingViewer
    this.permissions.roleAllowsUserToViewMeatPreviewStoreItemPriceChangesReport = ifUserIsInRole('SuperAdmin, Admin, Mainframe, SuperStoreGroupAdmin, StoreGroupAdmin, StoreGroupSupervisingManager, StoreGroupPricingManager, StoreSupervisingManager, StorePricingManager'); //StorePricingViewerAndReporter, StorePricingViewer
    this.permissions.roleAllowsUserToViewProducePreviewStoreItemPriceChangesReport = ifUserIsInRole('SuperAdmin, Admin, Mainframe, SuperStoreGroupAdmin, StoreGroupAdmin, StoreGroupSupervisingManager, StoreGroupPricingManager, StoreSupervisingManager, StorePricingManager'); //StorePricingViewerAndReporter, StorePricingViewer
    this.permissions.roleAllowsUserToViewLogActionsReport = ifUserIsInRole('SuperAdmin, Admin, Mainframe, SuperStoreGroupAdmin, StoreGroupAdmin, StoreGroupSupervisingManager, StoreSupervisingManager'); //StoreGroupPricingManager, StorePricingManager, StorePricingViewerAndReporter, StorePricingViewer
    this.permissions.roleAllowsUserToViewRequestAndResponse = ifUserIsInRole('SuperAdmin, Admin'); //Mainframe, StoreGroupAdmin, StoreGroupSupervisingManager, StoreGroupPricingManager, StoreSupervisingManager, StorePricingManager, StorePricingViewerAndReporter, StorePricingViewer
    this.permissions.roleAllowsUserToViewLogEntriesReport = ifUserIsInRole('SuperAdmin, Admin'); //Mainframe, StoreGroupAdmin, StoreGroupSupervisingManager, StoreGroupPricingManager, StoreSupervisingManager, StorePricingManager, StorePricingViewerAndReporter, StorePricingViewer
    this.permissions.roleAllowsUserToViewBillingProfitReport = ifUserIsInRole('SuperAdmin, Admin, Mainframe, SuperStoreGroupAdmin, StoreGroupAdmin, StoreGroupSupervisingManager, StoreGroupPricingManager, StoreSupervisingManager, StorePricingManager'); //StorePricingViewerAndReporter, StorePricingViewer
    this.permissions.roleAllowsUserToViewPriceChangeStatsReport = ifUserIsInRole('SuperAdmin, Admin'); //Mainframe, StoreGroupAdmin, StoreGroupSupervisingManager, StoreGroupPricingManager, StoreSupervisingManager, StorePricingManager, StorePricingViewerAndReporter, StorePricingViewer
    this.permissions.roleAllowsUserToViewArchivalPreviewPriceChangesReport = ifUserIsInRole('SuperAdmin, Admin'); //Mainframe, StoreGroupAdmin, StoreGroupSupervisingManager, StoreGroupPricingManager, StoreSupervisingManager, StorePricingManager, StorePricingViewerAndReporter, StorePricingViewer
    this.permissions.roleAllowsUserToViewReportingAuditReport = ifUserIsInRole('SuperAdmin, Admin'); //Mainframe, StoreGroupAdmin, StoreGroupSupervisingManager, StoreGroupPricingManager, StoreSupervisingManager, StorePricingManager, StorePricingViewerAndReporter, StorePricingViewer

    // Admin
    this.permissions.roleAllowsUserToViewAdmin = ifUserIsInRole('SuperAdmin, Admin, Mainframe, SuperStoreGroupAdmin, StoreGroupAdmin, StoreGroupSupervisingManager, StoreGroupPricingManager, StoreSupervisingManager, StorePricingManager, StorePricingViewerAndReporter, StorePricingViewer');

    // Admin Users
    this.permissions.roleAllowsUserToViewAdminUsers = ifUserIsInRole('SuperAdmin, Admin, Mainframe, SuperStoreGroupAdmin, StoreGroupAdmin, StoreGroupSupervisingManager, StoreGroupPricingManager, StoreSupervisingManager, StorePricingManager, StorePricingViewerAndReporter, StorePricingViewer');
    this.permissions.roleAllowsUserToCreateUser = ifUserIsInRole('SuperAdmin, Admin, SuperStoreGroupAdmin, StoreGroupAdmin, StoreGroupSupervisingManager, StoreSupervisingManager'); //Mainframe, StoreGroupPricingManager, StorePricingManager, StorePricingViewerAndReporter, StorePricingViewer
    this.permissions.roleAllowsUserToEditUser = ifUserIsInRole('SuperAdmin, Admin, SuperStoreGroupAdmin, StoreGroupAdmin, StoreGroupSupervisingManager, StoreSupervisingManager'); //Mainframe, StoreGroupPricingManager, StorePricingManager, StorePricingViewerAndReporter, StorePricingViewer
    this.permissions.roleAllowsUserToDeleteUser = ifUserIsInRole(this.superAdminAdminStorePersonnelExcludingPricingAndViewerRoles); 
    this.permissions.roleAllowsUserToDeleteStoreGroupUser = ifUserIsInRole('SuperAdmin, Admin, SuperStoreGroupAdmin, StoreGroupAdmin, StoreGroupSupervisingManager'); 
    this.permissions.roleAllowsUserToViewUserAssets = ifUserIsInRole(this.superAdminAdminStorePersonnelExcludingPricingAndViewerRoles); 
    this.permissions.roleAllowsUserToDeleteUserAssets = ifUserIsInRole(this.superAdminAdminStorePersonnelExcludingPricingAndViewerRoles); 
    this.permissions.roleAllowsUserToTransferUserAssets = ifUserIsInRole(this.superAdminAdminStorePersonnelExcludingPricingAndViewerRoles); 
    this.permissions.roleAllowsUserToSetDeleteAndTransferPrivileges = ifUserIsInRole('SuperAdmin'); // Admin, SuperStoreGroupAdmin, StoreGroupAdmin, StoreGroupSupervisingManager, StoreSupervisingManager'); //Mainframe, StoreGroupPricingManager, StorePricingManager, StorePricingViewerAndReporter, StorePricingViewer

    // Admin Store Groups
    this.permissions.roleAllowsUserToViewAdminStoreGroups = ifUserIsInRole('SuperAdmin, Admin, Mainframe, SuperStoreGroupAdmin, StoreGroupAdmin, StoreGroupSupervisingManager, StoreGroupPricingManager, StoreSupervisingManager, StorePricingManager, StorePricingViewerAndReporter, StorePricingViewer');
    this.permissions.roleAllowsUserToCreateStoreGroup = ifUserIsInRole('SuperAdmin, Admin'); //Mainframe, StoreGroupAdmin, StoreGroupSupervisingManager, StoreGroupPricingManager, StoreSupervisingManager, StorePricingManager, StorePricingViewerAndReporter, StorePricingViewer
    this.permissions.roleAllowsUserToEditStoreGroup = ifUserIsInRole('SuperAdmin, Admin'); //Mainframe, StoreGroupAdmin, StoreGroupSupervisingManager, StoreGroupPricingManager, StoreSupervisingManager, StorePricingManager, StorePricingViewerAndReporter, StorePricingViewer
    this.permissions.roleAllowsUserToDeleteStoreGroup = ifUserIsInRole(this.superAdminAdmin); 
    this.permissions.roleAllowsUserToViewStoreGroupAssets = ifUserIsInRole(this.superAdminAdminStorePersonnelExcludingPricingAndViewerRoles);
    this.permissions.roleAllowsUserToDeleteStoreGroupAssets = ifUserIsInRole(this.superAdminAdminStoreGroupPersonnel); 
    this.permissions.roleAllowsUserToTransferStoreGroupAssets = ifUserIsInRole('SuperAdmin, Admin, SuperStoreGroupAdmin'); 
    this.permissions.roleAllowsUserToRemoveSSGAFromManagement = ifUserIsInRole(this.superAdminAdmin); 

    // Admin Stores
    this.permissions.roleAllowsUserToViewAdminStores = ifUserIsInRole('SuperAdmin, Admin, Mainframe, SuperStoreGroupAdmin, StoreGroupAdmin, StoreGroupSupervisingManager, StoreGroupPricingManager, StoreSupervisingManager, StorePricingManager, StorePricingViewerAndReporter, StorePricingViewer');
    this.permissions.roleAllowsUserToCreateStore = ifUserIsInRole('SuperAdmin, Admin'); //Mainframe, StoreGroupAdmin, StoreGroupSupervisingManager, StoreGroupPricingManager, StoreSupervisingManager, StorePricingManager, StorePricingViewerAndReporter, StorePricingViewer
    this.permissions.roleAllowsUserToEditStore = ifUserIsInRole('SuperAdmin, Admin'); //Mainframe, StoreGroupAdmin, StoreGroupSupervisingManager, StoreGroupPricingManager, StoreSupervisingManager, StorePricingManager, StorePricingViewerAndReporter, StorePricingViewer
    this.permissions.roleAllowsUserToDeleteStore = ifUserIsInRole(this.superAdminAdmin);
    this.permissions.roleAllowsUserToDeleteStoreAssets = ifUserIsInRole(this.superAdminAdminStorePersonnelExcludingPricingAndViewerRoles); 
    this.permissions.roleAllowsUserToViewStoreAssets = ifUserIsInRole(this.superAdminAdminStorePersonnelExcludingPricingAndViewerRoles); 
    this.permissions.roleAllowsUserToTransferStoreAssets = ifUserIsInRole('SuperAdmin, Admin, SuperStoreGroupAdmin, StoreGroupAdmin, StoreGroupSupervisingManager'); 
    this.permissions.roleAllowsUserToDisassociateControlledStores = ifUserIsInRole(this.superAdminAdmin); 
    this.permissions.roleAllowsUserToTransferStoreUsers = ifUserIsInRole('SuperAdmin, Admin, SuperStoreGroupAdmin, StoreGroupAdmin, StoreGroupSupervisingManager'); 
    this.permissions.roleAllowsUserToTransferStoreToAnotherStoreGroup = ifUserIsInRole(this.superAdminAdmin);

    // Admin Schedule
    this.permissions.roleAllowsUserToViewAdminSchedule = ifUserIsInRole('SuperAdmin, Admin, Mainframe, SuperStoreGroupAdmin, StoreGroupAdmin, StoreGroupSupervisingManager, StoreGroupPricingManager, StoreSupervisingManager, StorePricingManager, StorePricingViewerAndReporter, StorePricingViewer');
    this.permissions.roleAllowsUserToEditAdminSchedule = ifUserIsInRole('SuperAdmin'); //Admin, Mainframe, StoreGroupAdmin, StoreGroupSupervisingManager, StoreGroupPricingManager, StoreSupervisingManager, StorePricingManager, StorePricingViewerAndReporter, StorePricingViewer

    // Admin Item Filters
    this.permissions.roleAllowsUserToViewAdminItemFilters = ifUserIsInRole('SuperAdmin, Admin, Mainframe, SuperStoreGroupAdmin, StoreGroupAdmin, StoreGroupSupervisingManager, StoreGroupPricingManager, StoreSupervisingManager, StorePricingManager'); //StorePricingViewerAndReporter, StorePricingViewer
    this.permissions.roleAllowsUserToCreateItemFilter = ifUserIsInRole('SuperAdmin, Admin, Mainframe, SuperStoreGroupAdmin, StoreGroupAdmin, StoreGroupSupervisingManager, StoreGroupPricingManager, StoreSupervisingManager, StorePricingManager'); //StorePricingViewerAndReporter, StorePricingViewer
    this.permissions.roleAllowsUserToEditItemFilter = ifUserIsInRole('SuperAdmin, Admin, Mainframe, SuperStoreGroupAdmin, StoreGroupAdmin, StoreGroupSupervisingManager, StoreGroupPricingManager, StoreSupervisingManager, StorePricingManager'); //StorePricingViewerAndReporter, StorePricingViewer
    this.permissions.roleAllowsUserToDeleteItemFilter = ifUserIsInRole('SuperAdmin, Admin'); //Admin List Page (Grid)
    this.permissions.roleAllowsUserToDeleteScopedUsersOwnedAssetsItemFilters = ifUserIsInRole('SuperAdmin, Admin, SuperStoreGroupAdmin, StoreGroupAdmin, StoreGroupSupervisingManager, StoreSupervisingManager'); 

    // Admin Lists
    this.permissions.roleAllowsUserToViewAdminItemLists = ifUserIsInRole('SuperAdmin, Admin, Mainframe, SuperStoreGroupAdmin, StoreGroupAdmin, StoreGroupSupervisingManager, StoreGroupPricingManager, StoreSupervisingManager, StorePricingManager'); //StorePricingViewerAndReporter, StorePricingViewer
    this.permissions.roleAllowsUserToCreateItemList = ifUserIsInRole('SuperAdmin, Admin, Mainframe, SuperStoreGroupAdmin, StoreGroupAdmin, StoreGroupSupervisingManager, StoreGroupPricingManager, StoreSupervisingManager, StorePricingManager'); //StorePricingViewerAndReporter, StorePricingViewer
    this.permissions.roleAllowsUserToEditItemList = ifUserIsInRole('SuperAdmin, Admin, Mainframe, SuperStoreGroupAdmin, StoreGroupAdmin, StoreGroupSupervisingManager, StoreGroupPricingManager, StoreSupervisingManager, StorePricingManager'); //StorePricingViewerAndReporter, StorePricingViewer
    this.permissions.roleAllowsUserToDeleteItemList = ifUserIsInRole('SuperAdmin, Admin'); //Admin List Page (Grid) and PM Item List Editor
    this.permissions.roleAllowsUserToDeleteScopedUsersOwnedAssetsItemLists = ifUserIsInRole('SuperAdmin, Admin, SuperStoreGroupAdmin, StoreGroupAdmin, StoreGroupSupervisingManager, StoreSupervisingManager'); 

    // Admin Tag Orders
    this.permissions.roleAllowsUserToViewAdminTagOrders = ifUserIsInRole('SuperAdmin, Admin, Mainframe, SuperStoreGroupAdmin, StoreGroupAdmin, StoreGroupSupervisingManager, StoreGroupPricingManager, StoreSupervisingManager, StorePricingManager, StorePricingViewerAndReporter, StorePricingViewer');
    this.permissions.roleAllowsUserToDeleteTprTag = ifUserIsInRole('SuperAdmin, Admin, Mainframe, SuperStoreGroupAdmin, StoreGroupAdmin, StoreGroupSupervisingManager, StoreGroupPricingManager, StoreSupervisingManager, StorePricingManager'); //, StorePricingViewerAndReporter, StorePricingViewer');

    // Admin POS Export
    this.permissions.roleAllowsUserToViewAdminPosExport = ifUserIsInRole('SuperAdmin, Admin, Mainframe, SuperStoreGroupAdmin, StoreGroupAdmin, StoreGroupSupervisingManager, StoreGroupPricingManager, StoreSupervisingManager, StorePricingManager, StorePricingViewerAndReporter, StorePricingViewer');
    this.permissions.roleAllowsUserToEditPosExportSettings = ifUserIsInRole('SuperAdmin, Admin, Mainframe, SuperStoreGroupAdmin, StoreGroupAdmin, StoreGroupSupervisingManager, StoreSupervisingManager'); //StoreGroupPricingManager, StorePricingManager, StorePricingViewerAndReporter, StorePricingViewer
    this.permissions.roleAllowsUserToClonePosExportSettingsAndDeptAsscs = ifUserIsInRole('SuperAdmin, Admin, Mainframe, SuperStoreGroupAdmin, StoreGroupAdmin, StoreGroupSupervisingManager, StoreSupervisingManager'); //StoreGroupPricingManager, StorePricingManager, StorePricingViewerAndReporter, StorePricingViewer
    this.permissions.roleAllowsUserToEditPosExportDeptAsscs = ifUserIsInRole('SuperAdmin, Admin, Mainframe, SuperStoreGroupAdmin, StoreGroupAdmin, StoreGroupSupervisingManager, StoreGroupPricingManager, StoreSupervisingManager, StorePricingManager'); //StorePricingViewerAndReporter, StorePricingViewer

    // Admin Pricing Rules
    this.permissions.roleAllowsUserToViewAdminPricingRules = ifUserIsInRole('SuperAdmin, Admin, Mainframe, SuperStoreGroupAdmin, StoreGroupAdmin, StoreGroupSupervisingManager, StoreGroupPricingManager, StoreSupervisingManager, StorePricingManager, StorePricingViewerAndReporter, StorePricingViewer');
    this.permissions.roleAllowsUserToCreatePricingRule = ifUserIsInRole('SuperAdmin, Admin'); //Mainframe, SuperStoreGroupAdmin, StoreGroupAdmin, StoreGroupSupervisingManager, StoreGroupPricingManager, StoreSupervisingManager, StorePricingManager, StorePricingViewerAndReporter, StorePricingViewer
    this.permissions.roleAllowsUserToDeletePricingRule = ifUserIsInRole('SuperAdmin, Admin'); //Mainframe, SuperStoreGroupAdmin, StoreGroupAdmin, StoreGroupSupervisingManager, StoreGroupPricingManager, StoreSupervisingManager, StorePricingManager, StorePricingViewerAndReporter, StorePricingViewer
    this.permissions.roleAllowsUserToEditPricingRule = ifUserIsInRole('SuperAdmin, Admin'); //Mainframe, SuperStoreGroupAdmin, StoreGroupAdmin, StoreGroupSupervisingManager, StoreGroupPricingManager, StoreSupervisingManager, StorePricingManager, StorePricingViewerAndReporter, StorePricingViewer
    this.permissions.roleAllowsUserToPrioritizePricingRules = ifUserIsInRole('SuperAdmin, Admin'); //Mainframe, SuperStoreGroupAdmin, StoreGroupAdmin, StoreGroupSupervisingManager, StoreGroupPricingManager, StoreSupervisingManager, StorePricingManager, StorePricingViewerAndReporter, StorePricingViewer

    // Admin Private Labels
    this.permissions.roleAllowsUserToViewAdminPrivateLabels = ifUserIsInRole('SuperAdmin, Admin, Mainframe, SuperStoreGroupAdmin, StoreGroupAdmin, StoreGroupSupervisingManager, StoreGroupPricingManager, StoreSupervisingManager, StorePricingManager, StorePricingViewerAndReporter, StorePricingViewer');
    this.permissions.roleAllowsUserToCreatePrivateLabel = ifUserIsInRole('SuperAdmin, Admin'); //Mainframe, StoreGroupAdmin, StoreGroupSupervisingManager, StoreGroupPricingManager, StoreSupervisingManager, StorePricingManager, StorePricingViewerAndReporter, StorePricingViewer
    this.permissions.roleAllowsUserToDeletePrivateLabel = ifUserIsInRole('SuperAdmin, Admin'); //Mainframe, StoreGroupAdmin, StoreGroupSupervisingManager, StoreGroupPricingManager, StoreSupervisingManager, StorePricingManager, StorePricingViewerAndReporter, StorePricingViewer
    this.permissions.roleAllowsUserToEditPrivateLabel = ifUserIsInRole('SuperAdmin, Admin'); //Mainframe, StoreGroupAdmin, StoreGroupSupervisingManager, StoreGroupPricingManager, StoreSupervisingManager, StorePricingManager, StorePricingViewerAndReporter, StorePricingViewer
  
    // Admin Application
    this.permissions.roleAllowsUserToLogOutInactiveUsers = ifUserIsInRole('SuperAdmin, Admin'); //Mainframe, StoreGroupAdmin, StoreGroupSupervisingManager, StoreGroupPricingManager, StoreSupervisingManager, StorePricingManager, StorePricingViewerAndReporter, StorePricingViewer
    
    //Admin Announcements
    this.permissions.roleAllowsUserToCreateAnnouncements = ifUserIsInRole('SuperAdmin, Admin'); //Mainframe, StoreGroupAdmin, StoreGroupSupervisingManager, StoreGroupPricingManager, StoreSupervisingManager, StorePricingManager, StorePricingViewerAndReporter, StorePricingViewer
    
    //Admin Retail Notification Contacts
    this.permissions.roleAllowsUserToEditRetailNotificationContacts = ifUserIsInRole('SuperAdmin, Admin'); //Mainframe, StoreGroupAdmin, StoreGroupSupervisingManager, StoreGroupPricingManager, StoreSupervisingManager, StorePricingManager, StorePricingViewerAndReporter, StorePricingViewer
    
    //Admin Admin Notification Contacts
    this.permissions.roleAllowsUserToEditAdminNotificationContacts = ifUserIsInRole('SuperAdmin, Admin'); //Mainframe, StoreGroupAdmin, StoreGroupSupervisingManager, StoreGroupPricingManager, StoreSupervisingManager, StorePricingManager, StorePricingViewerAndReporter, StorePricingViewer

    // UPDATE SUBSCRIBERS
    this._permissionsSubject.next(this.permissions);

  };


}
