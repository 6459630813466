import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from '../app.config';

@Injectable({
  providedIn: 'root'
})
export class RetailContactsService {

  private _baseUrl = AppConfig.settings.baseUrl;
  private _reqHeader = new HttpHeaders({ 'Accept': 'application/json', 'Content-Type': 'application/json' });

  constructor(
    private _http: HttpClient, 
  ) { }
  
  getAllRetailContacts() {
    return this._http.get(this._baseUrl + '/api/RetailContacts/GetAllRetailContacts', { headers: this._reqHeader });
  }

  getActiveRetailContacts() {
    return this._http.get(this._baseUrl + '/api/RetailContacts/GetActiveRetailContacts', { headers: this._reqHeader });
  }

  getRetailContactById(contactId) {
    return this._http.get(this._baseUrl + '/api/RetailContacts/GetRetailContactById//' + contactId, { headers: this._reqHeader });
  }

  createRetailContact(contact) {
    return this._http.post(this._baseUrl + '/api/RetailContacts/CreateRetailContact', contact, { headers: this._reqHeader });
  }

  setUserAccountBasedRetailContacts(userIds) {
    return this._http.post(this._baseUrl + '/api/RetailContacts/SetUserAccountBasedRetailContacts', userIds, { headers: this._reqHeader });
  }

  updateRetailContact(contactId, contact) {
    return this._http.put(this._baseUrl + '/api/RetailContacts/UpdateRetailContact/' + contactId, contact, { headers: this._reqHeader });
  }

  deleteRetailContact(contactId) {
    return this._http.delete(this._baseUrl + '/api/RetailContacts/DeleteRetailContact/' + contactId, { headers: this._reqHeader });
  }
}
