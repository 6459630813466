import { Component, OnInit, ViewChild } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { GridOptions } from 'ag-grid-community';
import { GridService } from 'src/app/shared/grid.service';
import { MessageService } from 'src/app/shared/message.service';
import { StoreService } from 'src/app/shared/store.service';
import * as moment from 'moment';
import { PriceManagementFilterService } from '../price-management-filter.service';
import { SpinnerService } from '../../shared/spinner.service';
import { CustomHeaderComponent } from '../custom-header/custom-header.component';
import 'signalR';
import { HelpService } from '../../shared/help.service';

@Component({
  selector: 'app-offline-price-management',
  templateUrl: './offline-price-management.component.html',
  styleUrls: ['./offline-price-management.component.css']
})
export class OfflinePriceManagementComponent implements OnInit {
  @ViewChild('agGrid') agGrid: AgGridAngular;

  gridOptions: GridOptions;
  quickFilterText: string = '';
  context; //required by grid
  //frameworkComponents; //required by grid

  routerSubscription;

  isStoreSelected:boolean = false;
  isGridDataLoaded:boolean = false;
  rowCount;
  now;

  stores = [];
  store: any = { storeId: null };
  itemLists;

  allItemsHashTable;
  gridFilterModel;
  gridFilterSubscription;
  tabModel = { activeTab: "AllItems", activeSubTab: "AllItems" };
  tabModelSubscription;
  isFilterApplied: boolean;
  isShowingFooter: boolean = false;
  selectedRowData: any = {}; //used for footer data on cell focused
  selectedRowNode: any = {}; //set same time as selectedRowData but is the whole node
  currentFocusedCell: any = {rowIndex:0};
  rowNodeToUpdate: any = {};
  updatedRowNode: any = {};
  pricingGridMetadata: any = {};

  masterChangeModel: any = {}; //needed for custom grid header

  isDataInfoBarVisible:boolean = false;
  dataInfoBarClass;
  dataInfoBarMessage;

  privateLabels;
  showHelpTimeout;

  rowData = [];
  statusBar;

  columnDefs = [
    {
      headerName: 'Item',
      field: "itemCode",
      width: 80,
      cellClass: ["itemCode-col", "tac"],
      cellRenderer: this._gridService.itemCodeRenderer,
      pinned: "left",
      floatingFilterComponent: this._gridService.getCustomNumberFloatingFilter(),
      filter: this._gridService.getCustomNumberFilter(),
      filterParams: {
        defaultOption: "startsWith",
        ignoreLeadingZeros: true,
      },
      get headerTooltip() { return this.headerName; }, 
    },
    {
      headerName: "UPC",
      field: "upcCode",
      width: 115,
      cellClass: ["upcCode-col"],
      filter: "agTextColumnFilter",
      filterParams: {
        defaultOption: "startsWith"
      },
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Dept",
      field: "deptCode",
      width: 60,
      cellClass: ["deptCode-col"],
      hide: true,
      filter: "agSetColumnFilter",
      //hide: true,
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Pack",
      field: "units",
      width: 60,
      cellClass: ["units-col", "tar"],
      hide: true,
      filter: "agNumberColumnFilter",
      filterParams: {
        defaultOption: "equals",
        inRangeInclusive: true,
      },
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Pack/Size",
      field: "size",
      width: 115,
      cellClass: ["size-col"],
      filter: "agTextColumnFilter",
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Description",
      field: "description",
      width: 300,
      cellClass: ["description-col"],
      floatingFilterComponent: this._gridService.getCustomTextFloatingFilter(),
      filter: this._gridService.getCustomTextFilter(),
      filterParams: {
        defaultOption: "listOr"
      },
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Long Description",
      field: "longDescription",
      width: 300,
      cellClass: ["longDescription-col"],
      hide: true,
      floatingFilterComponent: this._gridService.getCustomTextFloatingFilter(),
      filter: this._gridService.getCustomTextFilter(),
      filterParams: {
        defaultOption: "listOr"
      },
      comparator: this._gridService.caseInsensitiveTextComparator,
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Group",
      field: "groupNumber",
      width: 65,
      cellClass: ["groupNumber-col", "tar"],
      floatingFilterComponent: this._gridService.getCustomGroupNumberFloatingFilter(),
      filter: this._gridService.getCustomGroupNumberFilter(),
      filterParams: {
        defaultOption: "listEqualsOrRange",
        inRangeInclusive: true,
      },
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Group Description",
      field: "groupDescription",
      width: 250,
      cellClass: ["groupDescription-col"],
      hide: true,
      filter: "agTextColumnFilter",
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Movement",
      width: 100,
      headerClass: 'movement-column-group',
      openByDefault: false,
      children: [
        {
          headerName: '',
          field: 'movementGroup',
          width: 100,
          columnGroupShow: 'closed',
          filter: false,
          suppressHeaderMenuButton: true,
        },
        {
          headerName: "LY Mvt",
          field: "lastYearsMovementForStore",
          width: 70,
          cellClass: ["lastYearsMovementForStore-col", "tar"],
          filter: "agNumberColumnFilter",
          columnGroupShow: 'open',
          get headerTooltip() { return this.headerName; }
        },
        {
          headerName: "YTD Mvt",
          field: "yearToDateMovementForStore",
          width: 80,
          cellClass: ["yearToDateMovementForStore-col", "tar"],
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "equals",
            inRangeInclusive: true,
          },
          columnGroupShow: 'open',
          get headerTooltip() { return this.headerName; }
        },
        {
          headerName: "W LY Mvt",
          field: "lastYearsMovement",
          width: 85,
          cellClass: ["lastYearsMovement-col", "tar"],
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "equals",
            inRangeInclusive: true,
          },
          columnGroupShow: 'open',
          get headerTooltip() { return this.headerName; }
        },
        {
          headerName: "W YTD Mvt",
          field: "yearToDateMovement",
          width: 95,
          cellClass: ["yearToDateMovement-col", "tar"],
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "equals",
            inRangeInclusive: true,
          },
          columnGroupShow: 'open',
          get headerTooltip() { return this.headerName; }
        },
      ]
    },
    {
      headerName: "Classification",
      width: 115,
      headerClass: 'classification-column-group',
      openByDefault: false,
      children: [
        {
          headerName: '',
          field: 'classificationGroup',
          width: 115,
          columnGroupShow: 'closed',
          filter: false,
          suppressHeaderMenuButton: true,
        },
        {
          headerName: "Pvt Label",
          field: "privateLabel",
          width: 100,
          cellClass: ["privateLabel-col", "tac"],
          cellRenderer: this._gridService.privateLabelRenderer,
          filter: "agSetColumnFilter",
          filterParams: {
            newRowsAction: 'keep',
            comparator: (a, b) => {
                let labelA = this.getLabelFromCode(a);
                let labelB = this.getLabelFromCode(b);
                return labelA.localeCompare(labelB);
            },
            valueFormatter: (params) => {
                return this.getLabelFromCode(params.value);
            }
          },
          valueFormatter: (params) => {
              return this.getLabelFromCode(params.value);
          },
          columnGroupShow: 'open',
          get headerTooltip() { return this.headerName; }
        },
        {
          headerName: "New",
          field: "newItem",
          width: 70,
          cellClass: ["newItem-col", "tac"],
          cellRenderer: this._gridService.newItemRenderer,
          filter: "agSetColumnFilter",
          filterParams: {
            values: [true, false],
            newRowsAction: 'keep'
          },
          columnGroupShow: 'open',
          get headerTooltip() { return this.headerName; }
        },
        {
          headerName: "FTB",
          field: "firstTimeBuy",
          width: 70,
          cellClass: ["firstTimeBuy-col", "tac"],
          cellRenderer: this._gridService.firstTimeBuyRenderer,
          filter: "agSetColumnFilter",
          filterParams: {
            values: [true, false],
            newRowsAction: 'keep'
          },
          columnGroupShow: 'open',
          get headerTooltip() { return this.headerName; }
        },
        {
          headerName: "WIC",
          field: "wicItem",
          width: 70,
          hide: true,
          cellClass: ["wicItem-col", "tac"],
          cellRenderer: this._gridService.wicItemRenderer,
          filter: "agSetColumnFilter",
          filterParams: {
            values: [true, false],
            newRowsAction: 'keep'
          },
          columnGroupShow: 'open',
          get headerTooltip() { return this.headerName; }
        },
        {
          headerName: "Now",
          field: "nowItem",
          width: 70,
          cellClass: ["nowItem-col", "tac"],
          cellRenderer: this._gridService.nowItemRenderer,
          filter: "agSetColumnFilter",
          filterParams: {
            values: [true, false],
            newRowsAction: 'keep'
          },
          columnGroupShow: 'open',
          get headerTooltip() { return this.headerName; }
        },
        {
          headerName: "FTB/Now",
          field: "firstTimeBuyOrNowItem",
          width: 80,
          cellClass: ["firstTimeBuyOrNowItem-col", "tac"],
          cellRenderer: this._gridService.checkRenderer,
          hide: true,
          filter: "agSetColumnFilter",
          filterParams: {
            values: [true, false],
            newRowsAction: 'keep'
          },
          columnGroupShow: 'open',
          get headerTooltip() { return this.headerName; }
        },
        {
          headerName: "Rule Matched",
          field: "pricingRuleMatched",
          width: 110,
          cellClass: ["newItem-col", "tac"],
          hide: true,
          filter: 'agTextColumnFilter',
          columnGroupShow: 'open',
          get headerTooltip() { return this.headerName; }
        },
      ]
    },
    {
      headerName: "Unit Less D",
      field: "unitCostLessDeliveryFee",
      width: 95,
      cellClass: ["unitCostLessDeliveryFee-col", "tar"],
      cellRenderer: this._gridService.currencyRenderer,
      hide: true,
      filter: "agNumberColumnFilter",
      filterParams: {
        defaultOption: "equals",
        inRangeInclusive: true,
      },
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Unit Cost",
      field: "deliveredUnitCost",
      width: 80,
      cellClass: ["deliveredUnitCost-col", "tar"],
      cellRenderer: this._gridService.currencyRenderer,
      filter: "agNumberColumnFilter",
      filterParams: {
        defaultOption: "equals",
        inRangeInclusive: true,
      },
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Selector",
      field: "masterChangeCheckbox",
      width: 75,
      cellClass: ["masterChangeCheckbox-col"],
      cellRenderer: this._gridService.checkboxRenderer,
      lockVisible: true,
      hide: true,
      suppressHeaderMenuButton: true,
      filter: false,
      headerComponentParams: {
        masterInputCheckBox: true,
        masterInputHtml: '<div class="master-input master-input-checkbox"><input id="master-check-master-input" type="checkbox"/></div>'
      },
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Bk",
      field: "book",
      width: 50,
      cellClass: function (params) { if (params.data) return params.data.edited && params.data.book != params.data.oldBook ? ["book-col", "tac", "changed"] : ["book-col", "tac"]; },
      editable: false,
      lockVisible: true,
      cellRenderer: this.bookSrpCodeRenderer,
      cellEditor: this._gridService.getBookCellEditor(this),
      filter: "agSetColumnFilter",
      filterParams: {
        values: ['1', '2', '3', '4', '5', '6', '7', 'C', 'D'],
        newRowsAction: 'keep'
      },
      headerComponentParams: {
        dynamicClassType: 'book',
        masterInputHtml: '<select id="book-master-input">' + 
          '<option value=""></option>' +
          '<option>1</option>' +
          '<option>2</option>' +
          '<option>3</option>' +
          '<option>4</option>' +
          '<option>5</option>' +
          '<option>6</option>' +
          '<option>7</option>' +
          '<option>C</option>' +
          '<option>D</option>' +
          '</select>'
      },
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Deal Code",
      field: "srpCode",
      width: 85,
      cellClass: function (params) { if (params.data) return params.data.edited && params.data.srpCode != params.data.oldSrpCode ? ["srpCode-col", "tac", "changed"] : ["srpCode-col", "tac"]; },
      editable: false,
      lockVisible: true,
      cellRenderer: this.bookSrpCodeRenderer,
      cellEditor: this._gridService.getSrpCodeCellEditor(this),
      filter: "agSetColumnFilter",
      filterParams: {
        values: ['A', 'B', 'C', 'H', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
        newRowsAction: 'keep'
      },
      headerComponentParams: {
        dynamicClassType: 'book',
        masterInputHtml: '<select id="srpCode-master-input">' +
          '<option value=""></option>' +
          '<option>A</option>' +
          '<option>B</option>' +
          '<option>C</option>' +
          '<option>H</option>' +
          '<option>1</option>' +
          '<option>2</option>' +
          '<option>3</option>' +
          '<option>4</option>' +
          '<option>5</option>' +
          '<option>6</option>' +
          '<option>7</option>' +
          '<option>8</option>' +
          '<option>9</option>' +
          '</select>'
      },
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Multi",
      field: "multi",
      width: 60,
      cellClass: function (params) { if (params.data) return params.data.edited && params.data.multi != params.data.oldMulti ? ["n-col", "tar", "changed"] : ["n-col", "tar"]; },
      editable: false,
      lockVisible: true,
      cellRenderer: this.pricePercentRenderer,
      cellEditor: this._gridService.getMultiCellEditor(this),
      filter: "agNumberColumnFilter",
      filterParams: {
        defaultOption: "equals",
        inRangeInclusive: true,
      },
      headerComponentParams: {
        dynamicClassType: 'price',
        masterInputHtml: '<input id="multi-master-input" type="text"/>'
      },
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Current Price",
      field: "currentPrice",
      width: 100,
      cellClass: ["tar"],
      //cellClass: function (params) { if (params.data) return params.data.edited && params.data.price != params.data.oldPrice ? ["price-col", "tar", "changed"] : ["price-col", "tar"]; },
      editable: false,
      cellRenderer: this._gridService.currencyRenderer,
      hide: true,
      filter: "agNumberColumnFilter",
      filterParams: {
        defaultOption: "equals",
        inRangeInclusive: true,
      },
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "CHG",
      field: "chg",
      width: 60,
      cellClass: ["chg-col"],
      filter: "agTextColumnFilter",
      hide: true,
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Price",
      field: "price",
      width: 75,
      cellClass: function (params) { if (params.data) return params.data.edited && params.data.price != params.data.oldPrice ? ["price-col", "tar", "changed"] : ["price-col", "tar"]; },
      editable: false,
      lockVisible: true,
      cellRenderer: this.pricePercentRenderer,
      cellEditor: this._gridService.getPriceCellEditor(this),
      filter: "agNumberColumnFilter",
      filterParams: {
        defaultOption: "equals",
        inRangeInclusive: true,
      },
      headerComponentParams: {
        dynamicClassType: 'price',
        masterInputHtml: '<input id="price-master-input" type="text"/>'
      },
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "%",
      field: "percent",
      width: 45,
      cellClass: function (params) { if (params.data) return params.data.edited && params.data.percent != params.data.oldPercent ? ["percent-col", "tar", "changed"] : ["percent-col", "tar"]; },
      editable: false,
      lockVisible: true,
      cellRenderer: this.pricePercentRenderer,
      cellEditor: this._gridService.getPercentCellEditor(this),
      filter: "agNumberColumnFilter",
      filterParams: {
        defaultOption: "equals",
        inRangeInclusive: true,
      },
      headerComponentParams: {
        dynamicClassType: 'percent',
        masterInputHtml: '<input id="percent-master-input" type="text"/>'
      },
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Keep$",
      field: "keepSrp",
      width: 65,
      cellClass: function (params) { if (params.data) return params.data.edited && params.data.keepSrp != params.data.oldKeepSrp ? ["keepSrp-col", "tac", "changed"] : ["keepSrp-col", "tac"]; },
      cellRenderer: this.pricePercentRenderer,
      cellEditor: this._gridService.getKeepSrpCellEditor(this),
      lockVisible: true,
      filter: "agSetColumnFilter",
      filterParams: {
        values: [true, false],
        newRowsAction: 'keep'
      },
      headerComponentParams: {
        dynamicClassType: 'price',
        masterInputHtml: '<select id="keepSrp-master-input">' +
          '<option value=""></option>' +
          '<option value=true>True</option>' +
          '<option value=false>False</option>' +
          '</select>'
      },
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Keep%",
      field: "keepPercent",
      width: 65,
      cellClass: function (params) { if (params.data) return params.data.edited && params.data.keepPercent != params.data.oldKeepPercent ? ["keepPercent-col", "tac", "changed"] : ["keepPercent-col", "tac"]; },
      cellRenderer: this.pricePercentRenderer,
      cellEditor: this._gridService.getKeepPercentCellEditor(this),
      lockVisible: true,
      filter: "agSetColumnFilter",
      filterParams: {
        values: [true, false],
        newRowsAction: 'keep'
      },
      headerComponentParams: {
        dynamicClassType: 'percent',
        masterInputHtml: '<select id="keepPercent-master-input">' +
          '<option value=""></option>' +
          '<option value=true>True</option>' +
          '<option value=false>False</option>' +
          '</select>'
      },
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Deal",
      field: "deal",
      width: 75,
      cellClass: ["deal-col", "tar"],
      cellRenderer: this._gridService.currencyRenderer,
      filter: "agNumberColumnFilter",
      filterParams: {
        defaultOption: "equals",
        inRangeInclusive: true,
      },
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Deal End",
      field: "dealDate",
      width: 110,
      cellClass: ["dealDate-col"],
      cellRenderer: this._gridService.dateRenderer,
      filter: 'agDateColumnFilter',
      filterParams: {
        comparator: this._gridService.dateComparator,
        inRangeInclusive: true
      },
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Old Price",
      width: 100,
      headerClass: 'old-price-column-group',
      openByDefault: false,
      children: [
        {
          headerName: '',
          field: 'oldPriceGroup',
          width:100,
          columnGroupShow: 'closed',
          filter: false,
          suppressHeaderMenuButton: true,
          get headerTooltip() { return this.headerName; }
        },
        {
          headerName: "O Bk",
          field: "oldBook",
          width: 55,
          cellClass: ["oldBook-col", "tac", "old-values-column"],
          filter: "agTextColumnFilter",
          hide: false,
          columnGroupShow: 'open',
          get headerTooltip() { return this.headerName; }
        },
        {
          headerName: "O Dl Cd",
          field: "oldSrpCode",
          width: 70,
          cellClass: ["oldSrpCode-col", "tac", "old-values-column"],
          filter: "agTextColumnFilter",
          hide: false,
          columnGroupShow: 'open',
          get headerTooltip() { return this.headerName; }
        },
        {
          headerName: "O Mult",
          field: "oldMulti",
          width: 80,
          cellClass: ["oldMulti-col", "tar", "old-values-column"],
          filter: "agNumberColumnFilter",
          hide: false,
          columnGroupShow: 'open',
          filterParams: {
            defaultOption: "equals",
            inRangeInclusive: true,
          },
          get headerTooltip() { return this.headerName; }
        },
        {
          headerName: "O Price",
          field: "oldPrice",
          width: 70,
          cellClass: ["oldPrice-col", "tar", "old-values-column"],
          hide: false,
          cellRenderer: this._gridService.currencyRenderer,
          filter: "agNumberColumnFilter",
          columnGroupShow: 'open',
          filterParams: {
            defaultOption: "equals",
            inRangeInclusive: true,
          },
          get headerTooltip() { return this.headerName; }
        },
        {
          headerName: "O Kp$",
          field: "oldKeepSrp",
          width: 65,
          cellClass: ["oldKeepSrp-col", "tac", "old-values-column"],
          hide: false,
          cellRenderer: this._gridService.checkRenderer,
          filter: "agSetColumnFilter",
          filterParams: {
            values: [true, false],
            newRowsAction: 'keep'
          },
          columnGroupShow: 'open',
          get headerTooltip() { return this.headerName; }
        },
        {
          headerName: "O %",
          field: "oldPercent",
          width: 50,
          cellClass: ["oldPercent-col", "tar", "old-values-column"],
          hide: false,
          filter: "agNumberColumnFilter",
          columnGroupShow: 'open',
          filterParams: {
            defaultOption: "equals",
            inRangeInclusive: true,
          },
          get headerTooltip() { return this.headerName; }
        },
        {
          headerName: "O Kp%",
          field: "oldKeepPercent",
          width: 65,
          cellClass: ["oldKeepPercent-col", "tac", "old-values-column"],
          hide: false,
          cellRenderer: this._gridService.checkRenderer,
          filter: "agSetColumnFilter",
          filterParams: {
            values: [true, false],
            newRowsAction: 'keep'
          },
          columnGroupShow: 'open',
          get headerTooltip() { return this.headerName; }
        },
      ]
    },
    {
      headerName: "Case Less D",
      field: "caseCostLessDeliveryFee",
      width: 100,
      cellClass: ["caseCostLessDeliveryFee-col", "tar"],
      hide: true,
      cellRenderer: this._gridService.currencyRenderer,
      filter: "agNumberColumnFilter",
      filterParams: {
        defaultOption: "equals",
        inRangeInclusive: true,
      },
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Case",
      field: "deliveredCaseCost",
      width: 85,
      cellClass: ["deliveredCaseCost-col", "tar"],
      hide: false,
      cellRenderer: this._gridService.currencyRenderer,
      filter: "agNumberColumnFilter",
      filterParams: {
        defaultOption: "equals",
        inRangeInclusive: true,
      },
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Base",
      field: "baseCost",
      width: 85,
      cellClass: ["baseCost-col", "tar"],
      hide: true,
      cellRenderer: this._gridService.currencyRenderer,
      filter: "agNumberColumnFilter",
      filterParams: {
        defaultOption: "equals",
        inRangeInclusive: true,
      },
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Old Cost",
      width: 100,
      headerClass: 'old-cost-column-group',
      openByDefault: false,
      children: [
        {
          headerName: '',
          field: 'oldCostGroup',
          width: 100,
          columnGroupShow: 'closed',
          filter: false,
          suppressHeaderMenuButton: true,
        },
        {
          headerName: "O Case",
          field: "oldDeliveredCaseCost",
          width: 90,
          cellClass: ["oldDeliveredCaseCost-col", "tar", "old-values-column"],
          hide: false,
          cellRenderer: this._gridService.currencyRenderer,
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "equals",
            inRangeInclusive: true,
          },
          columnGroupShow: 'open',
          get headerTooltip() { return this.headerName; }
        },
        {
          headerName: "O Base",
          field: "oldBaseCost",
          width: 80,
          cellClass: ["oldBaseCost-col", "tar", "old-values-column"],
          hide: false,
          cellRenderer: this._gridService.currencyRenderer,
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "equals",
            inRangeInclusive: true,
          },
          columnGroupShow: 'open',
          get headerTooltip() { return this.headerName; }
        },
        {
          headerName: "O Unit",
          field: "oldDeliveredUnitCost",
          width: 70,
          cellClass: ["oldDeliveredUnitCost-col", "tar", "old-values-column"],
          hide: false,
          cellRenderer: this._gridService.currencyRenderer,
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "equals",
            inRangeInclusive: true,
          },
          columnGroupShow: 'open',
          get headerTooltip() { return this.headerName; }
        },
      ]
    },
    {
      headerName: "Other",
      field: "offPack",
      width: 125,
      cellClass: ["offPack-col", "tac"],
      hide: true,
      filter: "agTextColumnFilter",
      filterParams: {
        defaultOption: "startsWith",
      },
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Edited",
      field: "edited",
      width: 70,
      cellClass: ["edited-col", "tac"],
      cellRenderer: this._gridService.checkRenderer,
      hide: true,
      filter: "agSetColumnFilter",
      filterParams: {
        values: [true, false],
        newRowsAction: 'keep'
      },
      get headerTooltip() { return this.headerName; }
    },
    // {
    //   headerName: "Item Lists",
    //   field: "itemLists",
    //   width: 300,
    //   cellClass: ["itemLists-col"],
    //   cellRenderer: this.itemListRenderer,
    //   hide: true,
    //   floatingFilterComponent: this._gridService.getCustomItemListFloatingFilter(this),
    //   filter: this._gridService.getCustomItemListFilter(this),
    //   get headerTooltip() { return this.headerName; }
    // },
    {
      headerName: "Is Stock",
      field: "isStock",
      width: 70,
      cellClass: ["isStock-col"],
      hide: true,
      filter: "agSetColumnFilter",
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Is Preview",
      field: "isPreview",
      width: 85,
      cellClass: ["isPreview-col"],
      hide: true,
      filter: "agSetColumnFilter",
      get headerTooltip() { return this.headerName; }
    },
  ];

  
  constructor(
    private _priceManagementFilterService: PriceManagementFilterService,
    private _storeService: StoreService,
    private _gridService: GridService,
    private _messageService: MessageService,
    private _spinnerService: SpinnerService,
    private _helpService: HelpService,
    ) {

    this.gridOptions = <GridOptions> {
			columnDefs: this.columnDefs,
			rowData: this.rowData,
      components: { agColumnHeader: CustomHeaderComponent },
      rowHeight: 35,    
			headerHeight: 25,
			groupHeaderHeight: 25,
			floatingFiltersHeight: 35,
			suppressNoRowsOverlay: true,
			rowSelection: 'single',
      enableRangeSelection: true,
      onSelectionChanged: this.doOnSelectionChanged,
			singleClickEdit: false,
      onCellFocused: this.doOnCellFocused,
			editType: "fullRow",
      animateRows: false,
			rowClassRules: {
				'removed-item-row': function (params) {
          if (params.data != null)
            return false; // TODO???
						//return this.tabModel.activeTab == 'ItemList' && params.data.removedItem;
				},
				'deleted-item-row': function (params) {
					if (params.data != null)
						return params.data.book === 'D';
				},
			},
			defaultColDef: {
        sortable: true,
        resizable: true,
        floatingFilter: true,
        enableValue: true,
        enableRowGroup: true,
        enablePivot: true,
        enableCellChangeFlash: true,
        suppressSpanHeaderHeight: true,
				headerComponentParams: {
          masterInputHtml: '',
				}
      },
      sideBar: {
        toolPanels: [
            {
                id: 'columns',
                labelDefault: 'Columns',
                labelKey: 'columns',
                iconKey: 'columns',
                toolPanel: 'agColumnsToolPanel',
            },
            {
                id: 'filters',
                labelDefault: 'Filters',
                labelKey: 'filters',
                iconKey: 'filter',
                toolPanel: 'agFiltersToolPanel',
            }
        ],
        defaultToolPanel: ''
      },

    }
    this.statusBar = {
      statusPanels: [
        // {
        //   statusPanel: "agTotalRowCountComponent",
        //   align: "left"
        // },
        //{ statusPanel: "agFilteredRowCountComponent" },
        //{ statusPanel: "agSelectedRowCountComponent" },
        { statusPanel: "agAggregationComponent" }
      ]
    };

    this.context = { componentParent: this }
    //this.frameworkComponents = { agColumnHeader: CustomHeaderComponent };
  }

  getLabelFromCode(code) {
    let labelObj = this.privateLabels.find(item => item.letterCode === code);
    if (code === ' ') return '(None)';
    return labelObj ? labelObj.label : code;
  }


  //!///////////////////////////////
  // Angular lifecycle hooks
  //////////////////////////////////

  ngOnInit() {
		if (!('indexedDB' in window)) {
			this._messageService.alert('This browser does not support IndexedDB. Please use Chrome as your browser.');
			return;
    }
    var privateLabels = localStorage.getItem('rpmsPrivateLabels');
    this.privateLabels = privateLabels ? JSON.parse(privateLabels) : [];
    this.getStoreDataFromIndexedDb();
  }

  ngAfterViewInit(){
    var self = this;
    // subscribe to gridFilterModel changes
    this.gridFilterSubscription = this._priceManagementFilterService.gridFilterModel$
      .subscribe(
        (data) => {
          if (this.agGrid.api) {
            this.agGrid.api.setFilterModel(null);
            this.agGrid.api.setFilterModel(data); 
            this.agGrid.api.onFilterChanged();
            this.gridFilterModel = data;
          }
        }
      );
    // subscribe to tabModel changes
    this.tabModelSubscription = this._priceManagementFilterService.tabModel$
      .subscribe(
        (data) => {
          this.applyStylingFromTabModel(data);
          this.tabModel = data;
        }
      );

    // do on page load
    setTimeout(() => { 
      this.onWindowResize();
      this.sizeGrid();
      var metadata = localStorage.getItem('rpmsPricingGridMetadata');
      if (metadata) {
        this.setColumnAndColumnGroupState(JSON.parse(metadata));
      }
    }, 10);
  }

  ngOnDestroy() {
    this.gridFilterSubscription.unsubscribe();
    this.tabModelSubscription.unsubscribe();
  }

  setColumnAndColumnGroupState(metadata, callback?) {
    //this allows for old version - different data shape
    if (metadata.hasOwnProperty('columnState')) {
      this.agGrid.api.applyColumnState({state: metadata.columnState, applyOrder: true,});
      this.agGrid.api.setColumnGroupState(metadata.columnGroupState);
    }
    else {
      this.agGrid.api.applyColumnState({state: metadata, applyOrder: true,});
    }
    if (typeof callback == 'function') callback();
  }

  applyStylingFromTabModel(tabModel){
    //this makes the grid and controls match the tabs color
    var wrapperElement = <HTMLElement>document.querySelector('#pm-grid-and-controls');
    if (wrapperElement) wrapperElement.className = tabModel.activeTab;
  }

  //!///////////////////////////////
  // Store selection
  //////////////////////////////////

  onStoreSelected(){
    this._storeService.setSelectedStore(this.store);
    this.isStoreSelected = true;
    this.isGridDataLoaded = false;
    this.getPricingDataFromIndexedDb();
  }

  //this allows for setting a select value with an object
  compareStoreObjects(o1: any, o2: any): boolean {
    return o1 && o2 ? o1.storeId === o2.storeId : false;
  }

  //!///////////////////////////////
  // Get store data from Indexed Db
  //////////////////////////////////

  // this will only be used for offline mode

  getStoreDataFromIndexedDb(){
    var self = this;
    var db;
    var requestOpen = window.indexedDB.open("RPMS2", 3);

    requestOpen.onerror = function (event) {
      self._messageService.alert("Failed to open offline database.");
    }

    requestOpen.onsuccess = function (event:any) {
      db = event.target.result;
      var objectStore = db.transaction("storeData").objectStore("storeData");

      objectStore.openCursor().onsuccess = function (event) {
        var cursor = event.target.result;
        if (cursor) {
          self.stores.push(cursor.value.store);
          cursor.continue();
        }
        else {
          //no more storeData entries
        }
      }
    }
  }

  //!///////////////////////////////
  // Get items from Indexed Db
  //////////////////////////////////

  getPricingDataFromIndexedDb() {
    var self = this;
    let count = 0;
    var dataChunkArray = [];
    var allItemsData = [];
    var dataDateTime = null;
    self._spinnerService.handleProgressSpinnerVisibility('show', 'Getting data from IndexedDb...');
    var storeId = this.store.storeId;
    var db: any;
    var requestOpen = window.indexedDB.open("RPMS2", 3);
    requestOpen.onerror = function(event) {
      self._messageService.alert('Failed to open indexedDb.');
    };

    requestOpen.onsuccess = function (event: any) {
      self._spinnerService.handleProgressSpinnerVisibility('show');
      db = event.target.result;
      var transaction = db.transaction(["pricingData"]);
      var objectStore = transaction.objectStore("pricingData");

      objectStore.openCursor().onsuccess = function (event) {
        var cursor = event.target.result;
        if (cursor && count < 2) { //some key (any key) exists. LIMITING TO 2 KEYS PER STORE. CHANGE THIS 2 IF THAT CHANGES (this count keeps it from continuing to check all keys in IDB after it has found 2 that match the storeId)
          var keyString = cursor.key.toString();
          var storeIdFromKey = keyString.substring(0, keyString.indexOf('-'));
          if (storeIdFromKey == storeId){
            count++;
            dataChunkArray.push(JSON.parse(cursor.value.gridData));
            dataDateTime = cursor.value.dateTime;
          }
          cursor.continue();
        }
        else { //cursor has navigated through all keys (or no keys exist)
          if (dataChunkArray.length > 0){ //data has been loaded into allItemsData variable
              for (var i = 0; i < dataChunkArray.length; i++){
                allItemsData = allItemsData.concat(dataChunkArray[i]);
              }
              self.dataInfoBarMessage = 'You are viewing local data that was saved on ' + dataDateTime.toLocaleDateString() + ' at ' + dataDateTime.toLocaleTimeString() + '.';
              self.isDataInfoBarVisible = true;
              //give info bar time to become visible
              setTimeout(() => {}, 100);
              self.createAllItemsHashTable(allItemsData);
              self.formatandSetRowData(allItemsData);
              //size grid since data information bar moved it down
              setTimeout(() => { self.sizeGrid() }, 100);
          }
          else{// data doesn't exist for selected store. Load all data from server
            self._messageService.showToastTopRight('No data found in indexedDb');
          }
        }
      }
    }

    //this will create a new RPMS database if it doesn't exist
    requestOpen.onupgradeneeded = function (event: any) {
      var newVersion = event.target.result;
      if (!newVersion.objectStoreNames.contains("pricingData")) {
        newVersion.createObjectStore("pricingData", { keyPath: "key" });
      }
      if (!newVersion.objectStoreNames.contains("storeData")) {
        newVersion.createObjectStore("storeData", { keyPath: "storeId" });
      }
    }
  }

  createAllItemsHashTable(allItemsData) {
    this._spinnerService.handleProgressSpinnerVisibility('show', 'Creating hash table...');
    this.allItemsHashTable = {};
    for (var i = 0; i < allItemsData.length; i++) {
      var key = allItemsData[i].isPreview ? 'P' + allItemsData[i].item.itemCode : 'S' + allItemsData[i].item.itemCode;
      this.allItemsHashTable[key] = allItemsData[i];
    }
    //console.log('all items hash table completed');
    //console.log(this.allItemsHashTable);
  }

  //!///////////////////////////////
  // Format items for grid
  //////////////////////////////////

  formatSingleRowData(data) {
    var singleRowData = {}; //singleRowData gets returned from this function
    if (data){
      var lastPurchasedDate = data.lastPurchasedDate;
      var shelfTagPendingFulfillment = data.shelfTagPendingFulfillment;
      var shelfTagRequestDate = data.shelfTagRequestDate;
      var shelfTagQuantityOrdered = data.shelfTagQuantityOrdered;
      var shelfTagStatus = this.getShelfTagStatus(data);
      var tprTagPendingFulfillment = data.tprTagPendingFulfillment;
      var tprTagRequestDate = data.tprTagRequestDate;
      var tprPersist = data.tprPersist;
      var tprBook = data.tprBook;
      var tprTagStatus = this.getTprTagStatus(data);
      var storeItemId = data.storeItemId;
      var lastYearsMovement = data.item.lastYearsMovement;
      var yearToDateMovement = data.item.yearlyMovement;
      var weeksInInventory = data.item.weeksInInventory;
      var averageWeeklyMovement = data.item.averageWeeklyMovement;
      var balanceOnHand = data.item.balanceOnHand;
      var offPack = data.item.offPack;
      var groupNumber = data.item.groupNumber;
      var groupDescription = data.item.groupDescription;
      var buyerCode = data.item.buyerCode;
      var buyerName = data.item.buyerFullName;
      var buyerPhone = data.item.buyerPhoneNumber;
      var buyerEmail = data.item.buyerEmailAddress;
      var lastYearsMovementForStore = data.lastYearsMovementForStore;
      var yearToDateMovementForStore = data.yearlyMovementForStore;
      var weeksInStore = data.weeksInStore;
      var averageWeeklyMovementForStore = data.averageWeeklyMovementForStore;

      //If item is preview, overwrite these properties with properties from associated store item
      if (data.isPreview){
        let key = 'S' + data.item.itemCode; //Store items have an 'S' prefix
        var associatedStoreItemToPreviewItem = this.allItemsHashTable[key];
        if (associatedStoreItemToPreviewItem){
          lastPurchasedDate = associatedStoreItemToPreviewItem.lastPurchasedDate;
          shelfTagPendingFulfillment = associatedStoreItemToPreviewItem.shelfTagPendingFulfillment;
          shelfTagRequestDate = associatedStoreItemToPreviewItem.shelfTagRequestDate;
          shelfTagQuantityOrdered = associatedStoreItemToPreviewItem.shelfTagQuantityOrdered;
          shelfTagStatus = this.getShelfTagStatus(associatedStoreItemToPreviewItem);
          tprTagPendingFulfillment = associatedStoreItemToPreviewItem.tprTagPendingFulfillment;
          tprTagRequestDate = associatedStoreItemToPreviewItem.tprTagRequestDate;
          tprPersist = associatedStoreItemToPreviewItem.tprPersist;
          tprBook = associatedStoreItemToPreviewItem.tprBook;
          tprTagStatus = this.getTprTagStatus(associatedStoreItemToPreviewItem);
          storeItemId = associatedStoreItemToPreviewItem.storeItemId;
          lastYearsMovement = associatedStoreItemToPreviewItem.item.lastYearsMovement;
          yearToDateMovement = associatedStoreItemToPreviewItem.item.yearlyMovement;
          weeksInInventory = associatedStoreItemToPreviewItem.item.weeksInInventory;
          averageWeeklyMovement = associatedStoreItemToPreviewItem.item.averageWeeklyMovement;
          balanceOnHand = associatedStoreItemToPreviewItem.item.balanceOnHand;
          offPack = associatedStoreItemToPreviewItem.item.offPack;
          groupNumber = associatedStoreItemToPreviewItem.item.groupNumber;
          groupDescription = associatedStoreItemToPreviewItem.item.groupDescription;
          buyerCode = associatedStoreItemToPreviewItem.item.buyerCode;
          buyerName = associatedStoreItemToPreviewItem.item.buyerFullName;
          buyerPhone = associatedStoreItemToPreviewItem.item.buyerPhoneNumber;
          buyerEmail = associatedStoreItemToPreviewItem.item.buyerEmailAddress;
          lastYearsMovementForStore = associatedStoreItemToPreviewItem.lastYearsMovementForStore;
          yearToDateMovementForStore = associatedStoreItemToPreviewItem.yearlyMovementForStore;
          weeksInStore = associatedStoreItemToPreviewItem.weeksInStore;
          averageWeeklyMovementForStore = associatedStoreItemToPreviewItem.averageWeeklyMovementForStore;
        }
        else {
          this._messageService.alert('The preview item with item code: ' + data.item.itemCode + ' has no associated store item in the hash table. Please take note that the data that will be shown for this preview item will be from the preview item itself and not its associated store item.', 'Warning!');
        }
      }

      singleRowData = {
        action: "",
        itemCode: data.item.itemCode,
        deptCode: data.item.deptCode,
        isStock: data.isStock,
        isPreview: data.isPreview ? true : false, //signalR doesn't have isPreview property for filters, so must do this
        size: data.item.size,
        description: (data.item.description).trim(),
        longDescription: (data.item.longDescription != null) ? (data.item.longDescription).trim() : '',
        privateLabel: data.item.privateLabel == '' ? ' ' : data.item.privateLabel,
        newItem: data.item.newItem,
        firstTimeBuy: data.firstTimeBuy,
        wicItem: false, //data.wicItem,
        nowItem: data.nowItem,
        firstTimeBuyOrNowItem: data.firstTimeBuyOrNowItem,
        unitCostLessDeliveryFee: (data.isPreview) ? this.convertToTwoDecimals(data.previewItem.defaultUnitCostLessDeliveryFee) : this.convertToTwoDecimals(data.unitCostLessDeliveryFee),
        deliveredUnitCost: (data.isPreview) ? this.convertToTwoDecimals(data.previewItem.defaultDeliveredUnitCost) : this.convertToTwoDecimals(data.deliveredUnitCost),
        caseCostLessDeliveryFee: (data.isPreview) ? this.convertToTwoDecimals(data.previewItem.defaultCaseCostLessDeliveryFee) : this.convertToTwoDecimals(data.caseCostLessDeliveryFee),
        deliveredCaseCost: (data.isPreview) ? this.convertToTwoDecimals(data.previewItem.defaultDeliveredCaseCost) : this.convertToTwoDecimals(data.deliveredCaseCost),
        units: (data.isPreview) ? data.previewItem.units : data.item.units,
        baseCost: (data.isPreview) ? this.convertToTwoDecimals(data.previewItem.defaultBaseCost) : this.convertToTwoDecimals(data.baseCost),
        masterChangeCheckbox: false,
        book: data.book,
        srpCode: data.srpCode,
        multi: data.multi,
        currentPrice: data.currentPrice,
        price: data.price,
        percent: data.percent,
        deal: (data.isPreview) ? data.previewItem.dealAmt : data.dealAmt,
        dealDate: (data.isPreview) ? (data.previewItem.dealDate != null && data.previewItem.dealDate != '' ? moment(data.previewItem.dealDate, 'MMDDYY').toDate() : '') : (data.dealDate != null ? moment(data.dealDate).toDate() : ''),
        chg: (data.changeCode != null) ? data.changeCode : '',
        oldBook: (data.lastBook != null) ? data.lastBook : '',
        oldSrpCode: (data.lastSrpCode != null) ? data.lastSrpCode : '',
        oldMulti: (data.lastMulti != null) ? data.lastMulti : '',
        oldPrice: (data.lastPrice != null) ? data.lastPrice : '',
        oldKeepSrp: (data.lastKeepSrp != null) ? data.lastKeepSrp : '',
        oldPercent: (data.lastPercent != null) ? data.lastPercent : '',
        oldKeepPercent: (data.lastKeepPercent != null) ? data.lastKeepPercent : '',
        oldBaseCost: (data.lastBaseCost != null) ? this.convertToTwoDecimals(data.lastBaseCost) : '',
        oldDeliveredCaseCost: (data.lastDeliveredCaseCost != null) ? this.convertToTwoDecimals(data.lastDeliveredCaseCost) : '',
        oldDeliveredUnitCost: (data.lastDeliveredUnitCost != null) ? this.convertToTwoDecimals(data.lastDeliveredUnitCost) : '',
        edited: data.edited,
        //itemLists: this.getItemListsFromItemCode(data.item.itemCode),
        itemId: data.item.itemId,
        upcCode: data.item.upcCode,
        status: data.item.status,
        statusDescription: this.getStatusDesc(data.item.status, data.item.balanceOnHand),
        lastYearsMovement: lastYearsMovement,
        yearToDateMovement: yearToDateMovement,
        weeksInInventory: weeksInInventory,
        averageWeeklyMovement: averageWeeklyMovement ? Math.round(averageWeeklyMovement * 100) / 100 : 0,
        balanceOnHand: balanceOnHand,
        offPack: offPack,
        groupNumber: Number(groupNumber),
        groupDescription: groupDescription,
        buyerCode: buyerCode,
        buyerName: buyerName,
        buyerPhone: buyerPhone,
        buyerEmail: buyerEmail,
        storeNumber: this.store.storeNumber,
        storeItemId: storeItemId,
        previewStoreItemId: data.previewStoreItemId,
        lastYearsMovementForStore: lastYearsMovementForStore,
        yearToDateMovementForStore: yearToDateMovementForStore,
        weeksInStore: weeksInStore,
        averageWeeklyMovementForStore: averageWeeklyMovementForStore ? Math.round(averageWeeklyMovementForStore * 100) / 100 : 0,
        lastPurchasedDate: (lastPurchasedDate != null && lastPurchasedDate != '') ? moment.utc(new Date(lastPurchasedDate)).format("MM/DD/YY") : '',
        shelfTagPendingFulfillment: shelfTagPendingFulfillment,
        shelfTagRequestDate: (shelfTagRequestDate != null && shelfTagRequestDate != '') ? moment.utc(new Date(shelfTagRequestDate)).format("MM/DD/YY") : '',
        shelfTagQuantityOrdered: shelfTagQuantityOrdered,
        shelfTagStatus: shelfTagStatus,
        tprTagPendingFulfillment: tprTagPendingFulfillment,
        tprTagRequestDate: (tprTagRequestDate != null && tprTagRequestDate != '') ? moment.utc(new Date(tprTagRequestDate)).format("MM/DD/YY") : '',
        tprPersist: tprPersist,
        tprBook: tprBook,
        tprTagStatus: tprTagStatus,
        keepSrp: data.keepSrp,
        keepPercent: data.keepPercent,
        multi1: (data.isPreview) ? data.previewItem.multi1 : data.item.multi1,
        base1: (data.isPreview) ? data.previewItem.base1 : data.item.base1,
        percent1: (data.isPreview) ? data.previewItem.percent1 : data.item.percent1,
        multi2: (data.isPreview) ? data.previewItem.multi2 : data.item.multi2,
        base2: (data.isPreview) ? data.previewItem.base2 : data.item.base2,
        percent2: (data.isPreview) ? data.previewItem.percent2 : data.item.percent2,
        multi3: (data.isPreview) ? data.previewItem.multi3 : data.item.multi3,
        base3: (data.isPreview) ? data.previewItem.base3 : data.item.base3,
        percent3: (data.isPreview) ? data.previewItem.percent3 : data.item.percent3,
        multi4: (data.isPreview) ? data.previewItem.multi4 : data.item.multi4,
        base4: (data.isPreview) ? data.previewItem.base4 : data.item.base4,
        percent4: (data.isPreview) ? data.previewItem.percent4 : data.item.percent4,
        multi5: (data.isPreview) ? data.previewItem.multi5 : data.item.multi5,
        base5: (data.isPreview) ? data.previewItem.base5 : data.item.base5,
        percent5: (data.isPreview) ? data.previewItem.percent5 : data.item.percent5,
        multi6: (data.isPreview) ? data.previewItem.multi6 : data.item.multi6,
        base6: (data.isPreview) ? data.previewItem.base6 : data.item.base6,
        percent6: (data.isPreview) ? data.previewItem.percent6 : data.item.percent6,
        multi7: (data.isPreview) ? data.previewItem.multi7 : data.item.multi7,
        base7: (data.isPreview) ? data.previewItem.base7 : data.item.base7,
        percent7: (data.isPreview) ? data.previewItem.percent7 : data.item.percent7,
        multi1C: (data.isPreview) ? data.previewItem.multi1C : data.item.multi1C,
        current1: (data.isPreview) ? data.previewItem.current1 : data.item.current1,
        percent1C: (data.isPreview) ? data.previewItem.percent1C : data.item.percent1C,
        multi2C: (data.isPreview) ? data.previewItem.multi2C : data.item.multi2C,
        current2: (data.isPreview) ? data.previewItem.current2 : data.item.current2,
        percent2C: (data.isPreview) ? data.previewItem.percent2C : data.item.percent2C,
        multi3C: (data.isPreview) ? data.previewItem.multi3C : data.item.multi3C,
        current3: (data.isPreview) ? data.previewItem.current3 : data.item.current3,
        percent3C: (data.isPreview) ? data.previewItem.percent3C : data.item.percent3C,
        multi4C: (data.isPreview) ? data.previewItem.multi4C : data.item.multi4C,
        current4: (data.isPreview) ? data.previewItem.current4 : data.item.current4,
        percent4C: (data.isPreview) ? data.previewItem.percent4C : data.item.percent4C,
        multi5C: (data.isPreview) ? data.previewItem.multi5C : data.item.multi5C,
        current5: (data.isPreview) ? data.previewItem.current5 : data.item.current5,
        percent5C: (data.isPreview) ? data.previewItem.percent5C : data.item.percent5C,
        multi6C: (data.isPreview) ? data.previewItem.multi6C : data.item.multi6C,
        current6: (data.isPreview) ? data.previewItem.current6 : data.item.current6,
        percent6C: (data.isPreview) ? data.previewItem.percent6C : data.item.percent6C,
        multi7C: (data.isPreview) ? data.previewItem.multi7C : data.item.multi7C,
        current7: (data.isPreview) ? data.previewItem.current7 : data.item.current7,
        percent7C: (data.isPreview) ? data.previewItem.percent7C : data.item.percent7C,
        removedItem: false,
      };
    }
    return singleRowData;
  }

  getStatusDesc(statusCode, balanceOnHand) {
    switch (statusCode) {
      case '1': return 'Net Net Item';
      case 'D': return (balanceOnHand > 0) ? 'Active' : 'Deleted';
      case 'L': return 'Long Term Unavailable';
      case 'T': return 'Temporarily Unavailable';
      case 'C': return 'Comment Line';
      case 'I': return 'In & Out Item';
      case 'R': return 'Replaced Item';
      case '': return 'Active';
      default: return 'Unknown';
    }
  }

  getTprTagStatus(storeItem){
    var tprTagStatus = '';
    if (storeItem.tprTagPendingFulfillment) {
      if (storeItem.tprPersist) {
        tprTagStatus = "Always, Bk ";
      }
      else {
        tprTagStatus = "Once, Bk ";
      }
      tprTagStatus += storeItem.tprBook + ', on ' + moment(storeItem.tprTagRequestDate).format('M/D/YYYY');
    }
    else {
      tprTagStatus = "None Requested";
    }
    return tprTagStatus;
  }

  getShelfTagStatus(storeItem){
    return (storeItem.shelfTagPendingFulfillment) ?
      storeItem.shelfTagQuantityOrdered + ' Requested, on ' + moment(storeItem.shelfTagRequestDate).format('M/D/YYYY') : "None Requested";
  }

  public getRowId = (params) => {
    var nodeId = params.data.isPreview ? 'P' + params.data.itemCode : 'S' + params.data.itemCode;
    return String(nodeId);
  }

  formatandSetRowData(data, callback?) {
    this._spinnerService.handleProgressSpinnerVisibility('show', 'Formatting Data...');
    var gridData = [];
    for (var i=0; i<data.length; i++){
      var singleRowData:any = this.formatSingleRowData(data[i]);
      gridData.push(singleRowData);
    }
    //callback to add itemCode as rowNodeId
    // this.gridOptions.getRowNodeId = function(formattedRowData) {
    //   var nodeId = formattedRowData.isPreview ? 'P' + formattedRowData.itemCode : 'S' + formattedRowData.itemCode;
    //   return String(nodeId);
    // };
    this.rowData = gridData;
    this.onWindowResize();
    setTimeout(() => {  
      this.isShowingFooter = true;
      this.sizeGrid();
      var tabModel = this._priceManagementFilterService.getTabModel();
      this._priceManagementFilterService.setGridFilterModelFromTabModel(tabModel);
      this.isGridDataLoaded = true;
      this.agGrid.api.setFocusedCell(0, 'itemCode', null);
      this._spinnerService.handleProgressSpinnerVisibility('hide', 'Processing Request...');
      if (typeof callback === 'function') callback();
    },100); 
  }

  //!///////////////////////////////
  // Grid sizing and events
  //////////////////////////////////

  //Grid sizing was changed to be handled by CSS (flexbox) on 6/21/2022
  //This function and references to it can eventually be removed
  sizeGrid() {
    // var spaceBelowGrid = 35;
    // var gridElement = <HTMLElement>document.querySelector('#pm-ag-grid-wrapper');
    // var wrapperElement = <HTMLElement>document.querySelector('#pm-grid-and-controls');
    // var footerWrapperElement = <HTMLElement>document.querySelector('#pm-grid-footer-wrapper');
    // var windowHeight = window.innerHeight;
    // if (document.body.contains(gridElement) && document.body.contains(wrapperElement)){
    //   var wrapperOffset = wrapperElement.getBoundingClientRect().top;
    //   var remainingSpace = windowHeight - wrapperOffset;
    //   var gridHeight = this.isShowingFooter ? remainingSpace - 252 - spaceBelowGrid : remainingSpace - spaceBelowGrid - 107;
    //   wrapperElement.style.height = (windowHeight - wrapperOffset - spaceBelowGrid - 5) + 'px';
    //   gridElement.style.height = gridHeight + 'px';
    //   var bottom = this.isShowingFooter ? 5 : 0;
    //   footerWrapperElement.style.bottom = bottom + 'px';
    // }
  }

  onWindowResize() {
    var self = this;
    var rtime;
    var timeout = false;
    var delta = 200;
    window.onresize = function () {
      rtime = new Date();
      if (timeout === false) {
        timeout = true;
        setTimeout(resizeend, delta);
      }
    };

    function resizeend() {
      if (new Date().getTime() - rtime < delta) {
        setTimeout(resizeend, delta);
      } else {
        timeout = false;
        //done resizing
        self.sizeGrid();
      }
    }
  }

  onModelUpdated(){
    this.rowCount = this.agGrid.api.getDisplayedRowCount();
  }

  doOnCellFocused(params){
    //console.log(params);
    var self = params.context.componentParent;
			//cell focused gets called on grid load before a cell is focused so must check for null
			if (params.rowIndex != null) {
          var rowNode = params.api.getDisplayedRowAtIndex(params.rowIndex);
          if (rowNode){
            rowNode.setSelected(true, true);
            params.context.componentParent.selectedRowData = rowNode.data;
            params.context.componentParent.selectedRowNode = rowNode;
            params.context.componentParent.isKeepSrpInFooterChecked = rowNode.data.keepSrp;
            params.context.componentParent.isKeepPercentInFooterChecked = rowNode.data.keepPercent;
            params.context.componentParent.currentFocusedCell = params;
          }
			}
  }

  doOnSelectionChanged(params){
    var self = params.context.componentParent;
    var selectedRow = params.api.getSelectedRows()[0];
    self.agGrid.api.setGridOption('pinnedBottomRowData',[selectedRow]);
  }

  //scrolls selected row into view with eyeball button
  viewSelectedRow() {
    var selectedNode = this.agGrid.api.getSelectedNodes()[0];
    var viewport = document.querySelector('.ag-body-viewport');
    viewport.scrollTop = selectedNode.rowTop;
  }
  
  resetColumns() {
    this.clearFilters();
    this.agGrid.api.resetColumnState();
  }

  //!///////////////////////////////
  // Footer
  //////////////////////////////////

  showFooter(){
    this.isShowingFooter = true;
    this.sizeGrid();
  }

  hideFooter(){
    this.isShowingFooter = false;
    this.sizeGrid();
  }

  //!///////////////////////////////
  // Grid filter
  //////////////////////////////////

  clearFilters(){
    var gridFilterModel = this.agGrid.api.getFilterModel();
    this.quickFilterText = '';
    this.agGrid.api.setGridOption('quickFilterText', this.quickFilterText);
    this._priceManagementFilterService.setQuickFilterText(this.quickFilterText);
    var deptCode = gridFilterModel.deptCode;
    var isStock = gridFilterModel.isStock;
    var isPreview = gridFilterModel.isPreview;
    this.agGrid.api.setFilterModel(null);
    var clearedFilterModel = {
      deptCode: deptCode,
      isStock: isStock,
      isPreview: isPreview,
    }
    this._priceManagementFilterService.setGridFilterModel(clearedFilterModel);
    this.agGrid.api.setFilterModel(clearedFilterModel);
  }

  onQuickFilterChange(){
    this.agGrid.api.setGridOption('quickFilterText', this.quickFilterText);
    this._priceManagementFilterService.setQuickFilterText(this.quickFilterText);
  }

  onFilterChanged(){
    var gridFilterModel = this.agGrid.api.getFilterModel();
    this._priceManagementFilterService.setGridFilterModel(gridFilterModel);
    this.isFilterApplied = this._priceManagementFilterService.testIfFilterApplied();
  }

  //!///////////////////////////////
  // Grid column helpers
  //////////////////////////////////

  autoSizeColumns(){
    var columns = this.agGrid.api.getColumns();
    this.agGrid.api.autoSizeColumns(columns);
  }

  fitColumns(){
    this.agGrid.api.sizeColumnsToFit();
  }

  //!///////////////////////////////
  // Renderers
  //////////////////////////////////

  pricePercentRenderer(params){
    var self = params.context.componentParent;
    var eCell;
    switch(params.column.colId){
      case 'price':
        eCell = self._gridService.currencyRenderer(params);
        break;
      case 'keepSrp':
        eCell = self._gridService.checkRenderer(params);
        break;
      case 'keepPercent':
        eCell = self._gridService.checkRenderer(params);
        break;
      default:
        eCell = document.createElement('span');
        var value = document.createTextNode(params.value);
        eCell.appendChild(value);
        break;
    }
    return eCell;
  }

  bookSrpCodeRenderer(params){
    var eCell;
    eCell = document.createElement('span');
    var value = document.createTextNode(params.value);
    eCell.appendChild(value);
    return eCell;
  }

  //!///////////////////////////////
  // Formatters and Misc
  //////////////////////////////////

  isEmpty(obj) {
    var hasOwnProperty = Object.prototype.hasOwnProperty;
      if (obj == null) return true;
      if (obj.length > 0)    return false;
      if (obj.length === 0)  return true;
      if (typeof obj !== "object") return true;
      for (var key in obj) {
          if (hasOwnProperty.call(obj, key)) return false;
      }
      return true;
  }

  convertToTwoDecimals(num) {
    if (num && !isNaN(num)) return Number(num.toFixed(2));
    else return;
  }

  //https://stackoverflow.com/questions/728360/how-do-i-correctly-clone-a-javascript-object
  cloneObject(obj) {
    var copy;

    // Handle the 3 simple types, and null or undefined
    if (null == obj || "object" != typeof obj) return obj;

    // Handle Date
    if (obj instanceof Date) {
        copy = new Date();
        copy.setTime(obj.getTime());
        return copy;
    }

    // Handle Array
    if (obj instanceof Array) {
        copy = [];
        for (var i = 0, len = obj.length; i < len; i++) {
            copy[i] = this.cloneObject(obj[i]);
        }
        return copy;
    }

    // Handle Object
    if (obj instanceof Object) {
        copy = {};
        for (var attr in obj) {
            if (obj.hasOwnProperty(attr)) copy[attr] = this.cloneObject(obj[attr]);
        }
        return copy;
    }

    throw new Error("Unable to copy obj! Its type isn't supported.");
  }

  closeDataInfoBar(){
    this.isDataInfoBarVisible = false;
    setTimeout(() => this.sizeGrid(), 100);
  }

  // these are needed by the custom grid header
  showHelpfulTip(event){
    var self = this;
    var helps:any = document.getElementsByClassName('help');
    if (helps.length){
      for (var i = 0; i < helps.length; i++) {
        helps[i].style.opacity = '0';
        helps[i].style.transform = 'scale(.1, .1)';  
        helps[i].style['pointer-events'] = 'none';
      }
    }
    var target = event.target.attributes['data-help'].value;
    var tooltip = <HTMLElement>document.getElementById(target + '-tooltip');
    var isHelpfulMode = self._helpService.getIsHelpfulMode();
    if (tooltip && isHelpfulMode){
      tooltip.style['pointer-events'] = 'auto';
      tooltip.style.position = "absolute";
      tooltip.style.left = event.x +'px';
      tooltip.style.top = event.y +'px';
      tooltip.addEventListener('mouseover', function(){
        event.target.classList.add('child-has-mouse');
      })
      tooltip.addEventListener('mouseleave', function(){
        event.target.classList.remove('child-has-mouse');
        self.hideHelpfulTip(event);
      })
      self.showHelpTimeout = setTimeout(() => {
        tooltip.style['pointer-events'] = 'auto';
        tooltip.style.opacity = '1';
        tooltip.style.transform = 'scale(1, 1)';
        }, 1000);
    }
  }

  hideHelpfulTip(event){
    var self = this;
    clearTimeout(self.showHelpTimeout);
    setTimeout(() => {
      if (!event.target.classList.contains('child-has-mouse')){
        var target = event.target.attributes['data-help'].value;
        var tooltip = <HTMLElement>document.getElementById(target + '-tooltip');
        var isHelpfulMode = self._helpService.getIsHelpfulMode();
        if (tooltip && isHelpfulMode){
          tooltip.style.opacity = '0';
          tooltip.style.transform = 'scale(.1, .1)';
          var helps:any = document.getElementsByClassName('help');
          //clear all of them if any are visible
          if (helps.length){
            for (var i = 0; i < helps.length; i++) {
              helps[i].style.opacity = '0';
              helps[i].style.transform = 'scale(.1, .1)';  
              helps[i].style['pointer-events'] = 'none';
            }
          }
        }
      }
    }, 100);
  }

}
