import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { MessageService } from 'src/app/shared/message.service';
import { StoreService } from 'src/app/shared/store.service';

@Component({
  selector: 'rpms-controlled-store-diagram',
  templateUrl: './controlled-store-diagram.component.html',
  styleUrls: ['./controlled-store-diagram.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ControlledStoreDiagramComponent implements OnInit {
  @Input() stores: any[];
  @Input() store: any;
  @Output() shouldControlledStorePricingColumnBeVisibleEvent = new EventEmitter<boolean>();

  storeGroupDiagramHtml = '<div id="controlled-store-diagram">Test</div>';
  controllingStores;
  shouldDiagramBeVisible:boolean = false;
  storesCopy;
  storeCopy;

  constructor(
    private _storeService: StoreService,
    private _messageService: MessageService
  ) { }

  ngOnInit() {
    // console.log('ON INIT');
    // console.log(this.stores);
    // console.log(this.store);
    this.getAllStores();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log('ON CHANGES');
    // console.log(this.stores);
    // console.log(this.store);

    //GAIR-162 COMMENTED OUT AS A QUICK FIX - needs more work
    // this.storesCopy = JSON.parse(JSON.stringify(this.stores));
    // this.storeCopy = JSON.parse(JSON.stringify(this.store));
    // if (this.store.storeId) {
    //   if (this.store.controllingStoreId != null){
    //     //this is a controlled store. Get all controlled stores from the controlling store
    //     this.getControlledStores(this.store.controllingStoreId);
    //   }
    //   else {
    //     //this could be a controlling store or not, but it's not controlled. See if it has controlled stores
    //     this.getControlledStores(this.store.storeId);
    //   }
    // }
  }

  showDiagram(){
    let diagram:any = document.getElementById('store-diagram');
    if (diagram) {
      diagram.style.opacity = '1';
      diagram.style.transform = 'scale(1, 1)';
    }
  }

  hideDiagram(){
    let diagram:any = document.getElementById('store-diagram');
    if (diagram) {
      diagram.style.opacity = '0';
      diagram.style.transform = 'scale(.1, .1)';
    }
  }

  getAllStores(){
    this._storeService.getAllStores()
    .subscribe(
      (data) => {
        //console.log(data);
      },
      (response) => {
        this._messageService.onFailure('Failed to get all Stores.', response);
      })
  }

  getControlledStores(storeId){
    this._storeService.getControlledStores(storeId)
    .subscribe(
      (data) => {
        let controlledStores: any = data;
        if (controlledStores.length) {
          //this is a controlling store
          let controllingStore = this.getStoreFromStoresById(storeId)
          controllingStore.controlledStores = controlledStores;
          this.renderDiagramFromStoreWithControlledStoresData(controllingStore);
          this.shouldDiagramBeVisible = true;
          this.shouldControlledStorePricingColumnBeVisibleEvent.next(true);
        }
        else {
          //this has no controlled stores. Don't show anything
          this.shouldDiagramBeVisible = false;
          this.shouldControlledStorePricingColumnBeVisibleEvent.next(false);
        }
      },
      (response) => {
        this._messageService.onFailure('Failed to get Controlled Stores.', response);
      })
  }

  getStoreFromStoresById(storeId) {
    return this.storesCopy.find(s => s.storeId === storeId);
  }

  renderDiagramFromStoreWithControlledStoresData(controllingStore) {
    let htmlString = '';
    htmlString += `<div id="controlled-store-diagram">
                      <div class="controlling-store">${controllingStore.numberAndLocation} (Controlling)</div>
                      <div class="controlled-store-wrapper">`;
    //if there is more than one controlled store, sort them in store number order
    if (controllingStore.controlledStores.length > 1) controllingStore.controlledStores.sort(this.compare);
    for (let i=0; i<controllingStore.controlledStores.length; i++){
      htmlString += `<div class="controlled-store"><span>-</span>${controllingStore.controlledStores[i].numberAndLocation}</div>`
    }
    htmlString += `</div`;
    this.storeGroupDiagramHtml = htmlString;
  }

  compare(a, b) {
    if (Number(a.storeNumber) < Number(b.storeNumber))
      return -1;
    if (Number(a.storeNumber) > Number(b.storeNumber))
      return 1;
    return 0;
  }

}
