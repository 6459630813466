import { Component, OnInit, ViewChild, Inject, ElementRef, Output, EventEmitter, SecurityContext, HostListener, ChangeDetectionStrategy, } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { GridOptions } from 'ag-grid-community';
import { GridService } from 'src/app/shared/grid.service';
import { MessageService } from 'src/app/shared/message.service';
import { StoreService } from 'src/app/shared/store.service';
import * as moment from 'moment';
import { PriceManagementService } from '../shared/price-management.service';
import { PriceManagementFilterService } from './price-management-filter.service';
import { PriceManagementItemFilterComponent } from './price-management-item-filter/price-management-item-filter.component';
import { SpinnerService } from '../shared/spinner.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ItemListService } from '../shared/item-list.service';
import { ConfirmationDialogComponent } from '../shared/confirmation-dialog/confirmation-dialog.component';
import { RpmsMainframeOperationsService } from '../shared/rpms-mainframe-operations.service';
import { DomSanitizer } from '@angular/platform-browser';
import { CustomHeaderComponent } from './custom-header/custom-header.component';
import { ScheduleService } from '../shared/schedule.service';
import { AccessService } from '../shared/access.service';
import { Router, NavigationStart } from '@angular/router';
import { PriceManagementDataService } from './price-management-data.service';
import 'signalR';
import { NotificationWindowService } from '../shared/notification-window.service';
import { AuthenticationService } from '../core/authentication/shared/authentication.service';
import { PermissionsService } from '../shared/permissions.service';
import { HelpService } from '../shared/help.service';
import { PrivateLabelsService } from '../shared/private-labels.service';
import { SignalRService } from '../shared/signal-r.service';
import { ChangeDetectorRef } from '@angular/core';
import { ReportService } from '../shared/report.service';
import { environment } from 'src/environments/environment';
import { ViewControlledStorePricingButtonComponent } from '../shared/view-controlled-store-pricing-button/view-controlled-store-pricing-button.component';
import { AppConfig } from '../app.config';

declare const $: any;

@Component({
  selector: 'app-price-management',
  templateUrl: './price-management.component.html',
  styleUrls: ['./price-management.component.css'],
})
export class PriceManagementComponent implements OnInit {
  @ViewChild('agGrid') agGrid: AgGridAngular;
  @ViewChild('itemFilterComponent', {static: true}) itemFilterComponent: PriceManagementItemFilterComponent;
  private config = AppConfig.settings;

  confirmationDialogRef: MatDialogRef<ConfirmationDialogComponent>;
  groupHeadersDialogRef: MatDialogRef<GroupHeadersDialog>;

  gridOptions: GridOptions;
  quickFilterText: string = '';
  context; //required by grid
  //frameworkComponents; //required by grid

  isStoreSelected:boolean = false;
  isGridDataLoaded:boolean = false;
  isPricePercentEditorVisible:boolean = false;
  isBookEditorVisible: boolean = false;
  isShelfTagEditorVisible: boolean = false;
  isTprTagEditorVisible: boolean = false;
  isItemListEditorVisible: boolean = false;
  isControlledStorePricingVisible: boolean = false;
  shouldControlledStorePricingBeVisible: boolean = false;
  
  rowCount;
  now;
  areDependenciesLoaded;
  window;

  myUser;
  unfilteredStores = []; //only used in dev mode for controlled stores diagram
  stores = [];
  testStoreNumbers = [102,169,700,701,702,703];
  store: any = { storeId: null };
  itemLists;
  privateLabels = [];
  nodeIds;

  //allItemsData:any = [];
  //storeItemsHashTable = {}; //no longer used
  allItemsHashTable = {};
  routerSubscription;
  gridFilterModel;
  gridFilterSubscription;
  tabModel = { activeTab: "AllItems", activeSubTab: "AllItems" };
  tabModelSubscription;
  hubsSubscription;
  nodeIdToViewSubscription; //used to scroll to a row from notification window message
  storeItemPriceChangesEnabledSubscription;
  meatAndProduceStoreItemPriceChangesEnabledSubscription;
  previewStoreItemPriceChangesEnabledSubscription;
  meatAndProducePreviewStoreItemPriceChangesEnabledSubscription;
  serverMaintenanceEnabledSubscription;
  signalRStoreItemUpdateSubscription; //will send items that have been updated through signalR
  //signalRDataExpirationDateSubscription; //is set by signalR after 'updatingStoreItemsFromMeatAndProducePreviewStoreItems'

  isFilterApplied: boolean;
  isShowingFooter: boolean = false;
  selectedRowData: any = {}; //used for footer data on cell focused
  selectedRowNode: any = {}; //set same time as selectedRowData but is the whole node
  currentFocusedCell: any = {rowIndex:0};
  currentSelectedNodeId;
  rowNodeToUpdate: any = {};
  updatedRowNode: any = {};
  rowDataBeforeEditing: any = {};
  isCellEditMode: boolean = false;
  isMasterInputMode: boolean = false;
  changeType: string = '';
  valueInRowChanged: boolean = false;
  isCellEditingInProgress: boolean = false;

  masterChangeModel: any = {};
  isMasterChangeModelValid: boolean = false;
  numberOfItemsInMasterChange = 0;
  masterChangeRowArray = [];
  changingMultis = false;
  mismatchedMultis = false;
  mismatchedPrices = false;
  mismatchedPercents = false;
  mismatchedBooks = false;
  mismatchedSrpCodes = false;
  changingMultisConfirmed = false;
  mismatchedMultisConfirmed = false;
  mismatchedPricesConfirmed = false;
  mismatchedPercentsConfirmed = false;
  mismatchedBooksConfirmed = false;
  mismatchedSrpCodesConfirmed = false;
  deleteItemMasterConfirmed = false;
  numberOfItemsToTriggerMasterChangeWarning = 100;
  masterInputSaveCounter = 0;

  dataExpirationDate;
  storeItemPriceChangesEnabledForStoreObject = { value: null };
  meatAndProduceStoreItemPriceChangesEnabledForStoreObject = { value: null };
  previewStoreItemPriceChangesEnabledForStoreObject = { value: null };
  meatAndProducePreviewStoreItemPriceChangesEnabledForStoreObject = { value: null };
  serverMaintenanceWindowOpenObject:any = { value: null };
  previousStoreItemPriceChangesEnabledForStoreObject = { value: null };
  previousMeatAndProduceStoreItemPriceChangesEnabledForStoreObject = { value: null };
  previousPreviewStoreItemPriceChangesEnabledForStoreObject = { value: null };
  previousMeatAndProducePreviewStoreItemPriceChangesEnabledForStoreObject = { value: null };
  previousServerMaintenanceWindowOpenObject:any = { value: null };

  permissions: any = {};

  isGridReadOnly:boolean = false; // TODO
  isItemReadOnly:boolean = false;
  previousAccessValue: boolean; //to determine whether to show readonly message
  showReadOnlyMessageToStorePricingViewer: boolean = true; //to show message only once

  isDataSynchronizing:boolean = false;
  isDataInfoBarVisible:boolean = false;
  dataInfoBarMessage = '';

  isKeepSrpInFooterChecked:boolean = false;
  isKeepPercentInFooterChecked:boolean = false;

  priceManagementHub;
  signalRInitialized: boolean = false;
  previousStore:any = {};
  myUserWorkingInStore: any = {};

  showHelpTimeout;
  workInSelectedStoreTimer;
  numberOfItemsToUpdateThreshold = 1000;

  itemListEditorFilterValue = '';

  isMobile: boolean = false;
  pbValue = 0;

  rowData = [];
  statusBar;
  columnDefs = [
    {
      headerName: 'Item',
      field: "itemCode",
      width: 80,
      cellClass: ["itemCode-col", "tac"],
      cellRenderer: this._gridService.itemCodeRenderer,
      pinned: "left",
      floatingFilterComponent: this._gridService.getCustomNumberFloatingFilter(),
      floatingFilter: true,
      filter: this._gridService.getCustomNumberFilter(),
      filterParams: {
        defaultOption: "startsWith",
        ignoreLeadingZeros: true,
      },
      get headerTooltip() { return this.headerName; }, 
    },
    {
      headerName: "UPC",
      field: "upcCode",
      width: 115,
      cellClass: ["upcCode-col"],
      floatingFilter: true,
      filter: "agTextColumnFilter",
      filterParams: {
        defaultOption: "startsWith"
      },
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Dept",
      field: "deptCode",
      width: 60,
      cellClass: ["deptCode-col"],
      hide: true,
      floatingFilter: true,
      filter: "agSetColumnFilter",
      //hide: true,
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Pack",
      field: "units",
      width: 60,
      cellClass: ["units-col", "tar"],
      hide: true,
      floatingFilter: true,
      filter: "agNumberColumnFilter",
      filterParams: {
        defaultOption: "equals",
        inRangeInclusive: true,
      },
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Pack/Size",
      field: "size",
      width: 115,
      cellClass: ["size-col"],
      floatingFilter: true,
      filter: "agTextColumnFilter",
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Description",
      field: "description",
      width: 300,
      cellClass: ["description-col"],
      floatingFilter: true,
      floatingFilterComponent: this._gridService.getCustomTextFloatingFilter(),
      filter: this._gridService.getCustomTextFilter(),
      filterParams: {
        defaultOption: "listOr"
      },
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Long Description",
      field: "longDescription",
      width: 300,
      cellClass: ["longDescription-col"],
      hide: true,
      floatingFilter: true,
      floatingFilterComponent: this._gridService.getCustomTextFloatingFilter(),
      filter: this._gridService.getCustomTextFilter(),
      filterParams: {
        defaultOption: "listOr"
      },
      comparator: this._gridService.caseInsensitiveTextComparator,
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Group",
      field: "groupNumber",
      width: 65,
      cellClass: ["groupNumber-col", "tar"],
      floatingFilter: true,
      floatingFilterComponent: this._gridService.getCustomGroupNumberFloatingFilter(),
      filter: this._gridService.getCustomGroupNumberFilter(),
      filterParams: {
        defaultOption: "listEqualsOrRange",
        inRangeInclusive: true,
      },
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Group Description",
      field: "groupDescription",
      width: 250,
      cellClass: ["groupDescription-col"],
      hide: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Movement",
      width: 100,
      headerClass: 'movement-column-group',
      openByDefault: false,
      children: [
        {
          headerName: '',
          field: 'movementGroup',
          width: 100,
          columnGroupShow: 'closed',
          filter: false,
          suppressHeaderMenuButton: true,
        },
        {
          headerName: "LY Mvt",
          field: "lastYearsMovementForStore",
          width: 70,
          cellClass: ["lastYearsMovementForStore-col", "tar"],
          floatingFilter: true,
          filter: "agNumberColumnFilter",
          columnGroupShow: 'open',
          get headerTooltip() { return this.headerName; }
        },
        {
          headerName: "YTD Mvt",
          field: "yearToDateMovementForStore",
          width: 80,
          cellClass: ["yearToDateMovementForStore-col", "tar"],
          floatingFilter: true,
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "equals",
            inRangeInclusive: true,
          },
          columnGroupShow: 'open',
          get headerTooltip() { return this.headerName; }
        },
        {
          headerName: "W LY Mvt",
          field: "lastYearsMovement",
          width: 85,
          cellClass: ["lastYearsMovement-col", "tar"],
          floatingFilter: true,
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "equals",
            inRangeInclusive: true,
          },
          columnGroupShow: 'open',
          get headerTooltip() { return this.headerName; }
        },
        {
          headerName: "W YTD Mvt",
          field: "yearToDateMovement",
          width: 95,
          cellClass: ["yearToDateMovement-col", "tar"],
          floatingFilter: true,
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "equals",
            inRangeInclusive: true,
          },
          columnGroupShow: 'open',
          get headerTooltip() { return this.headerName; }
        },
      ]
    },
    {
      headerName: "Classification",
      width: 115,
      headerClass: 'classification-column-group',
      openByDefault: false,
      children: [
        {
          headerName: '',
          field: 'classificationGroup',
          width: 115,
          columnGroupShow: 'closed',
          filter: false,
          suppressHeaderMenuButton: true,
        },
        {
          headerName: "Pvt Label",
          field: "privateLabel",
          width: 100,
          cellClass: ["privateLabel-col", "tac"],
          cellRenderer: this._gridService.privateLabelRenderer,
          floatingFilter: true,
          filter: "agSetColumnFilter",
          filterParams: {
            newRowsAction: 'keep',
            comparator: (a, b) => {
                let labelA = this.getLabelFromCode(a);
                let labelB = this.getLabelFromCode(b);
                return labelA.localeCompare(labelB);
            },
            valueFormatter: (params) => {
                return this.getLabelFromCode(params.value);
            }
          },
          valueFormatter: (params) => {
              return this.getLabelFromCode(params.value);
          },
          columnGroupShow: 'open',
          get headerTooltip() { return this.headerName; }
        },
        {
          headerName: "New",
          field: "newItem",
          width: 70,
          cellClass: ["newItem-col", "tac"],
          cellRenderer: this._gridService.newItemRenderer,
          floatingFilter: true,
          filter: "agSetColumnFilter",
          filterParams: {
            values: [true, false],
            newRowsAction: 'keep'
          },
          columnGroupShow: 'open',
          get headerTooltip() { return this.headerName; }
        },
        {
          headerName: "FTB",
          field: "firstTimeBuy",
          width: 70,
          cellClass: ["firstTimeBuy-col", "tac"],
          cellRenderer: this._gridService.firstTimeBuyRenderer,
          floatingFilter: true,
          filter: "agSetColumnFilter",
          filterParams: {
            values: [true, false],
            newRowsAction: 'keep'
          },
          columnGroupShow: 'open',
          get headerTooltip() { return this.headerName; }
        },
        {
          headerName: "WIC",
          field: "wicItem",
          hide: true,
          width: 70,
          cellClass: ["wicItem-col", "tac"],
          cellRenderer: this._gridService.wicItemRenderer,
          floatingFilter: true,
          filter: "agSetColumnFilter",
          filterParams: {
            values: [true, false],
            newRowsAction: 'keep'
          },
          columnGroupShow: 'open',
          get headerTooltip() { return this.headerName; }
        },
        {
          headerName: "Now",
          field: "nowItem",
          width: 70,
          cellClass: ["nowItem-col", "tac"],
          cellRenderer: this._gridService.nowItemRenderer,
          floatingFilter: true,
          filter: "agSetColumnFilter",
          filterParams: {
            values: [true, false],
            newRowsAction: 'keep'
          },
          columnGroupShow: 'open',
          get headerTooltip() { return this.headerName; }
        },
        {
          headerName: "FTB/Now",
          field: "firstTimeBuyOrNowItem",
          width: 80,
          cellClass: ["firstTimeBuyOrNowItem-col", "tac"],
          cellRenderer: this._gridService.checkRenderer,
          hide: true,
          floatingFilter: true,
          filter: "agSetColumnFilter",
          filterParams: {
            values: [true, false],
            newRowsAction: 'keep'
          },
          columnGroupShow: 'open',
          get headerTooltip() { return this.headerName; }
        },
        {
          headerName: "Rule Matched",
          field: "pricingRuleMatched",
          width: 110,
          cellClass: ["pricingRuleMatched-col", "tac"],
          hide: true,
          floatingFilter: true,
          filter: 'agTextColumnFilter',
          columnGroupShow: 'open',
          get headerTooltip() { return this.headerName; }
        },
      ]
    },
    // {
    //   headerName: "Unit Less D",
    //   field: "unitCostLessDeliveryFee",
    //   width: 95,
    //   cellClass: ["unitCostLessDeliveryFee-col", "tar"],
    //   cellRenderer: this._gridService.currencyRenderer,
    //   hide: true,
    //   filter: "agNumberColumnFilter",
    //   filterParams: {
    //     defaultOption: "equals",
    //     inRangeInclusive: true,
    //   },
    //   get headerTooltip() { return this.headerName; }
    // },
    {
      headerName: "Unit Cost",
      field: "deliveredUnitCost",
      width: 80,
      cellClass: ["deliveredUnitCost-col", "tar", "currencyFormat"],
      cellRenderer: this._gridService.currencyRenderer,
      floatingFilter: true,
      filter: "agNumberColumnFilter",
      filterParams: {
        defaultOption: "equals",
        inRangeInclusive: true,
      },
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Controlled Stores Pricing",
      field: "controlledStoresPricing",
      width: 60,
      minWidth: 40,
      cellClass: ["controlledStoresPricing-col", "tac"],
      cellRenderer: "ViewControlledStorePricingButtonComponent",
      suppressHeaderMenuButton: true,
      filter: false,
      sortable: false,
      hide: !this.shouldControlledStorePricingBeVisible,
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Selector",
      field: "masterChangeCheckbox",
      width: 75,
      minWidth: 55,
      cellClass: ["masterChangeCheckbox-col"],
      cellRenderer: this._gridService.masterCheckboxRenderer,
      lockVisible: true,
      hide: true,
      suppressHeaderMenuButton: true,
      filter: false,
      headerComponentParams: {
        masterInputCheckBox: true,
        masterInputHtml: '<div class="master-input master-input-checkbox"><input id="master-check-master-input" type="checkbox"/></div>'
      },
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Bk",
      field: "book",
      width: 50,
      cellClass: function (params) { if (params.data) return params.data.edited && params.data.book != params.data.oldBook ? ["book-col", "tac", "changed"] : ["book-col", "tac"]; },
      editable: false,
      lockVisible: true,
      cellRenderer: this.bookSrpCodeRenderer,
      cellEditor: this._gridService.getBookCellEditor(this),
      floatingFilter: true,
      filter: "agSetColumnFilter",
      filterParams: {
        values: ['1', '2', '3', '4', '5', '6', '7', 'C', 'D'],
        newRowsAction: 'keep'
      },
      headerComponentParams: {
        dynamicClassType: 'book',
        masterInputHtml: '<select id="book-master-input">' + 
          '<option value=""></option>' +
          '<option>1</option>' +
          '<option>2</option>' +
          '<option>3</option>' +
          '<option>4</option>' +
          '<option>5</option>' +
          '<option>6</option>' +
          '<option>7</option>' +
          '<option>C</option>' +
          '<option>D</option>' +
          '</select>'
      },
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Deal Code",
      field: "srpCode",
      width: 85,
      cellClass: function (params) { if (params.data) return params.data.edited && params.data.srpCode != params.data.oldSrpCode ? ["srpCode-col", "tac", "changed"] : ["srpCode-col", "tac"]; },
      editable: false,
      lockVisible: true,
      cellRenderer: this.bookSrpCodeRenderer,
      cellEditor: this._gridService.getSrpCodeCellEditor(this),
      floatingFilter: true,
      filter: "agSetColumnFilter",
      filterParams: {
        values: ['A', 'B', 'C', 'H', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
        newRowsAction: 'keep'
      },
      headerComponentParams: {
        dynamicClassType: 'book',
        masterInputHtml: '<select id="srpCode-master-input">' +
          '<option value=""></option>' +
          '<option>A</option>' +
          '<option>B</option>' +
          '<option>C</option>' +
          '<option>H</option>' +
          '<option>1</option>' +
          '<option>2</option>' +
          '<option>3</option>' +
          '<option>4</option>' +
          '<option>5</option>' +
          '<option>6</option>' +
          '<option>7</option>' +
          '<option>8</option>' +
          '<option>9</option>' +
          '</select>'
      },
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Multi",
      field: "multi",
      width: 60,
      cellClass: function (params) { if (params.data) return params.data.edited && params.data.multi != params.data.oldMulti ? ["n-col", "tar", "changed"] : ["n-col", "tar"]; },
      editable: false,
      lockVisible: true,
      cellRenderer: this.pricePercentRenderer,
      cellEditor: this._gridService.getMultiCellEditor(this),
      floatingFilter: true,
      filter: "agNumberColumnFilter",
      filterParams: {
        defaultOption: "equals",
        inRangeInclusive: true,
      },
      headerComponentParams: {
        dynamicClassType: 'price',
        masterInputHtml: '<input id="multi-master-input" type="text"/>'
      },
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Current Price",
      field: "currentPrice",
      width: 100,
      cellClass: ["tar", "currencyFormat"],
      //cellClass: function (params) { if (params.data) return params.data.edited && params.data.price != params.data.oldPrice ? ["price-col", "tar", "changed"] : ["price-col", "tar"]; },
      editable: false,
      cellRenderer: this._gridService.currencyRenderer,
      hide: true,
      floatingFilter: true,
      filter: "agNumberColumnFilter",
      filterParams: {
        defaultOption: "equals",
        inRangeInclusive: true,
      },
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "CHG",
      field: "chg",
      width: 60,
      cellClass: ["chg-col"],
      floatingFilter: true,
      filter: "agTextColumnFilter",
      hide: true,
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Price",
      field: "price",
      width: 75,
      cellClass: function (params) { if (params.data) return params.data.edited && params.data.price != params.data.oldPrice ? ["price-col", "tar", "changed", "currencyFormat"] : ["price-col", "tar", "currencyFormat"]; },
      editable: false,
      lockVisible: true,
      cellRenderer: this.pricePercentRenderer,
      cellEditor: this._gridService.getPriceCellEditor(this),
      floatingFilter: true,
      filter: "agNumberColumnFilter",
      filterParams: {
        defaultOption: "equals",
        inRangeInclusive: true,
      },
      headerComponentParams: {
        dynamicClassType: 'price',
        masterInputHtml: '<input id="price-master-input" type="text"/>'
      },
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "%",
      field: "percent",
      width: 45,
      cellClass: function (params) { if (params.data) return params.data.edited && params.data.percent != params.data.oldPercent ? ["percent-col", "tar", "changed"] : ["percent-col", "tar"]; },
      editable: false,
      lockVisible: true,
      cellRenderer: this.pricePercentRenderer,
      cellEditor: this._gridService.getPercentCellEditor(this),
      floatingFilter: true,
      filter: "agNumberColumnFilter",
      filterParams: {
        defaultOption: "equals",
        inRangeInclusive: true,
      },
      headerComponentParams: {
        dynamicClassType: 'percent',
        masterInputHtml: '<input id="percent-master-input" type="text"/>'
      },
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Keep$",
      field: "keepSrp",
      width: 65,
      cellClass: function (params) { if (params.data) return params.data.edited && params.data.keepSrp != params.data.oldKeepSrp ? ["keepSrp-col", "tac", "changed"] : ["keepSrp-col", "tac"]; },
      cellRenderer: this.pricePercentRenderer,
      cellEditor: this._gridService.getKeepSrpCellEditor(this),
      lockVisible: true,
      floatingFilter: true,
      filter: "agSetColumnFilter",
      filterParams: {
        values: [true, false],
        newRowsAction: 'keep'
      },
      headerComponentParams: {
        dynamicClassType: 'price',
        masterInputHtml: '<select id="keepSrp-master-input">' +
          '<option value=""></option>' +
          '<option value=true>True</option>' +
          '<option value=false>False</option>' +
          '</select>'
      },
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Keep%",
      field: "keepPercent",
      width: 65,
      cellClass: function (params) { if (params.data) return params.data.edited && params.data.keepPercent != params.data.oldKeepPercent ? ["keepPercent-col", "tac", "changed"] : ["keepPercent-col", "tac"]; },
      cellRenderer: this.pricePercentRenderer,
      cellEditor: this._gridService.getKeepPercentCellEditor(this),
      lockVisible: true,
      floatingFilter: true,
      filter: "agSetColumnFilter",
      filterParams: {
        values: [true, false],
        newRowsAction: 'keep'
      },
      headerComponentParams: {
        dynamicClassType: 'percent',
        masterInputHtml: '<select id="keepPercent-master-input">' +
          '<option value=""></option>' +
          '<option value=true>True</option>' +
          '<option value=false>False</option>' +
          '</select>'
      },
      get headerTooltip() { return this.headerName; }
    },
    // {
    //   headerName: "S$K%",
    //   field: "setPriceKeepPercent",
    //   width: 65,
    //   cellClass: ["setPriceKeepPercent-col", "tac"],
    //   cellRenderer: this.pricePercentRenderer,
    //   cellEditor: this._gridService.getSetPriceKeepPercentCellEditor(this),
    //   lockVisible: true,
    //   floatingFilter: true,
    //   filter: "agSetColumnFilter",
    //   filterParams: {
    //     values: [true, false],
    //     newRowsAction: 'keep'
    //   },
    //   headerComponentParams: {
    //     dynamicClassType: 'price',
    //     masterInputHtml: '<select id="setPriceKeepPercent-master-input">' +
    //       '<option value=""></option>' +
    //       '<option value=true>True</option>' +
    //       '<option value=false>False</option>' +
    //       '</select>'
    //   },
    //   get headerTooltip() { return 'Set $ Keep %'; }
    // },
    {
      headerName: "Deal",
      field: "deal",
      width: 75,
      cellClass: ["deal-col", "tar", "currencyFormat"],
      cellRenderer: this._gridService.currencyRenderer,
      floatingFilter: true,
      filter: "agNumberColumnFilter",
      filterParams: {
        defaultOption: "equals",
        inRangeInclusive: true,
      },
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Deal End",
      field: "dealDate",
      width: 110,
      cellClass: ["dealDate-col", "dateFormat"],
      cellRenderer: this._gridService.dateRenderer,
      floatingFilter: true,
      filter: 'agDateColumnFilter',
      filterParams: {
        comparator: this._gridService.dateComparator,
        inRangeInclusive: true
      },
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Old Price",
      width: 100,
      headerClass: 'old-price-column-group',
      openByDefault: false,
      children: [
        {
          headerName: '',
          field: 'oldPriceGroup',
          width:100,
          columnGroupShow: 'closed',
          filter: false,
          suppressHeaderMenuButton: true,
          get headerTooltip() { return this.headerName; }
        },
        {
          headerName: "O Bk",
          field: "oldBook",
          width: 55,
          cellClass: ["oldBook-col", "tac", "old-values-column"],
          floatingFilter: true,
          filter: "agTextColumnFilter",
          hide: false,
          columnGroupShow: 'open',
          get headerTooltip() { return this.headerName; }
        },
        {
          headerName: "O Dl Cd",
          field: "oldSrpCode",
          width: 70,
          cellClass: ["oldSrpCode-col", "tac", "old-values-column"],
          floatingFilter: true,
          filter: "agTextColumnFilter",
          hide: false,
          columnGroupShow: 'open',
          get headerTooltip() { return this.headerName; }
        },
        {
          headerName: "O Mult",
          field: "oldMulti",
          width: 80,
          cellClass: ["oldMulti-col", "tar", "old-values-column"],
          floatingFilter: true,
          filter: "agNumberColumnFilter",
          hide: false,
          columnGroupShow: 'open',
          filterParams: {
            defaultOption: "equals",
            inRangeInclusive: true,
          },
          get headerTooltip() { return this.headerName; }
        },
        {
          headerName: "O Price",
          field: "oldPrice",
          width: 70,
          cellClass: ["oldPrice-col", "tar", "old-values-column", "currencyFormat"],
          hide: false,
          cellRenderer: this._gridService.currencyRenderer,
          floatingFilter: true,
          filter: "agNumberColumnFilter",
          columnGroupShow: 'open',
          filterParams: {
            defaultOption: "equals",
            inRangeInclusive: true,
          },
          get headerTooltip() { return this.headerName; }
        },
        {
          headerName: "O Kp$",
          field: "oldKeepSrp",
          width: 65,
          cellClass: ["oldKeepSrp-col", "tac", "old-values-column"],
          hide: false,
          cellRenderer: this._gridService.checkRenderer,
          floatingFilter: true,
          filter: "agSetColumnFilter",
          filterParams: {
            values: [true, false],
            newRowsAction: 'keep'
          },
          columnGroupShow: 'open',
          get headerTooltip() { return this.headerName; }
        },
        {
          headerName: "O %",
          field: "oldPercent",
          width: 50,
          cellClass: ["oldPercent-col", "tar", "old-values-column"],
          hide: false,
          floatingFilter: true,
          filter: "agNumberColumnFilter",
          columnGroupShow: 'open',
          filterParams: {
            defaultOption: "equals",
            inRangeInclusive: true,
          },
          get headerTooltip() { return this.headerName; }
        },
        {
          headerName: "O Kp%",
          field: "oldKeepPercent",
          width: 65,
          cellClass: ["oldKeepPercent-col", "tac", "old-values-column"],
          hide: false,
          cellRenderer: this._gridService.checkRenderer,
          floatingFilter: true,
          filter: "agSetColumnFilter",
          filterParams: {
            values: [true, false],
            newRowsAction: 'keep'
          },
          columnGroupShow: 'open',
          get headerTooltip() { return this.headerName; }
        },
      ]
    },
    // {
    //   headerName: "Case Less D",
    //   field: "caseCostLessDeliveryFee",
    //   width: 100,
    //   cellClass: ["caseCostLessDeliveryFee-col", "tar", "currencyFormat"],
    //   hide: true,
    //   cellRenderer: this._gridService.currencyRenderer,
    //   filter: "agNumberColumnFilter",
    //   filterParams: {
    //     defaultOption: "equals",
    //     inRangeInclusive: true,
    //   },
    //   get headerTooltip() { return this.headerName; }
    // },
    {
      headerName: "Case",
      field: "deliveredCaseCost",
      width: 85,
      cellClass: ["deliveredCaseCost-col", "tar", "currencyFormat"],
      hide: false,
      cellRenderer: this._gridService.currencyRenderer,
      floatingFilter: true,
      filter: "agNumberColumnFilter",
      filterParams: {
        defaultOption: "equals",
        inRangeInclusive: true,
      },
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Base",
      field: "baseCost",
      width: 85,
      cellClass: ["baseCost-col", "tar", "currencyFormat"],
      hide: true,
      cellRenderer: this._gridService.currencyRenderer,
      floatingFilter: true,
      filter: "agNumberColumnFilter",
      filterParams: {
        defaultOption: "equals",
        inRangeInclusive: true,
      },
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Old Cost",
      width: 100,
      headerClass: 'old-cost-column-group',
      openByDefault: false,
      children: [
        {
          headerName: '',
          field: 'oldCostGroup',
          width: 100,
          columnGroupShow: 'closed',
          filter: false,
          suppressHeaderMenuButton: true,
        },
        {
          headerName: "O Case",
          field: "oldDeliveredCaseCost",
          width: 90,
          cellClass: ["oldDeliveredCaseCost-col", "tar", "old-values-column", "currencyFormat"],
          hide: false,
          cellRenderer: this._gridService.currencyRenderer,
          floatingFilter: true,
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "equals",
            inRangeInclusive: true,
          },
          columnGroupShow: 'open',
          get headerTooltip() { return this.headerName; }
        },
        {
          headerName: "O Base",
          field: "oldBaseCost",
          width: 80,
          cellClass: ["oldBaseCost-col", "tar", "old-values-column", "currencyFormat"],
          hide: false,
          cellRenderer: this._gridService.currencyRenderer,
          floatingFilter: true,
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "equals",
            inRangeInclusive: true,
          },
          columnGroupShow: 'open',
          get headerTooltip() { return this.headerName; }
        },
        {
          headerName: "O Unit",
          field: "oldDeliveredUnitCost",
          width: 70,
          cellClass: ["oldDeliveredUnitCost-col", "tar", "old-values-column", "currencyFormat"],
          hide: false,
          cellRenderer: this._gridService.currencyRenderer,
          floatingFilter: true,
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "equals",
            inRangeInclusive: true,
          },
          columnGroupShow: 'open',
          get headerTooltip() { return this.headerName; }
        },
      ]
    },
    {
      headerName: "Other",
      field: "offPack",
      width: 125,
      cellClass: ["offPack-col", "tac"],
      hide: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      filterParams: {
        defaultOption: "startsWith",
      },
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Edited",
      field: "edited",
      width: 70,
      cellClass: ["edited-col", "tac"],
      cellRenderer: this._gridService.checkRenderer,
      hide: true,
      floatingFilter: true,
      filter: "agSetColumnFilter",
      filterParams: {
        values: [true, false],
        newRowsAction: 'keep'
      },
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Item Lists",
      field: "itemLists",
      width: 300,
      cellClass: ["itemLists-col"],
      cellRenderer: this.itemListRenderer,
      hide: true, 
      floatingFilter: true,
      filter: "agSetColumnFilter",
      valueFormatter: function (params) {
        if (Array.isArray(params.value)){
          return params.value.map(function (list) {
              return list.fullName;
            }).join(', ');
        }
        else{
          return params.value? params.value.fullName : '';
        }
      },
      keyCreator: function (params) {
        if (Array.isArray(params.value)){
          return params.value.map(function (list) {
            return list.fullName;
          });
        }
        else{
          return params.value? params.value.fullName : '';
        }
      },
      //floatingFilterComponent: this._gridService.getCustomItemListFloatingFilter(this),
      //filter: this._gridService.getCustomItemListFilter(this),
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Is Stock",
      field: "isStock",
      width: 70,
      cellClass: ["isStock-col"],
      hide: true,
      floatingFilter: true,
      filter: "agSetColumnFilter",
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Is Preview",
      field: "isPreview",
      width: 85,
      cellClass: ["isPreview-col"],
      hide: true,
      floatingFilter: true,
      filter: "agSetColumnFilter",
      get headerTooltip() { return this.headerName; }
    },
  ];

  excelStyles = [
    {
      id: 'currencyFormat',
      numberFormat: {
          format: '###,##0.00',
      },
    },
    {
      id: 'dateFormat',
      dataType: 'DateTime',
      numberFormat: {
          format: 'm/d/yyyy'
      }
    }
  ];
  
  constructor(
    private _authenticationService: AuthenticationService,
    private _priceManagementService: PriceManagementService,
    private _rpmsMainframeOperationsService: RpmsMainframeOperationsService,
    private _priceManagementFilterService: PriceManagementFilterService,
    private _storeService: StoreService,
    private _itemListService: ItemListService,
    private _privateLabelsService: PrivateLabelsService,
    private _scheduleService: ScheduleService,
    private _accessService: AccessService,
    private _gridService: GridService,
    private _priceManagementDataService: PriceManagementDataService,
    private _dialog: MatDialog,
    private _messageService: MessageService,
    private _domSanitizer: DomSanitizer,
    private _spinnerService: SpinnerService,
    private _router: Router,
    private _notificationWindowService: NotificationWindowService,
    private _permissionsService: PermissionsService,
    private _helpService: HelpService,
    private _signalRService: SignalRService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _reportService: ReportService,
    ) {
    this._spinnerService.handleProgressSpinnerVisibility('show', 'Loading Page...')
    this.permissions = this._permissionsService.getPermissions();

    this.isMobile = navigator.userAgent.indexOf( "Mobile" ) !== -1 || 
                    navigator.userAgent.indexOf( "iPhone" ) !== -1 || 
                    navigator.userAgent.indexOf( "Android" ) !== -1 || 
                    navigator.userAgent.indexOf( "Windows Phone" ) !== -1;

    this.gridOptions = <GridOptions> {
			columnDefs: this.columnDefs,
			rowData: this.rowData,
      components: { 
        agColumnHeader: CustomHeaderComponent,
        ViewControlledStorePricingButtonComponent: ViewControlledStorePricingButtonComponent,
      },
      rowHeight: 35,    
			headerHeight: 25,
			groupHeaderHeight: 25,
			floatingFiltersHeight: 35,
			suppressNoRowsOverlay: true,
      animateRows: false,
			rowSelection: 'single',
      enableRangeSelection: true,
      onSelectionChanged: this.doOnSelectionChanged,
      singleClickEdit: this.isMobile,
      suppressMovableColumns: this.isMobile,
      suppressAnimationFrame: true,
      onCellFocused: this.doOnCellFocused,
			editType: "fullRow",
			onRowEditingStarted: this.doOnRowEditingStarted,
      onRowEditingStopped: this.doOnRowEditingStopped,
      onColumnVisible: this.onColumnVisible,
      onColumnPinned: this.onColumnChange,
      onColumnResized: this.onColumnChange,
      onColumnMoved: this.onColumnChange,
      onColumnRowGroupChanged: this.onColumnChange,
      onColumnGroupOpened: this.onColumnChange,
      onDragStopped: this.onColumnChange,
      //enterMovesDownAfterEdit: true,
      //enableBrowserTooltips: false,
			//this styles removed item list rows and deleted item rows
			rowClassRules: {
				'removed-item-row': function (params) {
          if (params.data != null)
            return false; // TODO
						//return this.tabModel.activeTab == 'ItemList' && params.data.removedItem;
				},
				'deleted-item-row': function (params) {
					if (params.data != null)
						return params.data.book === 'D';
				},
			},
			defaultColDef: {
        sortable: true,
        resizable: true,
        enableValue: true,
        enableRowGroup: false,
        enablePivot: false,
        enableCellChangeFlash: true,
				headerComponentParams: {
          masterInputHtml: '',
        },
        suppressKeyboardEvent: this.onSuppressKeyboardEvent,
        suppressSpanHeaderHeight: true,
      },
      defaultCsvExportParams: {
        skipPinnedBottom: true,
        allColumns: false,
        processCellCallback: function(params) {
          let cellClass = params.column.getColDef().cellClass;
          
          if (Array.isArray(cellClass) && cellClass.indexOf('currencyFormat') != -1 && params.value && !isNaN(params.value)) {
            return Number(params.value).toFixed(2);
          }
          else if (Array.isArray(cellClass) && cellClass.indexOf('dateFormat') != -1 && params.value && moment(params.value, moment.ISO_8601).isValid()) {
            return moment(params.value).format('M/D/YYYY').toString();
          }
          else if (Array.isArray(cellClass) && cellClass.indexOf('itemLists-col') != -1 && params.value) {
            if(params.value && params.value.length > 0 && params.value[0].fullName != 'Loading...') {
              let itemLists = params.value.map(obj => obj.fullName).join(', ');
              return itemLists;
            }
            return '';
          }
          return params.value;
        }
      },
      defaultExcelExportParams: {
        skipPinnedBottom: true,
        allColumns: false,
        processCellCallback: function(params) {
          let cellClass = params.column.getColDef().cellClass;
          
          if (Array.isArray(cellClass) && cellClass.indexOf('currencyFormat') != -1 && params.value && !isNaN(params.value)) {
            return Number(params.value).toFixed(2);
          }
          else if (Array.isArray(cellClass) && cellClass.indexOf('itemLists-col') != -1 && params.value) {
            if(params.value && params.value.length > 0 && params.value[0].fullName != 'Loading...') {
              let itemLists = params.value.map(obj => obj.fullName).join(', ');
              return itemLists;
            }
            return '';
          }

          return params.value;
        }
      },
      sideBar: {
        toolPanels: [
          {
              id: 'columns',
              labelDefault: 'Columns',
              labelKey: 'columns',
              iconKey: 'columns',
              toolPanel: 'agColumnsToolPanel',
              toolPanelParams: {
                suppressRowGroups: true,
                suppressValues: true,
                suppressPivotMode: true,
              }    
          },
          {
              id: 'filters',
              labelDefault: 'Filters',
              labelKey: 'filters',
              iconKey: 'filter',
              toolPanel: 'agFiltersToolPanel',
          },
        ],
        defaultToolPanel: ''
      },

    }
    this.statusBar = {
      statusPanels: [
        // {
        //   statusPanel: "agTotalRowCountComponent",
        //   align: "left"
        // },
        //{ statusPanel: "agFilteredRowCountComponent" },
        //{ statusPanel: "agSelectedRowCountComponent" },
        { statusPanel: "agAggregationComponent" }
      ]
    };
    this.context = { componentParent: this }
    // this.frameworkComponents = { 
    //   agColumnHeader: CustomHeaderComponent,
    //   ViewControlledStorePricingButtonComponent: ViewControlledStorePricingButtonComponent,
    // };
  }

  getFieldName(params?){
    //console.log(params);
    var name: string = "test";
    return name;
  }

  onSuppressKeyboardEvent(params) {
    var key = params.event.key;
    return key === ' ';
  }

  getLabelFromCode(code) {
    let labelObj = this.privateLabels.find(item => item.letterCode === code);
    if (code === ' ') return '(None)';
    return labelObj ? labelObj.label : code;
  }

  //!///////////////////////////////
  // Angular lifecycle hooks
  //////////////////////////////////

  ngOnInit() {
		if (!('indexedDB' in window)) {
			this._messageService.alert('This browser does not support IndexedDB. Please use Chrome as your browser.');
			return;
    }
    //this.getActiveStores();

    this.myUser = this._authenticationService.getMyUser();
  }

  ngAfterViewInit(){
    this._spinnerService.handleProgressSpinnerVisibility('show', 'Loading Subscriptions...')
    // subscribe to gridFilterModel changes
    this.gridFilterSubscription = this._priceManagementFilterService.gridFilterModel$
      .subscribe(
        (data) => {
          //?? Had to clone data object or it was undefined in the setTimeout function
          let filterModel = this.cloneObject(data);
          if (this.agGrid.api) {
            let self = this;
            setTimeout(function() {
              self.agGrid.api.setFilterModel(null);
              self.agGrid.api.setFilterModel(filterModel); 
              self.agGrid.api.onFilterChanged();
              self._changeDetectorRef.detectChanges();
            }, 1);
          }
        }
      );
    // subscribe to tabModel changes
    this.tabModelSubscription = this._priceManagementFilterService.tabModel$
      .subscribe(
        (data) => {
          this.applyStylingFromTabModel(data);
          this.tabModel = data;
          if (this.isGridDataLoaded) this.determineAccess();
          //If the tabs don't match the selected item filters tabs, clear the selected item filter
          //This is because the saved item filters have tabs associated with the save. If the tabs are different, then it's no longer the saved item filter you are looking at since the tabs (and items) have changed.
          if (this.itemFilterComponent.itemFilter && this.tabModel.activeTab != this.itemFilterComponent.itemFilter.priceManagementTab && this.tabModel.activeSubTab != this.itemFilterComponent.itemFilter.priceManagementSubTab) {
            this.itemFilterComponent.clearItemFilter();
          }
        }
      );
    // subscribe to signalR hubs loading
    this.hubsSubscription = this._notificationWindowService.areHubsLoaded$
      .subscribe(
        (data) => {
          //if (data) this.initSignalR(); //TODO 
        }
      );
    this.nodeIdToViewSubscription = this._notificationWindowService.nodeIdToView$
      .subscribe(
        (data) => {
          this.selectRowNodeFromNodeId(data);
        }
      );

    this.storeItemPriceChangesEnabledSubscription = this._priceManagementDataService.storeItemPriceChangesEnabled$
    .subscribe(
      (data) => {
        this.storeItemPriceChangesEnabledForStoreObject = data;
        //this.setAccessToModifyGroceryStoreItems();
        this.determineAccess();
      }
    );

    this.meatAndProduceStoreItemPriceChangesEnabledSubscription = this._priceManagementDataService.meatAndProduceStoreItemPriceChangesEnabled$
    .subscribe(
      (data) => {
        this.meatAndProduceStoreItemPriceChangesEnabledForStoreObject = data;
        //this.setAccessToModifyMeatAndProduceStoreItems();
        this.determineAccess();
      }
    );

    this.previewStoreItemPriceChangesEnabledSubscription = this._priceManagementDataService.previewStoreItemPriceChangesEnabled$
    .subscribe(
      (data) => {
        this.previewStoreItemPriceChangesEnabledForStoreObject = data;
        //this.setAccessToModifyGroceryPreviewItems();      
        this.determineAccess();
      }
    );

    this.meatAndProducePreviewStoreItemPriceChangesEnabledSubscription = this._priceManagementDataService.meatAndProducePreviewStoreItemPriceChangesEnabled$
    .subscribe(
      (data) => {
        this.meatAndProducePreviewStoreItemPriceChangesEnabledForStoreObject = data;
        //this.setAccessToModifyMeatAndProducePreviewItems();
        this.determineAccess();
      }
    );

    this.serverMaintenanceEnabledSubscription = this._priceManagementDataService.serverMaintenanceEnabled$
    .subscribe(
      (data) => {
        this.serverMaintenanceWindowOpenObject = data;
        this.setAccessBasedOnServerMaintenanceWindow();
      }
    );

    this.signalRStoreItemUpdateSubscription = this._priceManagementDataService.signalRStoreItemUpdate$
    .subscribe(
      (data) => {
        //all signalR changes to store items and preview items go through here
        //if this is a store item, update the hash table
        //if (!data.isPreview) this.updateStoreItemsHashTable(data);
        //This will update the hash table with only the new updated properties from data and return the updated item
        //let correspondingItemFromHashTable = this.updateAllItemsHashTableAndReturnCorrespondingItem(data);
        //update row's data. formatSingleRowData accepts both store items and preview store items
        //THIS DATA IS NOT THE SAME SHAPE AS DATA RECEIVED WHEN LOADING ITEM FROM SERVER
        //I NEED TO UPDATE THAT DATA (FROM HASH TABLE?) ONLY WITH NEW PROPERTIES THAT EXIST AND THEN USE THAT DATA TO SEND TO FORMATTER
        //this.updateRowNodeWithNewData(null, this.formatSingleRowData(data));


        //ALL OF THE LOGIC ABOVE IS NOW HANDLED IN THE FOLLOWING FUNCTION (I'm leaving the above comments for now)
        //args are (rowNode, newData). When passing null as the rowNode, the rowNode will be determined by the itemCode and whether it's preview or not.
        //In other words, it uses the rowId which is "P" or "S" followed by the itemCode
        this.updateRowNodeWithNewData(null, data);
      }
    );

    // this.signalRDataExpirationDateSubscription = this._priceManagementDataService.signalRDataExpirationDate$
    // .subscribe(
    //   (data) => {
    //     // commented out 6-28-2019 when expiration date value now comes from /api/PriceManagement/GetCachedStoreDataExpiration
    //     //this.dataExpirationDate = data;
    //   }
    // );


    // subscribe to route changes
    // https://stackoverflow.com/questions/34444822/angular2-watch-for-route-change
    this.routerSubscription = this._router.events.subscribe((event:any) => {
      //console.log(event);
      if (event instanceof NavigationStart) {
        //console.log(event.url);
        this.exitStore();
        // save metadata before leaving page
        // don't save on logout. that is handled on the header component
        if (event.url !== '/login') {
          this.saveColumnMetadata();
          var colState = this.agGrid.api.getColumnState();
          var colGroupState = this.agGrid.api.getColumnGroupState();
          var metadata = {
            columnState: colState,
            columnGroupState: colGroupState,
          }
          this._priceManagementService.updatePricingGridMetadata({ pricingGridMetadata: JSON.stringify(metadata) })
              .subscribe(
                (data) => {
                },
                (response) => {
                  this._messageService.onFailure('Failed to save grid metadata.', response);
                }
              )
        }
      }
    });

    this.doAfterViewInit();

    // do on page load
    setTimeout(() => { 
      this.onWindowResize();
      this.sizeGrid();
    },100); 

  }

  doAfterViewInit() {
    this.saveColumnMetadata();
    this.getActiveItemFilters();
  }

  ngOnDestroy() {
    this.rowData = [];
    this.itemLists = [];
    this.allItemsHashTable = {};  
    this.gridOptions = {};
    this.context = {};
    window.onresize = null;
    clearTimeout(this.workInSelectedStoreTimer);
    this._spinnerService.setProgressSpinnerMessage('Processing Request...');
    this.gridFilterSubscription.unsubscribe();
    this.tabModelSubscription.unsubscribe();
    this.hubsSubscription.unsubscribe();
    this.nodeIdToViewSubscription.unsubscribe();
    this.storeItemPriceChangesEnabledSubscription.unsubscribe();
    this.meatAndProduceStoreItemPriceChangesEnabledSubscription.unsubscribe();
    this.previewStoreItemPriceChangesEnabledSubscription.unsubscribe();
    this.meatAndProducePreviewStoreItemPriceChangesEnabledSubscription.unsubscribe();
    this.serverMaintenanceEnabledSubscription.unsubscribe();
    this.signalRStoreItemUpdateSubscription.unsubscribe();
    //this.signalRDataExpirationDateSubscription.unsubscribe();
    this.routerSubscription.unsubscribe();
    this._signalRService.exitStore(
      (data) => {
        if (this.config.logPmLoading) console.log('SignalR exit store was successful');
        this.getReachableConnectedUsers();
      },
      (error) => {
        console.log('SignalR send error: ' + error);
      });
  }

  applyStylingFromTabModel(tabModel){
    //this makes the grid and controls match the tabs color
    var wrapperElement = <HTMLElement>document.querySelector('#pm-grid-and-controls');
    if (wrapperElement) wrapperElement.className = tabModel.activeTab;
  }


  //!///////////////////////////////
  // Get pre-grid dependencies
  //////////////////////////////////

  getActiveItemFilters() {
    this._spinnerService.handleProgressSpinnerVisibility('show', 'Loading Item Filters...');
    this.pbValue = 10;
    //need to detect changes or it will throw error: ExpressionChangedAfterItHasBeenCheckedError
    this._changeDetectorRef.detectChanges();
    //item filters are loaded on the item filter component
    //this is the first step to loading dependencies for this page
    //when it finishes it will call the function below to continue loading dependencies on this component
    this.itemFilterComponent.getActiveSharedItemFilters();
  }

  //Item filters are loaded on the item filter component
  //When they have finished loading, it will call this
  //I'm doing this so that the spinner doesn't disappear when loading these
  onItemFiltersLoaded(event) {
    this.getActiveStores();
  }

  getActiveStores(){
    this.pbValue = 20;
    this._changeDetectorRef.detectChanges();
    this._spinnerService.setProgressSpinnerMessage('Getting stores...');
    this._storeService.getActiveStores()
      .subscribe(
        (data:any) => {
          this.unfilteredStores = data; //only used for controlled stores diagram to make sure all stores get sent when in dev mode
          if (environment.isDevMode){
            this.stores = data.filter(s => s.storeNumber >= 700 && s.storeNumber < 720); //don't include 720
          }
          else{
            this.stores = data;
          }
          //this.getActiveSharedItemLists();
          this.getPrivateLabels();
        },
        (response) => {
          this._messageService.onFailure('Failed to get stores.', response);
        }
      )
  }

  getActiveSharedItemLists(callback){
    // this.pbValue = 30;
    // this._changeDetectorRef.detectChanges();
    this._spinnerService.handleProgressSpinnerVisibility('show', 'Getting item lists...');
    this._itemListService.getActiveSharedItemListsLite()
      .subscribe(
        (data) => {
          this.itemLists = data;
          //this.getPrivateLabels();
          if (typeof callback === "function") callback();
        },
        (response) => {
          this._messageService.onFailure('Failed to get item lists.', response);
        }
      )
  }

  getPrivateLabels(){
    this.pbValue = 50;
    this._changeDetectorRef.detectChanges();
    this._spinnerService.setProgressSpinnerMessage('Getting private labels...');
    this._privateLabelsService.getActivePrivateLabels()
      .subscribe(
        (data:any) => {
          data.sort((a, b) => {
            if (a.label < b.label) {
                return -1;
            }
            if (a.label > b.label) {
                return 1;
            }
            return 0;
          });
          this.privateLabels = data;
          localStorage.setItem('rpmsPrivateLabels', JSON.stringify(data));
          this.getCachedStoreDataExpiration();
        },
        (response) => {
          this._messageService.onFailure('Failed to get private labels.', response);
        }
      )
  }

  getCachedStoreDataExpiration(){
    this.pbValue = 70;
    this._changeDetectorRef.detectChanges();
    this._spinnerService.setProgressSpinnerMessage('Getting expiration date...');
    this._priceManagementService.getCachedStoreDataExpiration()
      .subscribe(
        (data:any) => {
          this.dataExpirationDate = new Date(data.expirationDate);
          this.getServerMaintenanceWindow();
        },
        (response) => {
          this._messageService.onFailure('Failed to get schedule.', response);
        }
      )
  }

  getServerMaintenanceWindow(){
    this.pbValue = 90;
    this._changeDetectorRef.detectChanges();
    this._spinnerService.setProgressSpinnerMessage('Getting schedule maintenance window...');
    this._scheduleService.isScheduleWindowOpen("ServerMaintenanceWindow")
      .subscribe(
        (data:any) => {
          this.serverMaintenanceWindowOpenObject = data;
          this.getPricingGridMetadata();
        },
        (response) => {
          this._messageService.onFailure('Failed to get server maintenance window.', response);
        }
      )
  }

  getPricingGridMetadata(){
    this.pbValue = 95;
    this._changeDetectorRef.detectChanges();
    this._priceManagementService.getPricingGridMetadata()
      .subscribe(
        (data:any) => {
          if (data.pricingGridMetadata != null) {
            //if the item lists column is showing, load the item lists
            //must use arrow function to preserve 'this'
            let colState = this.cloneObject(data.pricingGridMetadata.columnState);
            let itemListCol = colState.filter(e => e.colId === 'itemLists')[0]; //returns array so take [0]
            if (itemListCol && itemListCol.hide === false) {
              this.getActiveSharedItemLists(() => {
                //callback - put list names in column data
                this.agGrid.api.forEachNode((rowNode) => {
                  rowNode.setDataValue('itemLists', this.getItemListsFromItemCode(rowNode.data.itemCode));
                });
              })
            }
            this.setColumnAndColumnGroupState(data.pricingGridMetadata, () => {
              this.saveColumnMetadata();
              this.startOnlineMode();
            });
          }
          else {
            this.startOnlineMode();
          }
        },
        (response) => {
          this._messageService.onFailure('Failed to get grid metadata.', response);
        }
      )
  }

  setColumnAndColumnGroupState(metadata, callback?) {
    this.pbValue = 100;
    this._changeDetectorRef.detectChanges();
    //this allows for old version - different data shape
    if (metadata.hasOwnProperty('columnState')) {
      this.agGrid.api.applyColumnState({state: metadata.columnState, applyOrder: true,});
      this.agGrid.api.setColumnGroupState(metadata.columnGroupState);
    }
    else {
      this.agGrid.api.applyColumnState({state: metadata, applyOrder: true,});
    }
    //always have selector column hidden after page load
    this.agGrid.api.setColumnsVisible(["masterChangeCheckbox"], false);
    if (typeof callback == 'function') callback();
  }

  startOnlineMode(){
    this.pbValue = 0;
    this.areDependenciesLoaded = true;
    this._changeDetectorRef.detectChanges();
    this.sizeGrid();
    //get filter to be applied
    var nameOfItemFilterToApplyAfterLoadingItems = this._priceManagementFilterService.getNameOfItemFilterToApplyAfterLoadingItems();
    if (nameOfItemFilterToApplyAfterLoadingItems !== '') this._messageService.showToastTopRight('Item Filter "' + nameOfItemFilterToApplyAfterLoadingItems + '" will be applied after items are loaded.');
    //if a store has already been previously selected then select it and load items
    var selectedStore = this._storeService.getSelectedStore();
    // if no store has been selected and there is only one store to choose from
    if (this.isEmpty(selectedStore) && this.stores.length === 1) selectedStore = this.stores[0];
    if (!this.isEmpty(selectedStore)) {
      this.store = selectedStore;
      this.onStoreSelected();
    }
  }
  
  //!///////////////////////////////
  // Store selection
  //////////////////////////////////

  onStoreSelected(){
    this.hideAllEditors();
    this.pbValue = 10;
    this._storeService.setSelectedStore(this.store);
    this.isStoreSelected = true;
    this.isGridDataLoaded = false;
    this.rowData = [];
    if (!this.isEmpty(this.previousStore)) { //empty
      this.exitStoreAndWorkInSelectedStore();
    } else {
      this.workInSelectedStore();
    }
  }

  exitStoreAndWorkInSelectedStore() {
    var self = this;
    if (!this.isEmpty(this.previousStore)) { //empty
      self._signalRService.exitStore(
        function (data) {
          self.workInSelectedStore();
        },
        function (error) {
          console.log('SignalR send error: ' + error);
        });
    }
  }

  workInSelectedStore() {
    var self = this;
    if (this.config.logPmLoading) console.log('Work in selected store.');
    this.previousStore = this.store;

    // SignalR
    if (self._signalRService.signalRInitialized) { //set on .start().done()
      self._signalRService.workInStore(self.store.storeId,
        function (data) {
          self.getReachableConnectedUsers();
          //synchronize chat messages before adding any new messages to chat
          self.synchronizeMessages();
          //items wil be loaded after determining if changes are enabled
          self.areStoreItemPriceChangesEnabledForStore();
        },
        function (error) {
          self.workInSelectedStoreTimer = setTimeout(() => self.workInSelectedStore(), 2000);
          console.log('SignalR send error: ' + error);
        });
    }
    else {
      self.workInSelectedStoreTimer = setTimeout(() => self.workInSelectedStore(), 2000);
    }
  }

  exitStore(){
    var self = this;
    this._signalRService.exitStore(
      function (data) {
        if (self.config.logPmLoading) console.log('Store was exited.');
      },
      function (response) {
        self._messageService.onFailure('SignalR exit store error.', response);
      });
  }

  synchronizeMessages(){
    var self = this;
    this._signalRService.synchronizeMessages(
      function (data) {
        if (self.config.logPmLoading) console.log('synchronizeMessages done.');
        //console.log(data);
        self._notificationWindowService.handleSychronizeMessages(data, self.myUserWorkingInStore);
      },
      function (error) {
        console.log('SignalR send error: ' + error);
      });

  }

  getReachableConnectedUsers() {
    this.pbValue = 20;
    var self = this;
    this._signalRService.getReachableConnectedUsers(
      function (data) {
        self._notificationWindowService.setReachableUsers(data);
      },
      function (error) {
        console.log('SignalR send error: ' + error);
      });

  }

  //this allows for setting a select value with an object
  compareStoreObjects(o1: any, o2: any): boolean {
    return o1 && o2 ? o1.storeId === o2.storeId : false;
  }

  //!///////////////////////////////
  // Determine Access
  //////////////////////////////////

  areStoreItemPriceChangesEnabledForStore(){
    this.pbValue = 30;
    this._spinnerService.setProgressSpinnerMessage('Determining if store item price changes are enabled...');
    this._accessService.areStoreItemPriceChangesEnabledForStore(this.store.storeId)
      .subscribe(
        (data:any) => {
          this.storeItemPriceChangesEnabledForStoreObject = data;
          this._priceManagementDataService.setStoreItemPriceChangesEnabledForStoreObject(data);
          this.areMeatAndProduceStoreItemPriceChangesEnabledForStore();
        },
        (response) => {
          this._messageService.onFailure('Failed to determine if store item price changes are enabled.', response);
        }
      )
  }

  areMeatAndProduceStoreItemPriceChangesEnabledForStore(){
    this.pbValue = 40;
    this._spinnerService.setProgressSpinnerMessage('Determining if meat and produce store item price changes are enabled...');
    this._accessService.areMeatAndProduceStoreItemPriceChangesEnabledForStore(this.store.storeId)
      .subscribe(
        (data:any) => {
          this.meatAndProduceStoreItemPriceChangesEnabledForStoreObject = data;
          this._priceManagementDataService.setMeatAndProduceStoreItemPriceChangesEnabledForStoreObject(data);
          this.arePreviewStoreItemPriceChangesEnabledForStore();
        },
        (response) => {
          this._messageService.onFailure('Failed to determine if meat and produce store item price changes are enabled.', response);
        }
      )
  }

  arePreviewStoreItemPriceChangesEnabledForStore(){
    this.pbValue = 50;
    this._spinnerService.setProgressSpinnerMessage('Determining if preview store item price changes are enabled...');
    this._accessService.arePreviewStoreItemPriceChangesEnabledForStore(this.store.storeId)
      .subscribe(
        (data:any) => {
          this.previewStoreItemPriceChangesEnabledForStoreObject = data;
          this._priceManagementDataService.setPreviewStoreItemPriceChangesEnabledForStoreObject(data);
          this.areMeatAndProducePreviewStoreItemPriceChangesEnabledForStore();
        },
        (response) => {
          this._messageService.onFailure('Failed to determine if preview store item price changes are enabled.', response);
        }
      )
  }

  areMeatAndProducePreviewStoreItemPriceChangesEnabledForStore(){
    this.pbValue = 60;
    this._spinnerService.setProgressSpinnerMessage('Determining if meat and produce preview store item price changes are enabled...');
    this._accessService.areMeatAndProducePreviewStoreItemPriceChangesEnabledForStore(this.store.storeId)
      .subscribe(
        (data:any) => {
          this.meatAndProducePreviewStoreItemPriceChangesEnabledForStoreObject = data;
          this._priceManagementDataService.setMeatAndProducePreviewStoreItemPriceChangesEnabledForStoreObject(data);
          this.determineAccess(() => { //callback
            this.getStoreItemsToBeSynchronizedCountByStoreIdSinceDateTime();
          });
        },
        (response) => {
          this._messageService.onFailure('Failed to determine if meat and produce preview store item price changes are enabled.', response);
        }
      )
  }

  getStoreItemsToBeSynchronizedCountByStoreIdSinceDateTime(){
    this.pbValue = 70;
    //Get number of changes to data, if over threshold, then get all items from server
    var dateLastSavedObjFromStorage = JSON.parse(localStorage.getItem('rpmsDatePricingDataWasLastSavedForStoresById'));
    if (dateLastSavedObjFromStorage !== null) { //dateStamp exists in local storage
      var dateStamp = dateLastSavedObjFromStorage[this.store.storeId];
      var dateSince = moment(dateStamp).format('YYYY-MM-DDTHHmmss');
      //dateSince = '2019-02-22T000000'; //for testing
      this._priceManagementService.getStoreItemsToBeSynchronizedCountByStoreIdSinceDateTime(this.store.storeId, dateSince)
      .subscribe(
        (data:any) => {
          if (data.storeItemsToBeSynchronizedCount > this.numberOfItemsToUpdateThreshold) {  //too many changes, get from server
            this._messageService.showToastTopRight('Too many changes to synchronize.  Reloading data from server.');
            this.getItemsFromServer();
          }
          else { // get from indexedDb and then synchronize
            if (this.config.logPmLoading) console.log('Getting Pricing Data from IndexedDb');
            this.getPricingDataFromIndexedDb();
          }
        },
        (response) => {
          this._messageService.onFailure('Failed to get store items to be synchronized count.', response);
          this.getItemsFromServer();
        }
      )
    }
    else { //no dateStamp in local storage
      this.getItemsFromServer();
    }
  }

  determineAccess(callback?) {
    // Preview Items Data
    if (this.tabModel.activeTab === 'Preview') {
      //this.showingPreviewItems = true;
      if (this.tabModel.activeSubTab === 'MeatPreviewItems' || this.tabModel.activeSubTab === 'ProducePreviewItems') {
        if (this.store.storeId) this.setAccess(this.meatAndProducePreviewStoreItemPriceChangesEnabledForStoreObject); 
      }
      else {
        if (this.store.storeId) this.setAccess(this.previewStoreItemPriceChangesEnabledForStoreObject); 
      }
    }
    // All Items Data
    else {
      //showingPreviewItems = false;
      if (this.tabModel.activeTab == 'Meat' || this.tabModel.activeTab == 'Produce') {
        if (this.store.storeId) this.setAccess(this.meatAndProduceStoreItemPriceChangesEnabledForStoreObject); 
      }
      else {
        if (this.store.storeId) this.setAccess(this.storeItemPriceChangesEnabledForStoreObject); 
      }
    }
    if (typeof callback === 'function') callback();
  }

  setAccess(arePriceChangesEnabledObject) {
    //only show the message once, unless access changes
    var showMessage = this.previousAccessValue != arePriceChangesEnabledObject.value;
    this.previousAccessValue = arePriceChangesEnabledObject.value;
    if (!this.permissions.roleAllowsUserToEditPricing) { //StorePricingViewerAndReporter, StorePricingViewer
      this.isGridReadOnly = true;
      if (this.showReadOnlyMessageToStorePricingViewer) {
        this._messageService.showToastBottomLeft('Your user role does not allow you to edit prices. The pricing grid is in read only mode.');
        this.showReadOnlyMessageToStorePricingViewer = false;
      }
    }
    else {
      this.isGridReadOnly = !arePriceChangesEnabledObject.value;
      if (this.isGridReadOnly) {
        this.isCellEditMode = false;
        if (this.isMasterInputMode) this.onMasterInputModeChange();
      }
      if (this.isGridReadOnly && arePriceChangesEnabledObject.hasOwnProperty('reason') && showMessage) { //only show message if access changes
        this._messageService.showToastBottomLeft(arePriceChangesEnabledObject.reason + ' This tab is in Read Only Mode.');
      }
    }
    //GAIR-99
    //if user hasReadOnlyRestriction, set this.isGridReadOnly to true
    if (this.myUser.hasReadOnlyRestriction) {
      this.isGridReadOnly = true;
    }
  }

  setAccessBasedOnServerMaintenanceWindow() {
    //priceChangesEnabled = !data.value;
    this.isGridReadOnly = this.serverMaintenanceWindowOpenObject.value;
    if (this.isGridReadOnly) this._messageService.alert('The pricing grid will be in read only mode, and you will not be able to edit prices.<br/><br/>Reason:<br/> ' + this.serverMaintenanceWindowOpenObject.reason, 'Attention');
  }

  //!///////////////////////////////
  // Get store data from Indexed Db
  //////////////////////////////////

  // this will only be used for offline mode

  getStoreDataFromIndexedDb(){
    var self = this;
    var db;
    var requestOpen = window.indexedDB.open("RPMS2", 3);

    requestOpen.onerror = function (event) {
      self._messageService.alert("Failed to open offline database.");
    }

    requestOpen.onsuccess = function (event:any) {
      db = event.target.result;
      var objectStore = db.transaction("storeData").objectStore("storeData");

      objectStore.openCursor().onsuccess = function (event) {
        var cursor = event.target.result;
        if (cursor) {
          self.stores.push(cursor.value.store);
          cursor.continue();
        }
        else {
          //no more storeData entries
        }
      }
    }
  }

  //!///////////////////////////////
  // Get items from Indexed Db
  //////////////////////////////////

  getPricingDataFromIndexedDb() {
    this.pbValue = 80;
    this._changeDetectorRef.detectChanges();
    var self = this;
    let count = 0;
    var dataChunkArray = [];
    var allItemsData = [];
    var dataDateTime = null;
    var storeId = this.store.storeId;
    var db: any;
    var requestOpen = window.indexedDB.open("RPMS2", 3);
    requestOpen.onerror = function(event) {
      self._messageService.alert('Failed to open indexedDb, getting items from server.');
      self.getItemsFromServer();
    };

    requestOpen.onsuccess = function (event: any) {
      self._spinnerService.handleProgressSpinnerVisibility('show');
      db = event.target.result;
      var transaction = db.transaction(["pricingData"]);
      var objectStore = transaction.objectStore("pricingData");

      objectStore.openCursor().onsuccess = function (event) {
        self._spinnerService.handleProgressSpinnerVisibility('show', 'Getting data from IndexedDb...');
        self._changeDetectorRef.detectChanges();
        var cursor = event.target.result;
        if (cursor && count < 2) { //some key (any key) exists. LIMITING TO 2 KEYS PER STORE. CHANGE THIS 2 IF THAT CHANGES (this count keeps it from continuing to check all keys in IDB after it has found 2 that match the storeId)
          var keyString = cursor.key.toString();
          var storeIdFromKey = keyString.substring(0, keyString.indexOf('-'));
          if (storeIdFromKey == storeId){
            count++;
            if (self.config.logPmLoading) console.log('getting data from key ' + keyString);
            dataChunkArray.push(JSON.parse(cursor.value.gridData));
            dataDateTime = cursor.value.dateTime;
          }
          if (self.config.logPmLoading) console.log('cursor continue...');
          cursor.continue();
        }
        else { //cursor has navigated through all keys (or no keys exist)
          if (self.config.logPmLoading) console.log('No more keys...');
          self.pbValue = 90;
          self._changeDetectorRef.detectChanges();
          if (dataChunkArray.length > 0){ //data exists
            if (moment(dataDateTime).isBefore(self.dataExpirationDate)) {
              //all items data is expired. Load all data from server
              self._messageService.showToastTopRight('Data has expired. Loading from server now...');
              self.getItemsFromServer();
            }
            else{ //data has not expired and can be loaded into grid
              if (self.config.logPmLoading) console.log('Concatenating data...');
              for (var i = 0; i < dataChunkArray.length; i++){
                allItemsData = allItemsData.concat(dataChunkArray[i]);
              }
              self.dataInfoBarMessage = 'You are viewing local data that was saved on ' + dataDateTime.toLocaleDateString() + ' at ' + dataDateTime.toLocaleTimeString() + '.';
              self.isDataInfoBarVisible = true;
              self._changeDetectorRef.detectChanges();
              //give info bar time to become visible
              setTimeout(() => {}, 100);
              if (self.config.logPmLoading) console.log('Creating hash table...');
              //self.createStoreItemsHashTable(allItemsData);
              self.createAllItemsHashTable(allItemsData);
              if (self.config.logPmLoading) console.log('Formatting and setting row data...');
              self.pbValue = 100;
              self._changeDetectorRef.detectChanges();
              self.formatandSetRowData(allItemsData, function() {
                //callback
                if (self.config.logPmLoading) console.log('Synchronizing store items...');
                self.synchronizeStoreItems(dataDateTime);
              });
              //size grid since data information bar moved it down
             // setTimeout(() => { self.sizeGrid() }, 100);
            }
          }
          else{// data doesn't exist for selected store. Load all data from server
            self._messageService.showToastTopRight('No data found in indexedDb. Loading from server now...');
            self.getItemsFromServer();
          }
        }
      }

    };

    //this will create a new RPMS database if it doesn't exist
    requestOpen.onupgradeneeded = function (event: any) {
      self.createDatabaseObjects(event);    }
  }

  synchronizeStoreItems(dataDateTime){
    var self = this;
    self.isDataSynchronizing = true;
    self._changeDetectorRef.detectChanges();
    self._spinnerService.handleProgressSpinnerVisibility('show', 'Synchronizing store items...');
    self._signalRService.synchronizeStoreItems(dataDateTime.toLocaleString(),
      function (data) {
        if (!data.successful) {
          self._messageService.alert('Price Management Hub error while synchronizing store item price changes. ' + data.message);
          self.isDataSynchronizing = false;
          self._changeDetectorRef.detectChanges();
          self._spinnerService.handleProgressSpinnerVisibility('hide', 'Processing request...');
        }
        else {
          if (data.storeItems.length > 0) {
            self._spinnerService.handleProgressSpinnerVisibility('show', 'Updating store items...');
            for (var i=0; i<data.storeItems.length; i++){
              data.storeItems[i].isPreview = false;  // TODO? property not on returned data
              self.updateRowNodeWithNewData(null, data.storeItems[i]);
              self._notificationWindowService.createSynchronizationNotification(data.storeItems[i]);
            }
          }
        }
        if (self.config.logPmLoading) console.log('SignalR synchronizeStoreItems was successful.');
        if (self.config.logPmLoading) console.log('Synchronizing preview store items...');
        self.synchronizePreviewStoreItems(dataDateTime);
      },
      function (error) {
        self._messageService.alert('SignalR send error: ' + error);
        self.isDataSynchronizing = false;
        self._changeDetectorRef.detectChanges();
        self._spinnerService.handleProgressSpinnerVisibility('hide', 'Processing request...');
      });
      this.pbValue = 0;
  }

  synchronizePreviewStoreItems(dataDateTime){
    var self = this;
    self.isDataSynchronizing = true;
    self._changeDetectorRef.detectChanges();
    self._spinnerService.handleProgressSpinnerVisibility('show', 'Synchronizing preview store items...');
    self._signalRService.synchronizePreviewStoreItemsV2(dataDateTime.toLocaleString(),
      function (data) {
        if (!data.successful) {
          self._messageService.alert('Price Management Hub error while synchronizing preview store item price changes. ' + data.message);
          self.isDataSynchronizing = false;
          self._changeDetectorRef.detectChanges();
          self._spinnerService.handleProgressSpinnerVisibility('hide', 'Processing request...');
        }
        else {
          if (data.previewStoreItems.length > 0) {
            self._spinnerService.handleProgressSpinnerVisibility('show', 'Updating preview store items...');
            for (var i=0; i<data.previewStoreItems.length; i++){
              data.previewStoreItems[i].isPreview = true;  // TODO? property not on returned data
              self.updateRowNodeWithNewData(null, data.previewStoreItems[i]);
              self._notificationWindowService.createSynchronizationNotification(data.previewStoreItems[i]);
            }
          }
          if (self.config.logPmLoading) console.log('SignalR synchronizePreviewStoreItems was successful.');
          self.dataInfoBarMessage = 'You are viewing local data that was saved on ' + dataDateTime.toLocaleDateString() + ' at ' + dataDateTime.toLocaleTimeString() + '. (Synchronized on ' + new Date().toLocaleString() + ')';
        }
        self.isDataSynchronizing = false;
        self._changeDetectorRef.detectChanges();
        self._spinnerService.handleProgressSpinnerVisibility('hide', 'Processing request...');
        //size grid since data information bar moved it down
        setTimeout(() => { self.sizeGrid() }, 100);
      },
      function (error) {
        self._messageService.alert('SignalR send error: ' + error);
        self.isDataSynchronizing = false;
        self._changeDetectorRef.detectChanges();
        self._spinnerService.handleProgressSpinnerVisibility('hide', 'Processing request...');
        //size grid since data information bar moved it down
        setTimeout(() => { self.sizeGrid() }, 100);
      });
      this.pbValue = 0;
  }

  createAllItemsHashTable(allItemsData) {
    this._spinnerService.handleProgressSpinnerVisibility('show', 'Creating hash table...');
    this.allItemsHashTable = {};
    for (var i = 0; i < allItemsData.length; i++) {
      var key = allItemsData[i].isPreview ? 'P' + allItemsData[i].item.itemCode : 'S' + allItemsData[i].item.itemCode;
      this.allItemsHashTable[key] = allItemsData[i];
    }
    if (this.config.logPmLoading) console.log('all items hash table completed');
    //console.log(this.allItemsHashTable);
  }

  updateAllItemsHashTable(unformattedItem) {
    let itemCode;
    if (unformattedItem && unformattedItem.itemCode) itemCode = unformattedItem.itemCode;
    if (unformattedItem && unformattedItem.item && unformattedItem.item.itemCode) itemCode = unformattedItem.item.itemCode;
    //unformattedItem may not contain all properties.  This can be a store item or preview item
    if (itemCode) {
      var key = unformattedItem.isPreview ? 'P' + itemCode : 'S' + itemCode;
      let correspondingItemInHashTable = this.allItemsHashTable[key];
      //if exists, only update properties that exist on unformattedItem
      if (correspondingItemInHashTable) {
        for (var k in unformattedItem) if (correspondingItemInHashTable.hasOwnProperty(k)) correspondingItemInHashTable[k] = unformattedItem[k];
      }
      else { //create this new key/value.  TODO - Is this data shape the same as the rest?
        this.allItemsHashTable[key] = unformattedItem;
      }
    }
    else {
      console.log('Cannot update AllItemsHashTable because unformattedItem is undefined or missing item code.');
      console.log({unformattedItem: unformattedItem});
    }
  }

  //!///////////////////////////////
  // Get items from server
  //////////////////////////////////

  getItemsFromServer(){
    this.pbValue = 85;
    this._changeDetectorRef.detectChanges();
    this._spinnerService.handleProgressSpinnerVisibility('show', 'Getting items from server...');
    this._priceManagementService.getAllItemsByStoreId(this.store.storeId)
    .subscribe(
      (data) => {
        //this.allItemsData = data;
        //this.createStoreItemsHashTable(data);
        this.createAllItemsHashTable(data);
        this.getPreviewItemsFromServer(data);
      },
      (response) => {
        this._messageService.onFailure('Failed to get store items.', response);
      })
  }

  getPreviewItemsFromServer(allItemsData){
    //var self = this;
    this.pbValue = 90;
    this._changeDetectorRef.detectChanges();
    this._spinnerService.handleProgressSpinnerVisibility('show', 'Getting preview items from server...');
    this._priceManagementService.getAllPreviewItemsByStoreIdV2(this.store.storeId)
    .subscribe(
      (data:any) => {
        for (var i=0; i<data.length; i++){
          allItemsData.push(data[i]);
        }
        if (allItemsData.length > 10000){
          var dataChunk1 = allItemsData.slice(0, 10000);
          var dataChunk2 = allItemsData.slice(10000);
          this.saveAllItemsDataToIndexedDb(dataChunk1, this.store.storeId + '-1', () => {
            this.pbValue = 95;
            this._changeDetectorRef.detectChanges();
            this.saveAllItemsDataToIndexedDb(dataChunk2, this.store.storeId + '-2', () => {
              this.pbValue = 97;
              this._changeDetectorRef.detectChanges();
              this.saveSelectedStoreToIndexedDb(() => {
                this.dataInfoBarMessage = 'You are viewing server data that was saved locally on ' + new Date().toLocaleDateString() + ' at ' + new Date().toLocaleTimeString() + '.';
                this.pbValue = 100;
                this._changeDetectorRef.detectChanges();
                this.formatandSetRowData(allItemsData);
              })
            })
          });
        }
        else{
          this.saveAllItemsDataToIndexedDb(allItemsData, this.store.storeId + '-1', () => {
            this.pbValue = 95;
            this._changeDetectorRef.detectChanges();
            this.saveSelectedStoreToIndexedDb(() => {
              this.dataInfoBarMessage = 'You are viewing server data that was saved locally on ' + new Date().toLocaleDateString() + ' at ' + new Date().toLocaleTimeString() + '.';
              this.pbValue = 100;
              this._changeDetectorRef.detectChanges();
              this.formatandSetRowData(allItemsData);
            })
          });
        }
      },
      (response) => {
        this._messageService.onFailure('Failed to get preview items.', response);
      })
  }

  //!///////////////////////////////
  // Save to Indexed Db
  //////////////////////////////////

  saveAllItemsDataToIndexedDb(allItemsData, key, callback?) {
    let dateStamp = new Date();
    //var utcDateStamp = new Date(Date.UTC(dateStamp.getFullYear(), dateStamp.getMonth(), dateStamp.getDate(), dateStamp.getHours(), dateStamp.getMinutes(), dateStamp.getSeconds(), dateStamp.getMilliseconds()));
    var self = this;
    self._spinnerService.handleProgressSpinnerVisibility('show', 'Saving items to IndexedDb...');
    var db;
    var pricingData = {
      key: key,
      storeId: this.store.storeId,
      dateTime: dateStamp,
      gridData: JSON.stringify(allItemsData),
    };

    var requestOpen = window.indexedDB.open("RPMS2", 3);
    requestOpen.onerror = function(event) {
      self._messageService.alert("Failed to open offline database. " + event);
    };

    requestOpen.onsuccess = function(event: any) {
      db = event.target.result;

      var requestPutPricingData = db.transaction(["pricingData"], "readwrite").objectStore("pricingData").put(pricingData);
      requestPutPricingData.onsuccess = function(e) {
        self._spinnerService.handleProgressSpinnerVisibility('hide');
        self._messageService.onSuccess("Successfully added offline data for store " + self.store.storeNumber);
        var dateLastSavedObjFromStorage = JSON.parse(localStorage.getItem ('rpmsDatePricingDataWasLastSavedForStoresById'));
        var dateLastSavedObj = dateLastSavedObjFromStorage == null ? {} : dateLastSavedObjFromStorage;
        dateLastSavedObj[self.store.storeId] = dateStamp;
        localStorage.setItem('rpmsDatePricingDataWasLastSavedForStoresById', JSON.stringify(dateLastSavedObj));
        db.close();
        if (typeof callback === 'function') setTimeout(() => callback(), 1000);
      };

      requestPutPricingData.onerror = function (e) {
        self._messageService.alert("Unable to add offline pricing data for store " + self.store.storeNumber + '. ' + e);
      };
    };

    requestOpen.onupgradeneeded = function (event: any) {
      self.createDatabaseObjects(event);
    };

  }

  saveSelectedStoreToIndexedDb(callback?) {
    var self = this;
    self._spinnerService.handleProgressSpinnerVisibility('show', 'Saving store to IndexedDb...');
    var storeData = {
      storeId: self.store.storeId,
      store: self.store,
    };

    var db;
    var requestOpen = window.indexedDB.open("RPMS2", 3);

    requestOpen.onerror = function (event) {
      self._messageService.alert("Failed to open offline database. " + event);
    };

    requestOpen.onsuccess = function (event:any) {
      db = event.target.result;

      var requestPutStoreData = db.transaction(["storeData"], "readwrite").objectStore("storeData").put(storeData);
      requestPutStoreData.onsuccess = function (e) {
        if (typeof callback === 'function') callback();
      };

      requestPutStoreData.onerror = function (e) {
        self._messageService.alert("Unable to add store data. " + e);
      };
    };

    requestOpen.onupgradeneeded = function (event) {
      self.createDatabaseObjects(event);
    };
  }

  createDatabaseObjects(event) {
    var newVersion = event.target.result;
    if (!newVersion.objectStoreNames.contains("pricingData")) {
      newVersion.createObjectStore("pricingData", { keyPath: "key" });
    }
    if (!newVersion.objectStoreNames.contains("storeData")) {
      newVersion.createObjectStore("storeData", { keyPath: "storeId" });
    }
  }

  saveColumnMetadata(){
    var colState = this.agGrid.api.getColumnState();
    var colGroupState = this.agGrid.api.getColumnGroupState();
    var metadata = {
      columnState: colState,
      columnGroupState: colGroupState,
    }
    //save to variable that will be used to save before logging out in header.ts
    this._priceManagementDataService.setColumnMetadata(metadata);
    //save to local storage to be used for offline mode
    localStorage.setItem('rpmsPricingGridMetadata', JSON.stringify(metadata));
    
    //this would bog down for every column move or resize.  
    //Since the indexedDb is only for offline mode, don't save these events.  They will be saved on page exit anyway.
    //saveColumnMetadata is needed only if someone is logging out and not exiting page
    //if (e.type != 'columnMoved' && e.type != 'columnResized') self.saveColumnGridMetadataToIndexedDb();
  }

  //used to check if Item Lists Column become visible so that Item Lists will be loaded a that moment
  onColumnVisible(e) {
    let self = e.context.componentParent;
    if (!self.itemLists) {
      if (e.visible && (self.columnsHasItemLists(e) || e.column && e.column.colId == "itemLists")) {
        //Item Lists column is visible so load Item Lists
        self._spinnerService.handleProgressSpinnerVisibility('show', 'Loading Item Lists...');
        self.getActiveSharedItemLists(function() {
          //callback - put list names in column data
          self.agGrid.api.forEachNode(function (rowNode){
            rowNode.setDataValue('itemLists', self.getItemListsFromItemCode(rowNode.data.itemCode));
          });
        })
      }
    }
    //save column state now that it includes Item Lists as visible
    self.saveColumnMetadata();
  }

  columnsHasItemLists(e) {
    for (let i=0; i<e.columns.length; i++) {
      if (e.columns[i].colId == "itemLists") return true;
    }
    return false;
  }

  //this is called on grid column events (when something changes)
  onColumnChange(e) {
    let self = e.context.componentParent;
    self.saveColumnMetadata();
  }

  //!///////////////////////////////
  // Format items for grid
  //////////////////////////////////

  formatSingleRowData(data) {
    var singleRowData = {}; //singleRowData gets returned from this function
    if (data){
      var lastPurchasedDate = data.lastPurchasedDate;
      var shelfTagPendingFulfillment = data.shelfTagPendingFulfillment;
      var shelfTagRequestDate = data.shelfTagRequestDate;
      var shelfTagQuantityOrdered = data.shelfTagQuantityOrdered;
      var shelfTagStatus = this.getShelfTagStatus(data);
      var tprTagPendingFulfillment = data.tprTagPendingFulfillment;
      var tprTagRequestDate = data.tprTagRequestDate;
      var tprPersist = data.tprPersist;
      var tprBook = data.tprBook;
      var tprTagStatus = this.getTprTagStatus(data);
      var storeItemId = data.storeItemId;
      var lastYearsMovement = data.item.lastYearsMovement;
      var yearToDateMovement = data.item.yearlyMovement;
      var weeksInInventory = data.item.weeksInInventory;
      var averageWeeklyMovement = data.item.averageWeeklyMovement;
      var balanceOnHand = data.item.balanceOnHand;
      var offPack = data.item.offPack;
      var groupNumber = data.item.groupNumber;
      var groupDescription = data.item.groupDescription;
      var buyerCode = data.item.buyerCode;
      var buyerName = data.item.buyerFullName;
      var buyerPhone = data.item.buyerPhoneNumber;
      var buyerEmail = data.item.buyerEmailAddress;
      var lastYearsMovementForStore = data.lastYearsMovementForStore;
      var yearToDateMovementForStore = data.yearlyMovementForStore;
      var weeksInStore = data.weeksInStore;
      var averageWeeklyMovementForStore = data.averageWeeklyMovementForStore;

      //If item is preview, overwrite these properties with properties from associated store item
      if (data.isPreview){
        let key = 'S' + data.item.itemCode; //Store items have an 'S' prefix
        var associatedStoreItemToPreviewItem = this.allItemsHashTable[key];
        if (associatedStoreItemToPreviewItem){
          lastPurchasedDate = associatedStoreItemToPreviewItem.lastPurchasedDate;
          shelfTagPendingFulfillment = associatedStoreItemToPreviewItem.shelfTagPendingFulfillment;
          shelfTagRequestDate = associatedStoreItemToPreviewItem.shelfTagRequestDate;
          shelfTagQuantityOrdered = associatedStoreItemToPreviewItem.shelfTagQuantityOrdered;
          shelfTagStatus = this.getShelfTagStatus(associatedStoreItemToPreviewItem);
          tprTagPendingFulfillment = associatedStoreItemToPreviewItem.tprTagPendingFulfillment;
          tprTagRequestDate = associatedStoreItemToPreviewItem.tprTagRequestDate;
          tprPersist = associatedStoreItemToPreviewItem.tprPersist;
          tprBook = associatedStoreItemToPreviewItem.tprBook;
          tprTagStatus = this.getTprTagStatus(associatedStoreItemToPreviewItem);
          storeItemId = associatedStoreItemToPreviewItem.storeItemId;
          lastYearsMovement = associatedStoreItemToPreviewItem.item.lastYearsMovement;
          yearToDateMovement = associatedStoreItemToPreviewItem.item.yearlyMovement;
          weeksInInventory = associatedStoreItemToPreviewItem.item.weeksInInventory;
          averageWeeklyMovement = associatedStoreItemToPreviewItem.item.averageWeeklyMovement;
          balanceOnHand = associatedStoreItemToPreviewItem.item.balanceOnHand;
          offPack = associatedStoreItemToPreviewItem.item.offPack;
          groupNumber = associatedStoreItemToPreviewItem.item.groupNumber;
          groupDescription = associatedStoreItemToPreviewItem.item.groupDescription;
          buyerCode = associatedStoreItemToPreviewItem.item.buyerCode;
          buyerName = associatedStoreItemToPreviewItem.item.buyerFullName;
          buyerPhone = associatedStoreItemToPreviewItem.item.buyerPhoneNumber;
          buyerEmail = associatedStoreItemToPreviewItem.item.buyerEmailAddress;
          lastYearsMovementForStore = associatedStoreItemToPreviewItem.lastYearsMovementForStore;
          yearToDateMovementForStore = associatedStoreItemToPreviewItem.yearlyMovementForStore;
          weeksInStore = associatedStoreItemToPreviewItem.weeksInStore;
          averageWeeklyMovementForStore = associatedStoreItemToPreviewItem.averageWeeklyMovementForStore;
        }
        else {
          this._messageService.alert('The preview item with item code: ' + data.item.itemCode + ' has no associated store item in the hash table. Please take note that the data that will be shown for this preview item will be from the preview item itself and not its associated store item.', 'Warning!');
        }
      }

      singleRowData = {
        action: "",
        itemCode: data.item.itemCode,
        deptCode: data.item.deptCode,
        isStock: data.isStock,
        isPreview: data.isPreview ? true : false, //signalR doesn't have isPreview property for filters, so must do this
        size: data.item.size,
        description: (data.item.description).trim(),
        longDescription: (data.item.longDescription != null) ? (data.item.longDescription).trim() : '',
        privateLabel: data.item.privateLabel == '' ? ' ' : data.item.privateLabel,
        newItem: data.item.newItem,
        firstTimeBuy: data.firstTimeBuy,
        wicItem: false, //GAIR-131 - set to false, was: data.wicItem,
        nowItem: data.nowItem,
        firstTimeBuyOrNowItem: data.firstTimeBuyOrNowItem,
        pricingRuleMatched: data.pricingRuleMatched,
        //GAIR-107
        //unitCost: this.convertToTwoDecimals(data.unitCost), //commented out in GAIR-122, using deliveredUnitCost again instead
        //
        deliveredUnitCost: (data.isPreview) ? this.convertToTwoDecimals(data.previewItem.defaultDeliveredUnitCost) : this.convertToTwoDecimals(data.deliveredUnitCost),
        unitCostLessDeliveryFee: (data.isPreview) ? this.convertToTwoDecimals(data.previewItem.defaultUnitCostLessDeliveryFee) : this.convertToTwoDecimals(data.unitCostLessDeliveryFee),
        caseCostLessDeliveryFee: (data.isPreview) ? this.convertToTwoDecimals(data.previewItem.defaultCaseCostLessDeliveryFee) : this.convertToTwoDecimals(data.caseCostLessDeliveryFee),
        deliveredCaseCost: (data.isPreview) ? this.convertToTwoDecimals(data.previewItem.defaultDeliveredCaseCost) : this.convertToTwoDecimals(data.deliveredCaseCost),
        units: (data.isPreview) ? data.previewItem.units : data.item.units,
        baseCost: (data.isPreview) ? this.convertToTwoDecimals(data.previewItem.defaultBaseCost) : this.convertToTwoDecimals(data.baseCost),
        masterChangeCheckbox: false,
        book: data.book,
        srpCode: data.srpCode,
        multi: data.multi,
        currentPrice: data.currentPrice,
        price: data.price,
        percent: data.percent,
        deal: (data.isPreview) ? data.previewItem.dealAmt : data.dealAmt,
        //dealDate: (data.isPreview) ? (data.previewItem.dealDate != null && data.previewItem.dealDate != '' ? moment(data.previewItem.dealDate, 'MMDDYY').toDate() : '') : (data.dealDate != null ? moment(data.dealDate).toDate() : ''),
        dealDate: (data.isPreview) ? (data.previewItem.dealDate != null && data.previewItem.dealDate != '' ? moment(data.previewItem.dealDate, 'MMDDYY').format('YYYY-MM-DD') + 'T00:00:00' : '') : (data.dealDate != null ? data.dealDate : ''),
        chg: (data.changeCode != null) ? data.changeCode : '',
        oldBook: (data.lastBook != null) ? data.lastBook : '',
        oldSrpCode: (data.lastSrpCode != null) ? data.lastSrpCode : '',
        oldMulti: (data.isPreview) 
        ? (data.mainframeProvidedLastMulti != null)
            ? data.mainframeProvidedLastMulti 
            : ''
        : (data.lastMulti != null) 
            ? data.lastMulti
            : '',
        oldPrice: (data.isPreview) 
        ? (data.mainframeProvidedLastPrice != null)
            ? this.convertToTwoDecimals(data.mainframeProvidedLastPrice) 
            : ''
        : (data.lastPrice != null) 
            ? data.lastPrice
            : '',
        oldKeepSrp: (data.lastKeepSrp != null) ? data.lastKeepSrp : '',
        oldPercent: (data.isPreview) 
        ? (data.oldPercent != null && data.oldPercent != 0)
            ? data.oldPercent 
            : ''
        : (data.lastPercent != null) 
            ? data.lastPercent
            : '',
        oldKeepPercent: (data.lastKeepPercent != null) ? data.lastKeepPercent : '',
        oldBaseCost: (data.lastBaseCost != null) ? this.convertToTwoDecimals(data.lastBaseCost) : '',
        oldDeliveredCaseCost: (data.lastDeliveredCaseCost != null) ? this.convertToTwoDecimals(data.lastDeliveredCaseCost) : '',
        oldDeliveredUnitCost: (data.isPreview) 
          ? (data.mainframeProvidedLastUnitCost != null)
              ? this.convertToTwoDecimals(data.mainframeProvidedLastUnitCost) 
              : ''
          : (data.lastDeliveredUnitCost != null) 
              ? this.convertToTwoDecimals(data.lastDeliveredUnitCost)
              : '',
        edited: data.edited,
        itemLists: (this.itemLists) ? this.getItemListsFromItemCode(data.item.itemCode) : [{fullName: 'Loading...'}],
        itemId: data.item.itemId,
        upcCode: data.item.upcCode,
        status: data.item.status,
        statusDescription: this.getStatusDesc(data.item.status, data.item.balanceOnHand),
        lastYearsMovement: lastYearsMovement,
        yearToDateMovement: yearToDateMovement,
        weeksInInventory: weeksInInventory,
        averageWeeklyMovement: averageWeeklyMovement ? Math.round(averageWeeklyMovement * 100) / 100 : 0,
        balanceOnHand: balanceOnHand,
        offPack: offPack,
        groupNumber: Number(groupNumber),
        groupDescription: groupDescription,
        buyerCode: buyerCode,
        buyerName: buyerName,
        buyerPhone: buyerPhone,
        buyerEmail: buyerEmail,
        storeNumber: this.store.storeNumber,
        storeItemId: storeItemId,
        previewStoreItemId: data.previewStoreItemId,
        lastYearsMovementForStore: lastYearsMovementForStore,
        yearToDateMovementForStore: yearToDateMovementForStore,
        weeksInStore: weeksInStore,
        averageWeeklyMovementForStore: averageWeeklyMovementForStore ? Math.round(averageWeeklyMovementForStore * 100) / 100 : 0,
        lastPurchasedDate: (lastPurchasedDate != null && lastPurchasedDate != '') ? moment.utc(new Date(lastPurchasedDate)).format("MM/DD/YY") : '',
        shelfTagPendingFulfillment: shelfTagPendingFulfillment,
        shelfTagRequestDate: (shelfTagRequestDate != null && shelfTagRequestDate != '') ? moment.utc(new Date(shelfTagRequestDate)).format("MM/DD/YY") : '',
        shelfTagQuantityOrdered: shelfTagQuantityOrdered,
        shelfTagStatus: shelfTagStatus,
        tprTagPendingFulfillment: tprTagPendingFulfillment,
        tprTagRequestDate: (tprTagRequestDate != null && tprTagRequestDate != '') ? moment.utc(new Date(tprTagRequestDate)).format("MM/DD/YY") : '',
        tprPersist: tprPersist,
        tprBook: tprBook,
        tprTagStatus: tprTagStatus,
        keepSrp: data.keepSrp,
        keepPercent: data.keepPercent,
        //setPriceKeepPercent: false,
        multi1: (data.isPreview) ? data.previewItem.multi1 : data.item.multi1,
        base1: (data.isPreview) ? data.previewItem.base1 : data.item.base1,
        percent1: (data.isPreview) ? data.previewItem.percent1 : data.item.percent1,
        multi2: (data.isPreview) ? data.previewItem.multi2 : data.item.multi2,
        base2: (data.isPreview) ? data.previewItem.base2 : data.item.base2,
        percent2: (data.isPreview) ? data.previewItem.percent2 : data.item.percent2,
        multi3: (data.isPreview) ? data.previewItem.multi3 : data.item.multi3,
        base3: (data.isPreview) ? data.previewItem.base3 : data.item.base3,
        percent3: (data.isPreview) ? data.previewItem.percent3 : data.item.percent3,
        multi4: (data.isPreview) ? data.previewItem.multi4 : data.item.multi4,
        base4: (data.isPreview) ? data.previewItem.base4 : data.item.base4,
        percent4: (data.isPreview) ? data.previewItem.percent4 : data.item.percent4,
        multi5: (data.isPreview) ? data.previewItem.multi5 : data.item.multi5,
        base5: (data.isPreview) ? data.previewItem.base5 : data.item.base5,
        percent5: (data.isPreview) ? data.previewItem.percent5 : data.item.percent5,
        multi6: (data.isPreview) ? data.previewItem.multi6 : data.item.multi6,
        base6: (data.isPreview) ? data.previewItem.base6 : data.item.base6,
        percent6: (data.isPreview) ? data.previewItem.percent6 : data.item.percent6,
        multi7: (data.isPreview) ? data.previewItem.multi7 : data.item.multi7,
        base7: (data.isPreview) ? data.previewItem.base7 : data.item.base7,
        percent7: (data.isPreview) ? data.previewItem.percent7 : data.item.percent7,
        multi1C: (data.isPreview) ? data.previewItem.multi1C : data.item.multi1C,
        current1: (data.isPreview) ? data.previewItem.current1 : data.item.current1,
        percent1C: (data.isPreview) ? data.previewItem.percent1C : data.item.percent1C,
        multi2C: (data.isPreview) ? data.previewItem.multi2C : data.item.multi2C,
        current2: (data.isPreview) ? data.previewItem.current2 : data.item.current2,
        percent2C: (data.isPreview) ? data.previewItem.percent2C : data.item.percent2C,
        multi3C: (data.isPreview) ? data.previewItem.multi3C : data.item.multi3C,
        current3: (data.isPreview) ? data.previewItem.current3 : data.item.current3,
        percent3C: (data.isPreview) ? data.previewItem.percent3C : data.item.percent3C,
        multi4C: (data.isPreview) ? data.previewItem.multi4C : data.item.multi4C,
        current4: (data.isPreview) ? data.previewItem.current4 : data.item.current4,
        percent4C: (data.isPreview) ? data.previewItem.percent4C : data.item.percent4C,
        multi5C: (data.isPreview) ? data.previewItem.multi5C : data.item.multi5C,
        current5: (data.isPreview) ? data.previewItem.current5 : data.item.current5,
        percent5C: (data.isPreview) ? data.previewItem.percent5C : data.item.percent5C,
        multi6C: (data.isPreview) ? data.previewItem.multi6C : data.item.multi6C,
        current6: (data.isPreview) ? data.previewItem.current6 : data.item.current6,
        percent6C: (data.isPreview) ? data.previewItem.percent6C : data.item.percent6C,
        multi7C: (data.isPreview) ? data.previewItem.multi7C : data.item.multi7C,
        current7: (data.isPreview) ? data.previewItem.current7 : data.item.current7,
        percent7C: (data.isPreview) ? data.previewItem.percent7C : data.item.percent7C,
        removedItem: false,
      };
    }

    // GAIR-122 code to compare unitCost to deliveredUnitCost
    let ic = data.item.itemCode;
    let uc = this.convertToTwoDecimals(data.unitCost);
    let duc = (data.isPreview) ? this.convertToTwoDecimals(data.previewItem.defaultDeliveredUnitCost) : this.convertToTwoDecimals(data.deliveredUnitCost);
    // if (uc != duc) {
    //   console.log('itemCode: ' + ic);
    //   console.log('unitCost: ' + uc);
    //   console.log('deliveredUnitCost: ' + duc);
    //   console.log('\n\n');
    // }
    
    return singleRowData;
  }

  getItemListsFromItemCode(itemCode) {
    var itemListsContainingThisItem = [];
    for (var i = 0; i < this.itemLists.length; i++) {
      if (this.itemLists[i].itemCodes.indexOf(itemCode) > -1) {
        itemListsContainingThisItem.push(this.itemLists[i]);
      }
    }
    return itemListsContainingThisItem;
  }

  getStatusDesc(statusCode, balanceOnHand) {
    switch (statusCode) {
      case '1': return 'Net Net Item';
      case 'D': return (balanceOnHand > 0) ? 'Active' : 'Deleted';
      case 'L': return 'Long Term Unavailable';
      case 'T': return 'Temporarily Unavailable';
      case 'C': return 'Comment Line';
      case 'I': return 'In & Out Item';
      case 'R': return 'Replaced Item';
      case '': return 'Active';
      default: return 'Unknown';
    }
  }

  getTprTagStatus(storeItem){
    var tprTagStatus = '';
    if (storeItem.tprTagPendingFulfillment) {
      if (storeItem.tprPersist) {
        tprTagStatus = "Always, Bk ";
      }
      else {
        tprTagStatus = "Once, Bk ";
      }
      tprTagStatus += storeItem.tprBook + ', on ' + moment(storeItem.tprTagRequestDate).format('M/D/YYYY');
    }
    else {
      tprTagStatus = "None Requested";
    }
    return tprTagStatus;
  }

  getShelfTagStatus(storeItem){
    return (storeItem.shelfTagPendingFulfillment) ?
      storeItem.shelfTagQuantityOrdered + ' Requested, on ' + moment(storeItem.shelfTagRequestDate).format('M/D/YYYY') : "None Requested";
  }

  public getRowId = (params) => {
    var nodeId = params.data.isPreview ? 'P' + params.data.itemCode : 'S' + params.data.itemCode;
    return String(nodeId);
  }

  formatandSetRowData(data, callback?) {
    this._spinnerService.handleProgressSpinnerVisibility('show', 'Formatting Data...');
    var gridData = [];
    for (var i=0; i<data.length; i++){
      var singleRowData:any = this.formatSingleRowData(data[i]);
      gridData.push(singleRowData);
    }
    //callback to add itemCode as rowNodeId
    //TODO - deprecated
    // this.agGrid.api.getRowId = function(formattedRowData) {
    //   var nodeId = formattedRowData.isPreview ? 'P' + formattedRowData.itemCode : 'S' + formattedRowData.itemCode;
    //   return String(nodeId);
    // };
    this.rowData = gridData;
    this.onWindowResize();
    setTimeout(() => {
      this.isShowingFooter = true;
      this.sizeGrid();
      var nameOfItemFilterToApplyAfterLoadingItems = this._priceManagementFilterService.getNameOfItemFilterToApplyAfterLoadingItems();
      // if an item filter needs to be applied (from home link)
      if (nameOfItemFilterToApplyAfterLoadingItems !== '') {
        this.itemFilterComponent.selectItemFilterByName(nameOfItemFilterToApplyAfterLoadingItems);
      }
      // else apply whatever current tab model is (will remember previous tabs if returning to page)
      else{
        var tabModel = this._priceManagementFilterService.getTabModel();
        this._priceManagementFilterService.setGridFilterModelFromTabModel(tabModel);
      }
      this.isGridDataLoaded = true;
      this.agGrid.api.setFocusedCell(0, 'itemCode', null);
      this._spinnerService.handleProgressSpinnerVisibility('hide', 'Processing Request...');
      this.pbValue = 0;
      this._changeDetectorRef.detectChanges();
      this.setFileNameForExport();
      if (typeof callback === 'function') callback();
    },100); 
  }

  //!///////////////////////////////
  // Grid sizing and events
  //////////////////////////////////

  //Grid sizing was changed to be handled by CSS (flexbox) on 6/21/2022
  //This function and references to it can eventually be removed
  sizeGrid() {
    // var spaceBelowGrid = 35;
    // var gridElement = <HTMLElement>document.querySelector('#pm-ag-grid-wrapper');
    // var wrapperElement = <HTMLElement>document.querySelector('#pm-grid-and-controls');
    // var footerWrapperElement = <HTMLElement>document.querySelector('#pm-grid-footer-wrapper');
    // var windowHeight = window.innerHeight;
    // if (document.body.contains(gridElement) && document.body.contains(wrapperElement)){
    //   var wrapperOffset = wrapperElement.getBoundingClientRect().top;
    //   var remainingSpace = windowHeight - wrapperOffset;
    //   var gridHeight = this.isShowingFooter ? remainingSpace - 252 - spaceBelowGrid : remainingSpace - spaceBelowGrid - 107;
    //   wrapperElement.style.height = (windowHeight - wrapperOffset - spaceBelowGrid - 5) + 'px';
    //   gridElement.style.height = gridHeight + 'px';
    //   var bottom = this.isShowingFooter ? 5 : 0;
    //   footerWrapperElement.style.bottom = bottom + 'px';
    // }
  }

  timeout = false;

  onWindowResize() {
    var rtime;
    var delta = 200;
    window.onresize = () => {
      rtime = new Date();
      if (this.timeout === false) {
        this.timeout = true;
        //console.log({ onResize: this});
        setTimeout(() => this.resizeend(rtime, delta), delta);
      }
    }  
  }

  resizeend(rtime, delta) {
    //console.log({ resizeEnd: this});
    if (new Date().getTime() - rtime < delta) {
      setTimeout(() => this.resizeend(rtime, delta), delta);
    } 
    else {
      this.timeout = false;
      //done resizing
      this.sizeGrid();
    }
  }

  onModelUpdated(){
    this.rowCount = this.agGrid.api.getDisplayedRowCount();
    this.setFileNameForExport();
  }

  onCellEditModeChange() {
    this.isCellEditMode = !this.isCellEditMode;
    this.hideAllEditors();
    if (this.isMasterInputMode) this.onMasterInputModeChange(); //turn off
    if (!this.isGridReadOnly){
      this.setEditableColumns(this.isCellEditMode);
    }
    // unfocusing allows the double click to be triggered again after a edit mode change without having to click off the row and back on the row
    this.agGrid.api.clearFocusedCell();
  };

  setEditableColumns(value){
    this.agGrid.api.getColumn('book').getColDef().editable = value;
    this.agGrid.api.getColumn('srpCode').getColDef().editable = value;
    this.agGrid.api.getColumn('multi').getColDef().editable = value;
    this.agGrid.api.getColumn('price').getColDef().editable = value;
    this.agGrid.api.getColumn('keepSrp').getColDef().editable = value;
    this.agGrid.api.getColumn('percent').getColDef().editable = value;
    this.agGrid.api.getColumn('keepPercent').getColDef().editable = value;
  }

  doOnRowEditingStarted(event){
    //this gets called when editing in grid cell mode AND editor mode!!!
    //store original values before editing
    //must deep clone object or it will reference same object and values will change
    //event.stopPropagation();
    //event.preventDefault();
    var self = event.context.componentParent;
    self.isCellEditingInProgress = true;
    self.changeType = 'keepOnly'; //default
    //self.rowDataBeforeEditing = self.cloneObject(event.node.data);

    // This is use to reset the values back to the orginal values if the change operation fails in cell edit mode
    // These need to be stored the way unformatted data is stored because the updateRowNodeWithNewData that gets called on change fail expects unformatted data
    let rowData = self.cloneObject(event.node.data);
    self.rowDataBeforeEditing = {
      itemCode: rowData.itemCode,
      isPreview: rowData.isPreview == true || rowData.isPreview == 'true' || rowData.isPreview == 'True' ? true : false,
      book: rowData.book,
      srpCode: rowData.srpCode,
      multi: Number(rowData.multi),
      price: Number(rowData.price),
      keepSrp: rowData.keepSrp == true || rowData.keepSrp == 'true' || rowData.keepSrp == 'True' ? true : false,
      percent: Number(rowData.percent),
      keepPercent: rowData.keepPercent == true || rowData.keepPercent  == 'true' || rowData.keepPercent == 'True' ? true : false,
    }
  }

  doOnRowEditingStopped(event){
    var self = event.context.componentParent;
    self.isCellEditingInProgress = false;
    // this gets called in cell editing mode when the row selection changes
    // !!! it ALSO gets called when starting a price or percent change in editor mode because loading the price history makes the row lose focus.  
    // The following IF statement keeps anything from happening while in editor mode
    if (self.isCellEditMode){
      //self.handleAppearanceOfChangeType(''); //remove highlights
      var valueInRowChanged = false;
      var missingRequiredValue = false;
      var data = event.node.data;
      
      //This is to make null equal an empty string on the test below.
      //This happens on a non-priced item. When in cell edit mode and not making a change, then leaving the row,
      //it was triggering a change because null did not equal "".
      //I could have just made the rowDataBeforeEditing.book equal "", but I didn't want to change the value that was set on the back end.
      //I just wanted to change it for the test without affecting the data.
      var bookData = self.rowDataBeforeEditing.book;
      var srpCodeData = self.rowDataBeforeEditing.srpCode;
      if (self.rowDataBeforeEditing.book === null) bookData = "";
      if (self.rowDataBeforeEditing.srpCode === null) srpCodeData = "";

      if (data.book != bookData ||
          data.srpCode != srpCodeData ||
          data.multi != self.rowDataBeforeEditing.multi ||
          data.price != self.rowDataBeforeEditing.price ||
          data.keepSrp != self.rowDataBeforeEditing.keepSrp ||
          data.percent != self.rowDataBeforeEditing.percent ||
          data.keepPercent != self.rowDataBeforeEditing.keepPercent) valueInRowChanged = true;
      if (data.multi === '' ||  
          data.price === '' || 
          data.percent === '' ) missingRequiredValue = true;
      if (missingRequiredValue) {
        self._messageService.alert(`You attempted to change Item ${data.itemCode} but you were missing a required value.  Please try again.`);
        event.node.setDataValue('multi', self.rowDataBeforeEditing.multi);
        event.node.setDataValue('price', self.rowDataBeforeEditing.price);
        event.node.setDataValue('percent', self.rowDataBeforeEditing.percent);
      }
      if (valueInRowChanged && !missingRequiredValue) {
        self.sendPricePercentOrBookChange(event.node, event.node.data, self.changeType, false);
      }
    }
  }

  //TODO - I don't know if this is right
  checkItemForReadOnly(rowData, params) {
    var self = params.context.componentParent;
    let deptCode = rowData.deptCode;
    if (!self.meatAndProduceStoreItemPriceChangesEnabledForStoreObject.value && (deptCode.indexOf('M') != -1 || deptCode.indexOf('N') != -1 || deptCode.indexOf('P') != -1)) return true;
    return false;
  }

  doOnCellFocused(params){
    //console.log(params);
    var self = params.context.componentParent;
			//cell focused gets called on grid load before a cell is focused so must check for null
			if (params.rowIndex != null) {
        var rowNode = params.api.getDisplayedRowAtIndex(params.rowIndex);
        if (rowNode){
          rowNode.setSelected(true, true);
          //if on All Items tab, make meat and produce items read only if their window is closed
          if (self.tabModel.activeTab == 'AllItems') self.isItemReadOnly = self.checkItemForReadOnly(rowNode.data, params);
          if (self.isItemReadOnly) {
            self.setEditableColumns(false);
          }
          else {
            if (!self.isGridReadOnly) self.setEditableColumns(self.isCellEditMode);
          }

          params.context.componentParent.selectedRowData = rowNode.data;
          params.context.componentParent.selectedRowNode = rowNode;
          params.context.componentParent.isKeepSrpInFooterChecked = rowNode.data.keepSrp;
          params.context.componentParent.isKeepPercentInFooterChecked = rowNode.data.keepPercent;

          params.context.componentParent.currentFocusedCell = params;
        }
			}
  }

  doOnSelectionChanged(params){
    var self = params.context.componentParent;
    var selectedRow = self.agGrid.api.getSelectedRows()[0];
    var selectedNode = self.agGrid.api.getSelectedNodes()[0];
    //console.log(selectedNode);
    if (selectedNode && !self.isMobile && self.currentSelectedNodeId != selectedNode.id) self.hideAllEditors();
    if (selectedRow) self.agGrid.api.setGridOption('pinnedBottomRowData',[selectedRow]);
    if (selectedNode) self.currentSelectedNodeId = selectedNode.id;
  }

  selectRowNodeFromNodeId(nodeId){
    var rowNode = this.agGrid.api.getRowNode(nodeId);
    if (rowNode){
      rowNode.setSelected(true, true);
      this.viewSelectedRow();
    }
  }

  //scrolls selected row into view with eyeball button
  viewSelectedRow() {
    var selectedNode = this.agGrid.api.getSelectedNodes()[0];
    var viewport = document.querySelector('.ag-body-viewport');
    viewport.scrollTop = selectedNode.rowTop;
  }
  
  resetColumns() {
    this.clearFilters();
    this.agGrid.api.resetColumnState();
    this.agGrid.api.resetColumnGroupState();
  }

  autoSizeColumns(){
    var columns = this.agGrid.api.getColumns();
    this.agGrid.api.autoSizeColumns(columns);
  }

  //!///////////////////////////////
  // Master inputs
  //////////////////////////////////

  onMasterInputModeChange() {
    this.isMasterInputMode = !this.isMasterInputMode;
    if (this.isMasterInputMode) {
      this.agGrid.api.setGridOption("headerHeight", 64);
      this.agGrid.api.setColumnsVisible(["masterChangeCheckbox"], true);
      this.agGrid.api.refreshHeader();
      this.isCellEditMode = false;
    }
    else {
      this.isMasterChangeModelValid = false; //disable apply button if hiding master inputs
      this.agGrid.api.setGridOption("headerHeight", 25);
      this.agGrid.api.setColumnsVisible(["masterChangeCheckbox"], false);
      this.agGrid.api.refreshHeader();
      //set all checkboxes to unchecked
      var rowNodes = [];
      this.agGrid.api.forEachNodeAfterFilterAndSort(function (rowNode) {
        rowNode.data.masterChangeCheckbox = false;
        rowNodes.push(rowNode);//creating an array of nodes that need to be refreshed
      });
      this.agGrid.api.refreshCells({ rowNodes: rowNodes, force: true });
      this.masterChangeModel = {};
    }
    this.sizeGrid();
  }

  setMasterChangeModel(masterChangeModel){ //called from custom header
    this.masterChangeModel = masterChangeModel;
    this.validateMasterChangeModel();
  }

  validateMasterChangeModel(){
    this.isMasterChangeModelValid = false;
    var changeType = this.masterChangeModel.changeType;
    var values = this.masterChangeModel.values;
    switch (changeType){
      case 'book':
        if ((values.book != '' && values.srpCode != '') ||
            (values.book == 'D')) this.isMasterChangeModelValid = true;
      break;
      case 'price':
        if (values.multi != '' && values.price != '') this.isMasterChangeModelValid = true;
      break;
      case 'percent':
        if (values.percent != '') this.isMasterChangeModelValid = true;
      break;
      case 'keepOnly':
        if (values.keepPercent != null || values.keepSrp != null) this.isMasterChangeModelValid = true;
      break;
    }

    //GAIR-69
    //Added by RG on 10/9/2023
    //checks to see if new values are in a valid range and highlight errors
    //as always each input must be checked to see if it exists 
    //because with ag-grid, the inputs don't exist when they are scrolled out of view

    let multiInput = <HTMLInputElement>document.getElementById('multi-master-input');
    let priceInput = <HTMLInputElement>document.getElementById('price-master-input');
    let percentInput = <HTMLInputElement>document.getElementById('percent-master-input');

    let failsRangeChecks:boolean = false;
    let rangeErrorMsg = '';

    if (multiInput) {
      multiInput.style.color = 'black';
      multiInput.style.borderColor = 'black';
    }
    if (priceInput) {
      priceInput.style.color = 'black';
      priceInput.style.borderColor = 'black';
    }
    if (percentInput) {
      percentInput.style.color = 'black';
      percentInput.style.borderColor = 'black';
    }

    // check if multi, price and percent values are in range
    if (values.multi && (values.multi < 0 || values.multi > 99)){
      if (multiInput) {
        multiInput.style.color = 'red';
        multiInput.style.borderColor = 'red';
      }
      failsRangeChecks = true;
      rangeErrorMsg += 'Multi must be between 0 and 99.<br/>';
    }
    if (values.price && (values.price < 0 || values.price > 999.99)){
      if (priceInput) {
        priceInput.style.color = 'red';
        priceInput.style.borderColor = 'red';
      }
      failsRangeChecks = true;
      rangeErrorMsg += 'Price must be between 0 and 999.99.<br/>';
    }
    if (values.percent && (values.percent < -99 || values.percent > 99)){
      if (percentInput) {
        percentInput.style.color = 'red';
        percentInput.style.borderColor = 'red';
      }
      failsRangeChecks = true;
      rangeErrorMsg += 'Percent must be between -99 and 99.<br/>';
    }

    if (failsRangeChecks){
      this.isMasterChangeModelValid = false;
      this._messageService.alert(rangeErrorMsg);
      return;
    }

  }

  onApplyMasterChangeButtonClick(){

    var self = this;

    this.numberOfItemsInMasterChange = 0;
    this.masterChangeRowArray = [];
    this.changingMultis = false;
    this.mismatchedMultis = false;
    this.mismatchedPrices = false;
    this.mismatchedPercents = false;
    this.mismatchedBooks = false;
    this.mismatchedSrpCodes = false;
    this.changingMultisConfirmed = false;
    this.mismatchedMultisConfirmed = false;
    this.mismatchedPricesConfirmed = false;
    this.mismatchedPercentsConfirmed = false;
    this.mismatchedBooksConfirmed = false;
    this.mismatchedSrpCodesConfirmed = false;
    this.deleteItemMasterConfirmed = false;

    var firstPass = true;
    var firstMulti = '';
    var firstPrice = '';
    var firstPercent = '';
    var firstBook = '';
    var firstSrpCode = '';

    var attemptingToDeleteNonPricedItem = false;
    var itemCodesForDeleteNonPricedItemMessage = [];
    // var attemptingToChangePercentOnCripDealItem = false;
    // var itemCodesForCripDealMessage = [];

    this.agGrid.api.forEachNodeAfterFilterAndSort(function (rowNode) {
      if (rowNode.data.masterChangeCheckbox) { //if row is checked
        //if this is a percent change check to see if item is on deal.
        //only add items to the masterChangeRowArray where this is NOT true.
        //Disable percent change for deal items.
        // if (self.masterChangeModel.changeType === 'percent' && rowNode.data.dealDate !== ''){
        //   attemptingToChangePercentOnCripDealItem = true;
        //   itemCodesForCripDealMessage.push(rowNode.data.itemCode);
        //   //TODO - maybe leave it checked so that the user can see which ones were skipped after the master changes are completed.
        //   rowNode.data.masterChangeCheckbox = false;
        //   self.gridOptions.api.refreshCells({ rowNodes: [rowNode], force: true });
        // }
        //these items are NOT items on deal with a percent change, so add them to the masterChangeRowArray
        //else{
          self.numberOfItemsInMasterChange++;//getting number of checked rows
          self.masterChangeRowArray.push(rowNode);//add rows to array

          if (firstPass) { //do this first time only
            firstMulti = rowNode.data.multi;
            firstPrice = rowNode.data.price;
            firstPercent = rowNode.data.percent;
            firstBook = rowNode.data.book;
            firstSrpCode = rowNode.data.srpCode;
            firstPass = false;
          }

          if (!self.changingMultis && self.masterChangeModel.values.multi != rowNode.data.multi) { 
            self.changingMultis = true;
          }
          if (!self.mismatchedMultis && firstMulti != rowNode.data.multi) self.mismatchedMultis = true; //!mismatchedMultis = stop doing this when a mismatch is found
          if (!self.mismatchedPrices && firstPrice != rowNode.data.price) self.mismatchedPrices = true;
          if (!self.mismatchedPercents && firstPercent != rowNode.data.percent) self.mismatchedPercents = true;
          if (!self.mismatchedBooks && firstBook != rowNode.data.book) self.mismatchedBooks = true;
          if (!self.mismatchedSrpCodes && firstSrpCode != rowNode.data.srpCode) self.mismatchedSrpCodes = true;

          if (rowNode.data.storeItemId == 0 && self.masterChangeModel.values.book === 'D') {
            attemptingToDeleteNonPricedItem = true;
            itemCodesForDeleteNonPricedItemMessage.push(rowNode.data.itemCode);
          }
        //}
      }
    });
    // if (attemptingToChangePercentOnCripDealItem && self.numberOfItemsInMasterChange == 0) {         
    //   var icStr = itemCodesForCripDealMessage.length > 1 ? 'Item Codes: ' : 'Item Code: ';
    //   var tiStr = itemCodesForCripDealMessage.length > 1 ? 'these items' : 'this item';
    //   self._messageService.alert('Percent changes are not allowed for items on deal. ('  + icStr + itemCodesForCripDealMessage.join(', ') + ') <br/><br/>Since ' + tiStr + ' will be skipped for this master change, that leaves no valid items for this master change.', 'Attention');
    // }
    // else if (attemptingToChangePercentOnCripDealItem && self.numberOfItemsInMasterChange > 0){
    //   var icStr = itemCodesForCripDealMessage.length > 1 ? 'Item Codes: ' : 'Item Code: ';
    //   var tiStr = itemCodesForCripDealMessage.length > 1 ? 'These items' : 'This item';
    //   this.confirmationDialogRef = this._dialog.open(ConfirmationDialogComponent, {
    //     disableClose: false,
    //     //width: '600px',
    //     data: {
    //       title: 'Attention',
    //       message: 'Percent changes are not allowed for items on deal. ('  + icStr + itemCodesForCripDealMessage.join(', ') + ') <br/><br/>' + tiStr + ' will be skipped for this master change.',
    //       confirmText: 'Proceed with master change',
    //       cancelText: 'Cancel this action!'
    //     }
    //   });
    //   this.confirmationDialogRef.afterClosed().subscribe(result => {
    //     if(result) {
    //       self.passMasterInputValidation();
    //     }
    //     this.confirmationDialogRef = null;
    //   });
    // }
    if (self.numberOfItemsInMasterChange == 0) {
      self._messageService.alert("There are no items selected for this master change.<br/><br/>Select items by checking the boxes on the item's row you wish to change.", "Attention");
    }
    else { //there are items in Master Change array.
      if (attemptingToDeleteNonPricedItem) {
        var icStr = itemCodesForDeleteNonPricedItemMessage.length > 1 ? 'Item Codes: ' : 'Item Code: ';
        self._messageService.alert('Non-priced store items cannot be deleted. (' + icStr + itemCodesForDeleteNonPricedItemMessage.join(', '), 'Attention');
      }
      else {
        self.passMasterInputValidation();
      }
    }
  }

  passMasterInputValidation() {
    var valid = false;
    if (this.masterChangeModel.changeType === 'keepOnly'){
      valid = true;
    }
    if (this.masterChangeModel.changeType === 'book'){
      if (this.masterChangeModel.values.book === '') { //has no book value
        this._messageService.alert("You must enter a master value for Book before applying.", "Attention!");
        valid = false;
      }
      else { //has book value
        if (this.masterChangeModel.values.srpCode === '' && this.masterChangeModel.values.book !== 'C' && this.masterChangeModel.values.book !== 'D') { //a blank Srp Code is only ok if Book is C
          this._messageService.alert("You must enter a master value for Srp Code before applying.", "Attention!");
          valid = false;
        }
        else if (this.masterChangeModel.values.book === 'D') {
          if (!this.deleteItemMasterConfirmed) {
            this.showDeleteItemsMasterConfirmation();
            valid = false;
          }
          else {
            valid = true;
          }
        }
        else { // book and Srp code values in the master inputs are valid, now show confirmation dialog if any mismatched values on rows
          if ((this.mismatchedBooks || this.mismatchedSrpCodes) && (!this.mismatchedBooksConfirmed && !this.mismatchedSrpCodesConfirmed)) {
            this.showMismatchedBooksOrSrpCodesConfirmation();
            valid = false;
          }
          else {
            valid = true;
          }
        }
      }
    }
    else if (this.masterChangeModel.changeType === 'price') {
      if (this.masterChangeModel.values.keepSrp !== '') { // if the master keepSrp is set to either True or False (not null)
        if (this.masterChangeModel.values.price !== '') { //if price is set to something (doesn't have to be set if keepSrp is set), but if it is, then validation needs to occur on price and multis
          if (this.masterChangeModel.values.multi === '') { //if multis are empty. If they are then this is invalid and show confirmation to set all to zero
            this.showMultiEmptyConfirmation();
            valid = false;
          }
          else { // there is a master multi value set (may have all been set to zero with confirmation above)
            if (this.changingMultis && !this.changingMultisConfirmed) {
              this.showChangingMultisConfirmation();
              valid = false;
            }
            else if (this.mismatchedMultis && !this.mismatchedMultisConfirmed) { //if multis are mismatched in the row data (and multi mismatch hasn't been confirmed yet)
              this.showMismatchedMultisConfirmation();
              valid = false;
            }
            else if (this.mismatchedPrices && !this.mismatchedPricesConfirmed) {//multis are valid.  if prices are mismatched in the row data (and price mismatch hasn't been confirmed yet)
              this.showMismatchedPricesConfirmation();
              valid = false;
            }
            else { // multis and prices are valid or confirmed
              valid = true;
            }
          }
        }
        else { //no price was set, but keepSrp was set so this is valid
          valid = true;
        }
      }
      else { // master keepSrp has not been set
        if (this.masterChangeModel.values.price !== '') { //if price is set to something then validation needs to occur on price and multis
          if (this.masterChangeModel.values.multi === '') { //if multis are empty. If they are then this is invalid and show confirmation to set all to zero
            this.showMultiEmptyConfirmation();
            valid = false;
          }
          else { // there is a master multi value set (may have all been set to zero with confirmation above)
            if (this.changingMultis && !this.changingMultisConfirmed) {
              this.showChangingMultisConfirmation();
              valid = false;
            }
            else if (this.mismatchedMultis && !this.mismatchedMultisConfirmed) { //if multis are mismatched in the row data (and multi mismatch hasn't been confirmed yet)
              this.showMismatchedMultisConfirmation();
              valid = false;
            }
            else if (this.mismatchedPrices && !this.mismatchedPricesConfirmed) {//multis are valid.  if prices are mismatched in the row data (and price mismatch hasn't been confirmed yet)
              this.showMismatchedPricesConfirmation();
              valid = false;
            }
            else { // multis and prices are valid or confirmed
              valid = true;
            }
          }
        }
        else { //master price was not set. It must be if master keepSrp is not set
          this._messageService.alert("You must enter a master value for Price before applying.", "Attention!");
          valid = false;
        }
      }
    }
    else if (this.masterChangeModel.changeType === 'percent'){
      if (this.masterChangeModel.values.keepPercent === '') { // if the master keepPercent is not set to either True or False, then percent needs to have a value
        if (this.masterChangeModel.values.percent === '') {
          this._messageService.alert("You must enter a master value for Percent before applying.", "Attention!");
          valid = false;
        }
        else {
          if (this.mismatchedPercents && !this.mismatchedPercentsConfirmed) { //if percents are mismatched in the row data (and percent mismatch hasn't been confirmed yet)
            this.showMismatchedPercentsConfirmation();
            valid = false;
          }
          else {
            valid = true;
          }
        }
      }
      else { //master keepPercent was set so this is valid
        valid = true;
      }
    }
    if (valid) this.showFinalConfirmation();
  }

  showDeleteItemsMasterConfirmation() {
    this.confirmationDialogRef = this._dialog.open(ConfirmationDialogComponent, {
      disableClose: false,
      //width: '600px',
      data: {
        title: 'Delete Items?',
        message: 'Would you like to delete the items for the selected rows?',
        confirmText: 'Yes, delete the items',
        cancelText: 'No, cancel this action!'
      }
    });
    this.confirmationDialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.deleteItemMasterConfirmed = true;
        this.passMasterInputValidation();
      }
      this.confirmationDialogRef = null;
    });
  }

  showMultiEmptyConfirmation() {
    this.confirmationDialogRef = this._dialog.open(ConfirmationDialogComponent, {
      disableClose: false,
      //width: '600px',
      data: {
        title: 'A master Multi value was not entered!',
        message: 'Would you like to continue and set all Multi values to "0" for the selected rows?',
        confirmText: 'Yes, set all to "0"',
        cancelText: 'No, cancel this action!'
      }
    });
    this.confirmationDialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.masterChangeModel.values.multi = 0;//set to zero
        this.passMasterInputValidation();
        }
      this.confirmationDialogRef = null;
    });
  }

  showMismatchedMultisConfirmation() {
    this.confirmationDialogRef = this._dialog.open(ConfirmationDialogComponent, {
      disableClose: false,
      //width: '600px',
      data: {
        title: 'Warning! Selected Multi values do not match!',
        message: `The selected items do not all have the same Multi value.<br><br>
        Would you like to continue and set all Multi values to "${this.masterChangeModel.values.multi}" for the selected items?`,
        confirmText: 'Yes, set all to "' + this.masterChangeModel.values.multi + '"',
        cancelText: 'No, cancel this action!'
      }
    });
    this.confirmationDialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.mismatchedMultisConfirmed = true;
        this.passMasterInputValidation();
      }
      else{
        this.mismatchedMultisConfirmed = false;
      }
      this.confirmationDialogRef = null;
    });
  }

  showChangingMultisConfirmation() {
    this.confirmationDialogRef = this._dialog.open(ConfirmationDialogComponent, {
      disableClose: false,
      //width: '600px',
      data: {
        title: 'Warning! You are changing the Multi value!',
        message: `Please review your selected items. You are attempting to change at least one Multi value from its original value.<br><br>
        Would you like to change the Multi value to "${this.masterChangeModel.values.multi}"?`,
        confirmText: 'Yes, use "' + this.masterChangeModel.values.multi + '" as the Multi value',
        cancelText: 'No, cancel this action!'
      }
    });
    this.confirmationDialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.changingMultisConfirmed = true;
        this.passMasterInputValidation();
      }
      else{
        this.changingMultisConfirmed = false;
      }
      this.confirmationDialogRef = null;
    });
  }

  showMismatchedPricesConfirmation() {
    this.confirmationDialogRef = this._dialog.open(ConfirmationDialogComponent, {
      disableClose: false,
      //width: '600px',
      data: {
        title: 'Warning! Current prices on selected rows do not match!',
        message: 'Would you like to continue and set all prices to "' + this.masterChangeModel.values.price + '" for the selected rows?',
        confirmText: 'Yes, set all to "' + this.masterChangeModel.values.price + '"',
        cancelText: 'No, cancel this action!'
      }
    });
    this.confirmationDialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.mismatchedPricesConfirmed = true;
        this.passMasterInputValidation();
      }
      else{
        this.mismatchedPricesConfirmed = false;
      }
      this.confirmationDialogRef = null;
    });
  }

  showMismatchedPercentsConfirmation() {
    this.confirmationDialogRef = this._dialog.open(ConfirmationDialogComponent, {
      disableClose: false,
      //width: '600px',
      data: {
        title: 'Warning! Current percents on selected rows do not match!',
        message: 'Would you like to continue and set all percents to "' + this.masterChangeModel.values.percent + '" for the selected rows?',
        confirmText: 'Yes, set all to "' + this.masterChangeModel.values.percent + '"',
        cancelText: 'No, cancel this action!'
      }
    });
    this.confirmationDialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.mismatchedPercentsConfirmed = true;
        this.passMasterInputValidation();
      }
      else{
        this.mismatchedPercentsConfirmed = false;
      }
      this.confirmationDialogRef = null;
    });
  }

  showMismatchedBooksOrSrpCodesConfirmation() {
    this.confirmationDialogRef = this._dialog.open(ConfirmationDialogComponent, {
      disableClose: false,
      //width: '600px',
      data: {
        title: 'Warning! Current books and/or SRP Codes on selected rows do not match!',
        message: 'Would you like to continue and set all to Book: "' + this.masterChangeModel.values.book + '" and SRP Code: "' + this.masterChangeModel.values.srpCode + '" for the selected rows?',
        confirmText: 'Yes, set all to Book: "' + this.masterChangeModel.values.book + '", SRP Code: "' + this.masterChangeModel.values.srpCode + '"',
        cancelText: 'No, cancel this action!'
      }
    });
    this.confirmationDialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.mismatchedBooksConfirmed = true;
        this.mismatchedSrpCodesConfirmed = true;
        this.passMasterInputValidation();
        }
      else{
        this.mismatchedBooksConfirmed = false;
        this.mismatchedSrpCodesConfirmed = false;
        }
      this.confirmationDialogRef = null;
    });
  }

  showFinalConfirmation() {
    var messageContent = '';
    let changeType = this.masterChangeModel.changeType;
    let keepSrpMessage = this.masterChangeModel.values.keepSrp != null ? '<br/>Keep Price: ' + this.masterChangeModel.values.keepSrp : '';
    let keepPercentMessage = this.masterChangeModel.values.keepPercent != null ? '<br/>Keep Percent: ' + this.masterChangeModel.values.keepPercent : '';
    switch(changeType) {
      case 'keepOnly':
        if (this.masterChangeModel.values.keepSrp != null) {
          messageContent = 'Keep Price: ' + this.masterChangeModel.values.keepSrp;
        }
        else if (this.masterChangeModel.values.keepPercent != null) {
          messageContent = 'Keep Percent: ' + this.masterChangeModel.values.keepPercent;
        }
      break;
      case 'book':
        messageContent = 'Book: ' + this.masterChangeModel.values.book + '<br/>Deal Code: ' + this.masterChangeModel.values.srpCode;
      break;
      case 'price':
        if (this.masterChangeModel.values.price > 0) {
          messageContent = 'Price: $' + this.masterChangeModel.values.price + 
          '<br/>Multi: ' + this.masterChangeModel.values.multi + keepSrpMessage + keepPercentMessage;
        }
        else{
          messageContent = 'Keep Price: ' + this.masterChangeModel.values.keepSrp;
        }
      break;
      case 'percent':
        if (this.masterChangeModel.values.percent !== '') {
          messageContent = 'Percent: ' + this.masterChangeModel.values.percent + keepSrpMessage + keepPercentMessage;
        }
        else {
          messageContent = 'Keep Percent: ' + this.masterChangeModel.values.keepPercent;
        }
      break;
    }

    let message = 'You are about to change <span class="master-items-quantity">' + this.numberOfItemsInMasterChange + ' items</span> to the following:<br/><br/>' + messageContent + '<br/><br/>Do you want to make these changes to the selected items?';

    this.confirmationDialogRef = this._dialog.open(ConfirmationDialogComponent, {
      disableClose: false,
      //width: '600px',
      data: {
        title: 'Final Confirmation',
        message: this._domSanitizer.sanitize(SecurityContext.HTML, message),
        confirmText: 'Yes, make these changes',
        cancelText: 'No, cancel this action!'
      }
    });
    this.confirmationDialogRef.afterClosed().subscribe(result => {
      if(result) {
        //save
        if (this.numberOfItemsInMasterChange >= this.numberOfItemsToTriggerMasterChangeWarning) {
          this.showWarningForLargeNumberOfItemChanges();
        }
        else {
          this.masterInputSaveCounter = 0;
          this.handleMasterInputSave();
        }
      }
      else{
        this.mismatchedBooksConfirmed = false;
        this.mismatchedSrpCodesConfirmed = false;
        }
      this.confirmationDialogRef = null;
    });
  }

  showWarningForLargeNumberOfItemChanges() {
    var message = 'You are attempting to change <span class="master-items-quantity">' + this.numberOfItemsInMasterChange + ' items</span> at one time.  This number of items exceeds the recommended amount of ' + this.numberOfItemsToTriggerMasterChangeWarning + ' items.  Please make absolutely sure that you are aware of each item change that will be made. <br/><br/> Do you want to continue to change the selected ' + this.numberOfItemsInMasterChange + ' items?';
    this.confirmationDialogRef = this._dialog.open(ConfirmationDialogComponent, {
      disableClose: false,
      //width: '600px',
      data: {
        title: 'Warning!!! Number of Items Exceeds Recommended Amount!',
        message: this._domSanitizer.sanitize(SecurityContext.HTML, message),
        confirmText: 'Yes, make these changes to the selected ' + this.numberOfItemsInMasterChange + ' items',
        cancelText: 'No, cancel this action!'
      }
    });
    this.confirmationDialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.masterInputSaveCounter = 0;
        this.handleMasterInputSave();
      }
      this.confirmationDialogRef = null;
    });
  }

  handleMasterInputSave() {
    var rowNodeToUpdate = this.masterChangeRowArray[this.masterInputSaveCounter];
    //console.log({masterChangeModel: this.masterChangeModel});
    //must clone because changing "newValues" argument in sendPricePercentOrBookChange function would also change this.masterChangeModel
    let mcm = this.cloneObject(this.masterChangeModel);
    this.sendPricePercentOrBookChange(rowNodeToUpdate, mcm.values, mcm.changeType, true);
  }

  onMasterChangesCompleted() {
    this.numberOfItemsInMasterChange = 0;
    this.masterChangeRowArray = [];
    this.mismatchedMultis = false;
    this.mismatchedPrices = false;
    this.mismatchedPercents = false;
    this.mismatchedBooks = false;
    this.mismatchedSrpCodes = false;
    this.mismatchedMultisConfirmed = false;
    this.mismatchedPricesConfirmed = false;
    this.mismatchedPercentsConfirmed = false;
    this.mismatchedBooksConfirmed = false;
    this.mismatchedSrpCodesConfirmed = false;
    this.deleteItemMasterConfirmed = false;
    //this.masterChangeModel = {}; //having this was preventing a second master change, but the Apply button was enabled
    //this.isMasterChangeModelValid = false;
  }

  showDeleteItemConfirmation(rowNodeToUpdate) { //one item - not master change
    this.confirmationDialogRef = this._dialog.open(ConfirmationDialogComponent, {
      disableClose: false,
      //width: '600px',
      data: {
        title: 'Delete Item?',
        message: 'Are you sure you would like to delete the item with itemCode: ' + rowNodeToUpdate.data.itemCode + '?',
        confirmText: 'Yes, delete the item',
        cancelText: 'No, cancel this action!'
      }
    });
    this.confirmationDialogRef.afterClosed().subscribe(result => {
      if(result) {
        rowNodeToUpdate.data.isPreview ? this.deletePreviewStoreItem(rowNodeToUpdate, false) : this.deleteStoreItem(rowNodeToUpdate, false);
      }
      else {
        if (this.isCellEditMode && rowNodeToUpdate.data.itemCode === this.rowDataBeforeEditing.itemCode) this.revertFailedCellEditingChangeValuesToPreviousValues(rowNodeToUpdate, this.rowDataBeforeEditing);
      }
      this.confirmationDialogRef = null;
    });
  }

  deletePreviewStoreItem(rowNodeToUpdate, isMasterChange?){
    this._rpmsMainframeOperationsService.deletePreviewStoreItem(rowNodeToUpdate.data.previewStoreItemId)
    .subscribe(
      (data: any) => {
        if (data.itemInInventory === false) {
          if (this.isBookEditorVisible) this._messageService.onFailure(`The item with Item Code: ${data.item?.itemCode} is not in inventory.`);
          else this._messageService.showToastBottomLeft(`The item with Item Code: ${data.item?.itemCode} is not in inventory`);
        }
        else this._messageService.onSuccess('Successfully deleted item with item code: ' + rowNodeToUpdate.data.itemCode);

        this.updateRowNodeWithNewData(rowNodeToUpdate, data);
        if (isMasterChange) {
          this.masterInputSaveCounter++;
          if (this.masterInputSaveCounter < this.masterChangeRowArray.length) {
            this.handleMasterInputSave();
          }
          else { // finished master changes
            this.onMasterChangesCompleted();
          }
        }
      },
      (response) => {
        if (isMasterChange) {
          this._messageService.onFailure('Failed at selected row ' + (this.masterInputSaveCounter + 1) + '.', response);
          this.masterInputSaveCounter = 0;
        }
        else{
          this._messageService.onFailure('Failed to delete item.', response);
          if (this.isCellEditMode && rowNodeToUpdate.data.itemCode === this.rowDataBeforeEditing.itemCode) this.updateRowNodeWithNewData(rowNodeToUpdate, this.rowDataBeforeEditing);
        }
      }
    )
  }

  deleteStoreItem(rowNodeToUpdate, isMasterChange?){
    this._rpmsMainframeOperationsService.deleteStoreItem(rowNodeToUpdate.data.storeItemId)
    .subscribe(
      (data: any) => {
        if (data.itemInInventory === false) {
          if (this.isBookEditorVisible) this._messageService.onFailure(`The item with Item Code: ${data.item?.itemCode} is not in inventory.`);
          else this._messageService.showToastBottomLeft(`The item with Item Code: ${data.item?.itemCode} is not in inventory`);
        }
        else this._messageService.onSuccess('Successfully deleted item with item code: ' + rowNodeToUpdate.data.itemCode);

        this.updateRowNodeWithNewData(rowNodeToUpdate, data);
        if (isMasterChange) {
          this.masterInputSaveCounter++;
          if (this.masterInputSaveCounter < this.masterChangeRowArray.length) {
            this.handleMasterInputSave();
          }
          else { // finished master changes
            this.onMasterChangesCompleted();
          }
        }
      },
      (response) => {
        if (isMasterChange) {
          this._messageService.onFailure('Failed at selected row ' + (this.masterInputSaveCounter + 1) + '.', response);
          this.masterInputSaveCounter = 0;
        }
        else{
          this._messageService.onFailure('Failed to delete item.', response);
          if (this.isCellEditMode && rowNodeToUpdate.data.itemCode === this.rowDataBeforeEditing.itemCode) this.updateRowNodeWithNewData(rowNodeToUpdate, this.rowDataBeforeEditing);
        }
      }
    )
  }

  updatePreviewStoreItemPricing(rowNodeToUpdate, updateModel, isMasterChange?){
    this._rpmsMainframeOperationsService.updatePreviewStoreItemPricing(rowNodeToUpdate.data.previewStoreItemId, updateModel)
    .subscribe(
      (data: any) => {
        //console.log(data);
        if (data.itemInInventory === false) {
          if (this.isPricePercentEditorVisible) this._messageService.onFailure(`The item with Item Code: ${data.item?.itemCode} is not in inventory.`);
          else this._messageService.showToastBottomLeft(`The item with Item Code: ${data.item?.itemCode} is not in inventory`);
        }
        else this._messageService.onSuccess('Successfully updated item with item code: ' + rowNodeToUpdate.data.itemCode);

        this.updateRowNodeWithNewData(rowNodeToUpdate, data);
        
        if (isMasterChange) {
          this.masterInputSaveCounter++;
          if (this.masterInputSaveCounter < this.masterChangeRowArray.length) {
            this.handleMasterInputSave();
          }
          else { // finished master changes
            this.onMasterChangesCompleted();
          }
        }
      },
      (response) => {
        if (isMasterChange) {
          this._messageService.onFailure('Failed at selected row ' + (this.masterInputSaveCounter + 1) + '.', response);
          this.masterInputSaveCounter = 0;
        }
        else{
          this._messageService.onFailure('Failed to update item.', response);
          if (this.isCellEditMode && rowNodeToUpdate.data.itemCode === this.rowDataBeforeEditing.itemCode) this.revertFailedCellEditingChangeValuesToPreviousValues(rowNodeToUpdate, this.rowDataBeforeEditing);
        }
      }
    )
  }

  updateStoreItemPricing(rowNodeToUpdate, updateModel, isMasterChange?){
    this._rpmsMainframeOperationsService.updateStoreItemPricing(rowNodeToUpdate.data.storeItemId, updateModel)
    .subscribe(
      (data: any) => {
        //console.log(data);
        if (data.itemInInventory === false) {
          if (this.isPricePercentEditorVisible) this._messageService.onFailure(`The item with Item Code: ${data.item?.itemCode} is not in inventory.`);
          else this._messageService.showToastBottomLeft(`The item with Item Code: ${data.item?.itemCode} is not in inventory`);
        }
        else this._messageService.onSuccess('Successfully updated item with item code: ' + rowNodeToUpdate.data.itemCode);
        
        this.updateRowNodeWithNewData(rowNodeToUpdate, data);
        
        if (isMasterChange) {
          this.masterInputSaveCounter++;
          if (this.masterInputSaveCounter < this.masterChangeRowArray.length) {
            this.handleMasterInputSave();
          }
          else { // finished master changes
            this.onMasterChangesCompleted();
          }
        }
      },
      (response) => {
        if (isMasterChange) {
          this._messageService.onFailure('Failed at selected row ' + (this.masterInputSaveCounter + 1) + '.', response);
          this.masterInputSaveCounter = 0;
        }
        else{
          this._messageService.onFailure('Failed to update item.', response);
          if (this.isCellEditMode && rowNodeToUpdate.data.itemCode === this.rowDataBeforeEditing.itemCode) this.revertFailedCellEditingChangeValuesToPreviousValues(rowNodeToUpdate, this.rowDataBeforeEditing);
          //make sure checkboxes in footer are set back to the row's actual data
          this.updateCheckboxesInFooter(rowNodeToUpdate);
        }
      }
    )
  }

  revertFailedCellEditingChangeValuesToPreviousValues(rowNodeToUpdate, rowDataBeforeEditing) {
    rowNodeToUpdate.data.book = rowDataBeforeEditing.book;
    rowNodeToUpdate.data.srpCode = rowDataBeforeEditing.srpCode;
    rowNodeToUpdate.data.multi = rowDataBeforeEditing.multi;
    rowNodeToUpdate.data.price = rowDataBeforeEditing.price;
    rowNodeToUpdate.data.keepSrp = rowDataBeforeEditing.keepSrp;
    rowNodeToUpdate.data.percent = rowDataBeforeEditing.percent;
    rowNodeToUpdate.data.keepPercent = rowDataBeforeEditing.keepPercent;
    this.changeType = 'keepOnly';
    //rowNodeToUpdate.data.setPriceKeepPercent = false; //should always be false
    //this.gridOptions.api.refreshCells({ rowNodes: [rowNodeToUpdate], force: true });
    //use redraw instead of refresh to make rowClass style re-render.
    //this is needed when a 'D' book delete action is canceled in cell edit mode.
    this.agGrid.api.redrawRows({ rowNodes: [rowNodeToUpdate] });
  }

  //!///////////////////////////////
  // Footer
  //////////////////////////////////

  showFooter(){
    this.isShowingFooter = true;
    this.sizeGrid();
  }

  hideFooter(){
    this.isShowingFooter = false;
    this.sizeGrid();
  }

  onKeepSrpChangeInFooter(event){
    this.isKeepPercentInFooterChecked = false;
    var updateModel = {
      multi: this.selectedRowData.multi,
      price: this.selectedRowData.price,
      keepSrp: event.checked,
    }
    this.sendPricePercentOrBookChange(this.selectedRowNode, updateModel, 'keepOnly', false);
  }

  onKeepPercentChangeInFooter(event){
    this.isKeepSrpInFooterChecked = false;
    var updateModel = {
      percent: this.selectedRowData.percent,
      keepPercent: event.checked,
    }
    this.sendPricePercentOrBookChange(this.selectedRowNode, updateModel, 'keepOnly', false);
  }

  //!///////////////////////////////
  // Grid filter
  //////////////////////////////////

  clearFilters(){
    var gridFilterModel = this.agGrid.api.getFilterModel();
    //console.log(gridFilterModel);
    this.itemFilterComponent.clearItemFilter();
    this.quickFilterText = '';
    this.agGrid.api.setGridOption('quickFilterText', this.quickFilterText);
    this._priceManagementFilterService.setQuickFilterText(this.quickFilterText);
    var deptCode = gridFilterModel.deptCode;
    var isStock = gridFilterModel.isStock;
    var isPreview = gridFilterModel.isPreview;
    this.agGrid.api.setFilterModel(null);
    var clearedFilterModel = {
      deptCode: deptCode,
      isStock: isStock,
      isPreview: isPreview,
    }
    this._priceManagementFilterService.setGridFilterModel(clearedFilterModel);
    this.agGrid.api.setFilterModel(clearedFilterModel);
  }

  onQuickFilterChange(){
    this.agGrid.api.setGridOption('quickFilterText', this.quickFilterText);
    this._priceManagementFilterService.setQuickFilterText(this.quickFilterText);
  }

  onFilterChanged(){
    var gridFilterModel = this.agGrid.api.getFilterModel();
    this._priceManagementFilterService.setGridFilterModel(gridFilterModel);
    this.isFilterApplied = this._priceManagementFilterService.testIfFilterApplied();
  }

  //!///////////////////////////////
  // Renderers
  //////////////////////////////////

  pricePercentRenderer(params){
    var self = params.context.componentParent;
    var eCell;
    var eBalloon;
    switch(params.column.colId){
      case 'price':
        eCell = self._gridService.currencyRenderer(params);
        break;
      case 'keepSrp':
        eCell = self._gridService.checkRenderer(params);
        break;
      case 'keepPercent':
        eCell = self._gridService.checkRenderer(params);
        break;
      default:
        eCell = document.createElement('span');
        var value = document.createTextNode(params.value);
        eCell.appendChild(value);
        break;
    }
    params.eGridCell.addEventListener('keyup', function (e) {
      if (e.keyCode == 13) {
        e.preventDefault();
        //e.stopPropagation();
        params.node.setSelected(true, true);
        if (!self.isCellEditMode) self.showEditor(params);
      }
    });
    // params.eGridCell.addEventListener('dblclick', function (e) {
    //     e.preventDefault();
    //     params.node.setSelected(true, true);
    //     if (!self.isCellEditMode) self.showEditor(params);
    // });

    //dblclick event was not working correctly, probably due to interference with the grid's dblclick event for editing
    //editor window was slow to open and sometimes took 3 clicks
    //so this uses a single click and e.detail which counts the number of single clicks in a short period of time
    params.eGridCell.addEventListener('click', function (e) {
      //console.log(e.detail);
      if (!self.isCellEditMode && e.detail > 1) {
        e.preventDefault();
        //e.stopPropagation();
        params.node.setSelected(true, true);
        self.showEditor(params);
      }
    });
    if (self.isMobile){ //single click on mobile
      params.eGridCell.addEventListener('click', function(e){
        e.preventDefault();
        e.stopPropagation();
        params.node.setSelected(true, true);
        if (!self.isCellEditMode) self.showEditor(params);
      });
    }
    params.eGridCell.addEventListener('mouseenter', function (e){
      if (params.eGridCell.className.indexOf('changed') > -1) {
        var field = params.column.colId;
        var fieldCapitalized = field.charAt(0).toUpperCase() + field.slice(1);
        var fieldForPreviousValue = 'old' + fieldCapitalized;
        var previousValue = fieldForPreviousValue == 'oldPrice' ? Number(params.node.data.oldPrice).toFixed(2) : params.node.data[fieldForPreviousValue];
        eBalloon = document.createElement('div');
        var value = document.createTextNode('Was: ' + previousValue);
        eBalloon.appendChild(value);
        document.body.appendChild(eBalloon);
        eBalloon.className = 'cell-balloon';
        eBalloon.id = 'cell-balloon';
        eBalloon.style.position = "absolute";
        var rect = params.eGridCell.getBoundingClientRect();
        eBalloon.style.left = rect.left + 'px';
        eBalloon.style.top = rect.top - 30 + 'px';
      }
    });
    params.eGridCell.addEventListener('mouseleave', function (e){
      var els = document.getElementsByClassName('cell-balloon');
      if (els.length > 0) {
        for (let i=0; i<els.length; i++) {
          document.body.removeChild(els[i]);
        }
      }
    });
    return eCell;
  }

  bookSrpCodeRenderer(params){
    var self = params.context.componentParent;
    var eCell;
    var eBalloon;
    eCell = document.createElement('span');
    var textValue = params.value != null ? params.value : '';
    var value = document.createTextNode(textValue);
    eCell.appendChild(value);
    params.eGridCell.addEventListener('keyup', function (e) {
      if (e.keyCode == 13) {
        e.preventDefault();
        params.node.setSelected(true, true);
        if (!self.isCellEditMode) self.showEditor(params);
      }
    });
    // params.eGridCell.addEventListener('dblclick', function (e) {
    //     e.preventDefault();
    //     params.node.setSelected(true, true);
    //     if (!self.isCellEditMode) self.showEditor(params);
    // });

    //dblclick event was not working correctly, probably due to interference with the grid's dblclick event for editing
    //editor window was slow to open and sometimes took 3 clicks
    //so this uses a single click and e.detail which counts the number of single clicks in a short period of time
    params.eGridCell.addEventListener('click', function (e) {
      //console.log(e.detail);
      if (!self.isCellEditMode && e.detail > 1) {
        e.preventDefault();
        params.node.setSelected(true, true);
        self.showEditor(params);
      }
    });
    if (self.isMobile){ //single click on mobile
      params.eGridCell.addEventListener('click', function(e){
        e.preventDefault();
        e.stopPropagation();
        params.node.setSelected(true, true);
        if (!self.isCellEditMode) self.showEditor(params);
      });
    }
    params.eGridCell.addEventListener('mouseenter', function (e){
      if (params.eGridCell.className.indexOf('changed') > -1) {
        var field = params.column.colId;
        var fieldCapitalized = field.charAt(0).toUpperCase() + field.slice(1);
        var fieldForPreviousValue = 'old' + fieldCapitalized;
        var previousValue = params.node.data[fieldForPreviousValue];
        eBalloon = document.createElement('div');
        var value = document.createTextNode('Was: ' + previousValue);
        eBalloon.appendChild(value);
        document.body.appendChild(eBalloon);
        eBalloon.className = 'cell-balloon';
        eBalloon.id = 'cell-balloon';
        eBalloon.style.position = "absolute";
        var rect = params.eGridCell.getBoundingClientRect();
        eBalloon.style.left = rect.left + 'px';
        eBalloon.style.top = rect.top - 30 + 'px';
      }
    });
    params.eGridCell.addEventListener('mouseleave', function (e){
      var els = document.getElementsByClassName('cell-balloon');
      if (els.length > 0) {
        for (let i=0; i<els.length; i++) {
          document.body.removeChild(els[i]);
        }
      }
    });
    return eCell;
  }

  itemListRenderer(params){
    var self = params.context.componentParent;
    var eCell;
    eCell = document.createElement('span');
    var itemListsString = '';
    if (params.data != null && params.data.hasOwnProperty('itemLists')) {
      for (var i = 0; i < params.data.itemLists.length; i++) {
        itemListsString += params.data.itemLists[i].fullName + ', ';
      }
      itemListsString = itemListsString.slice(0, -2);//remove last comma and space
    }
    var eLabel = document.createTextNode(itemListsString);
    eCell.appendChild(eLabel);
    params.eGridCell.addEventListener('keyup', function (e) {
      if (e.keyCode == 13) {
        e.preventDefault();
        // TODO - make sure set selected calls onCellFocused else setFocusedCell
        params.node.setSelected(true, true);
        self.showItemListEditor(params);
        self._changeDetectorRef.detectChanges();
      }
    });
    // params.eGridCell.addEventListener('dblclick', function (e) {
    //     e.preventDefault();
    //     params.node.setSelected(true, true);
    //     self.showItemListEditor(params);
    // });

    //dblclick event was not working correctly, probably due to interference with the grid's dblclick event for editing
    //editor window was slow to open and sometimes took 3 clicks
    //so this uses a single click and e.detail which counts the number of single clicks in a short period of time
    params.eGridCell.addEventListener('click', function (e) {
      //console.log(e.detail);
      if (e.detail > 1) {
        e.preventDefault();
        params.node.setSelected(true, true);
        self.showItemListEditor(params);
        self._changeDetectorRef.detectChanges();
      }
    });
    if (self.isMobile){ //single click on mobile
      params.eGridCell.addEventListener('click', function(e){
        e.preventDefault();
        e.stopPropagation();
        params.node.setSelected(true, true);
        self.showItemListEditor(params);
        self._changeDetectorRef.detectChanges();
      });
    }
    return eCell;
  }

  //!///////////////////////////////
  // Show editors
  //////////////////////////////////
  
  showEditor(params){
    this.hideAllEditors();
    switch(params.colDef.field){
      case 'book':  
        this.changeType = 'book'; 
        this.setPricePercentEditorVisible(false);
        this.setBookEditorVisible(true);
        break;
      case 'srpCode': 
        this.changeType = 'book'; 
        this.setPricePercentEditorVisible(false);
        this.setBookEditorVisible(true);
        break;
      case 'multi': 
        this.changeType = 'price'; 
        this.setPricePercentEditorVisible(true);
        this.setBookEditorVisible(false);
        break;
      case 'price': 
        this.changeType = 'price'; 
        this.setPricePercentEditorVisible(true);
        this.setBookEditorVisible(false);
        break;
      case 'keepSrp': 
        this.changeType = 'price'; 
        this.setPricePercentEditorVisible(true);
        this.setBookEditorVisible(false);
        break;
      case 'percent': 
        this.changeType = 'percent'; 
        this.setPricePercentEditorVisible(true);
        this.setBookEditorVisible(false);
        break;
      case 'keepPercent': 
        this.changeType = 'percent'; 
        this.setPricePercentEditorVisible(true);
        this.setBookEditorVisible(false);
        break;
    }
    this.rowNodeToUpdate = params.node;
  }

  showShelfTagEditor(){
    this.hideAllEditors();
    this.rowNodeToUpdate = this.selectedRowNode;
    this.setShelfTagEditorVisible(true);
  }

  showTprTagEditor(){
    this.hideAllEditors();
    this.rowNodeToUpdate = this.selectedRowNode;
    this.setTprTagEditorVisible(true);
  }

  showItemListEditor(){
    //this._spinnerService.handleProgressSpinnerVisibility('show', 'Opening Editor...');
    //this._changeDetectorRef.detectChanges();
    this.hideAllEditors();
    this.rowNodeToUpdate = this.selectedRowNode;
    this.setItemListEditorVisible(true);
  }

  showControlledStorePricing(){
    this.hideAllEditors();
    this.rowNodeToUpdate = this.selectedRowNode;
    this.setControlledStorePricingVisible(true);
  }

  //!///////////////////////////////
  // Editors visibility
  //////////////////////////////////

  setPricePercentEditorVisible(booleanValue){
    this.isPricePercentEditorVisible = booleanValue;
  }

  setBookEditorVisible(booleanValue){
    this.isBookEditorVisible = booleanValue;
  }

  setShelfTagEditorVisible(booleanValue){
    this.isShelfTagEditorVisible = booleanValue;
  }

  setTprTagEditorVisible(booleanValue){
    this.isTprTagEditorVisible = booleanValue;
  }

  setItemListEditorVisible(booleanValue){
    this.isItemListEditorVisible = booleanValue;
  }

  setControlledStorePricingVisible(booleanValue){
    this.isControlledStorePricingVisible = booleanValue;
  }

  setControlledStorePricingColumnVisible(booleanValue){
    this.shouldControlledStorePricingBeVisible = booleanValue;
    this.agGrid.api.setColumnsVisible(["controlledStoresPricing"], booleanValue)
  }

  hideAllEditors(){
    this.setPricePercentEditorVisible(false);
    this.setBookEditorVisible(false);
    this.setShelfTagEditorVisible(false);
    this.setTprTagEditorVisible(false);
    this.setItemListEditorVisible(false);
    this.setControlledStorePricingVisible(false);
    }

  setItemListEditorFilterValue(str) {
    this.itemListEditorFilterValue = str;
  }

  //!///////////////////////////////
  // Events from editors
  //////////////////////////////////

  //Event Emitters can only pass one argument
  //so parameters need to be passed as an object

  //obj = { rowNode: this.rowNodeToUpdate, newValues: newValues, changeType: this.changeType }
  onPricePercentOrBookChangeFromEditor(obj){
    this.sendPricePercentOrBookChange(obj.rowNode, obj.newValues, obj.changeType, false);
  }

  //obj = { rowNode: this.rowNodeToUpdate, quantity: this.shelfTagQty }
  onShelfTagChangeFromEditor(obj){
    //Users with hasReadOnlyRestriction set to true should not even be allowed to invoke this action because it is hidden or disabled in the UI.
    //This is for extra protection. The back end will also be protected.
    if (this.myUser.hasReadOnlyRestriction){
      this._messageService.alertReadOnlyRestriction();
      return;
    }
    
    var isPreviewTagChange = obj.rowNode.data.isPreview ? true : false;
    var storeItemId = obj.rowNode.data.storeItemId;
    var shelfTagModel = {
      quantity: obj.quantity,
      storeItemId: storeItemId
    };
    if (obj.quantity > 0) { //if updating quantity
      this._rpmsMainframeOperationsService.updateStoreItemShelfTagState(storeItemId, shelfTagModel)
        .subscribe(
          (data) => {
            this._messageService.onSuccess("Successfully updated shelf tag state.");
            this.updateRowNodeWithNewData(obj.rowNode, data, isPreviewTagChange);
            this.isShelfTagEditorVisible = false;
          },
          (response) => {
            this._messageService.onFailure("Failed to update shelf tag state.", response);
          }
        )
    }
    else { //if deleting all shelf tag requests for this item
      this._rpmsMainframeOperationsService.deleteStoreItemShelfTag(storeItemId)
        .subscribe(
          (data) => {
            this._messageService.onSuccess("Successfully updated shelf tag state.");
            this.updateRowNodeWithNewData(obj.rowNode, data, isPreviewTagChange);
            this.isShelfTagEditorVisible = false;
          },
          (response) => {
            this._messageService.onFailure("Failed to update shelf tag state.", response);
          }
        )
    }
  }

  //obj = { rowNode: this.rowNodeToUpdate, tprTagModel: { tprBook: this.selectedBook, tprType: this.tprType }}
  onTprTagChangeFromEditor(obj){
    //Users with hasReadOnlyRestriction set to true should not even be allowed to invoke this action because it is hidden or disabled in the UI.
    //This is for extra protection. The back end will also be protected.
    if (this.myUser.hasReadOnlyRestriction){
      this._messageService.alertReadOnlyRestriction();
      return;
    }

    var isPreviewTagChange = obj.rowNode.data.isPreview ? true : false;
    var storeItemId = obj.rowNode.data.storeItemId;
    var tprModel = {
      book: String(obj.tprTagModel.tprBook),
      storeItemId: storeItemId
    };

    if (obj.tprTagModel.tprType == 'always') {
      this._rpmsMainframeOperationsService.updateStoreItemTprTagStateToAlways(storeItemId, tprModel)
        .subscribe(
          (data) => {
            this._messageService.onSuccess("Successfully updated Compare At Tag state.");
            this.updateRowNodeWithNewData(obj.rowNode, data, isPreviewTagChange);
            this.isTprTagEditorVisible = false;
          },
          (response) => {
            this._messageService.onFailure("Failed to update Compare At Tag state.", response);
          }
        )
    }
    else if (obj.tprTagModel.tprType == 'once') {
      this._rpmsMainframeOperationsService.updateStoreItemTprTagStateToOnce(storeItemId, tprModel)
        .subscribe(
          (data) => {
            this._messageService.onSuccess("Successfully updated Compare At Tag state.");
            this.updateRowNodeWithNewData(obj.rowNode, data, isPreviewTagChange);
            this.isTprTagEditorVisible = false;
          },
          (response) => {
            this._messageService.onFailure("Failed to update Compare At Tag state.", response);
          }
        )
    }
    else {
      this._rpmsMainframeOperationsService.deleteStoreItemTprTag(storeItemId)
        .subscribe(
          (data) => {
            this._messageService.onSuccess("Successfully updated Compare At Tag state.");
            this.updateRowNodeWithNewData(obj.rowNode, data, isPreviewTagChange);
            this.isTprTagEditorVisible = false;
          },
          (response) => {
            this._messageService.onFailure("Failed to update Compare At Tag state.", response);
          }
        )
    }
  }

  //obj = { rowNode: this.rowNodeToUpdate, currentItemListId: currentItemListId, itemListsForCurrentItem: array of item lists, shouldReloadItemLists: boolean }
  updateItemWithUpdatedItemListData(obj){

    //adding or removing one item list from one item
    if (!obj.shouldReloadItemLists) {
      let rowNode = this.agGrid.api.getRowNode(obj.rowNode.id);
      rowNode.setDataValue('itemLists', obj.itemListsForCurrentItem);
      this._itemListService.getItemListById(obj.currentItemListId)
        .subscribe(
          (data: any) => {
            let itemListsClone = [];
            for (let i=0; i<this.itemLists.length; i++) {
              if (this.itemLists[i].itemListId == data.itemListId) {
                this.itemLists[i] = data;
              }
              itemListsClone.push(this.itemLists[i]);
            }
            this.itemLists = itemListsClone;
            //this._changeDetectorRef.detectChanges();
          },
          (error) => {
            this._messageService.onFailure("Failed to get Item List by ID.", error);
          }
        )
    }
    else {
      //if remove all items from item list, creating, editing, or deleting item list
      this._itemListService.getActiveSharedItemListsLite()
      .subscribe(
        (data) => {
          this.itemLists = data;
          this.agGrid.api.forEachNode( (rowNode) => {
            rowNode.setDataValue('itemLists', this.getItemListsFromItemCode(rowNode.data.itemCode));
          });
        },
        (response) => {
          this._messageService.onFailure('Failed to get item lists.', response);
        }
      )
    }
  }

  updateRowNodeWithNewData(rowNode, newData, isPreviewTagChange?){
    //newData is UNFORMATTED
    let isUserChange = rowNode !== null; //sync changes will not have rowNode argument
    let rowNodeToUpdate;
    let previewRowNodeToUpdate;
    let correspondingStoreItemFromHashTable;
    let correspondingPreviewItemFromHashTable;
    let itemCode = newData?.item?.itemCode ? newData?.item?.itemCode : newData?.itemCode;
    // If it's a preview tag change, the data passed in will have isPreview set to false even if the tag order was placed on a preview item

    // If it's a store item change though, and there is a preview item that is associated with it, then we should update both in the GRID's data (formatted data) not the hash table data which is unformatted
    // The formatSingleRowData function is what puts the associated storeItem data into the preview items data that is used in the grid
    // Finally, if the updated row node is the selected row node, then we need to update the footer, which uses selectedRowData in the view, so that variable needs to be updated
    // selectedRowData usually only gets set when a cell is focused and would not automatically occur after a update when the row is already selected
    
    // FIRST update the hash table
    // The updateAllItemsHashTable uses the isPreview flag on newData to determine whether to update the 'S' (store item) or the 'P' (preview item)
    // if this is a store item change, then the store item gets updated in the hash table
    // if this is a preview item change, then the preview item gets updated in the hash table
    // if this is a preview item TAG change, then the STORE ITEM gets updated in the hash table because isPreview is set to false
    // if this is a preview item TAG change, We only need to update the store item because the preview's tag information in the footer comes from the corresponding store item
    // if this item doesn't exist in the hash table, it will create it
    this.updateAllItemsHashTable(newData);

    // SECOND get the store item and preview store item from hash table that have this change's itemCode.  
    // If it's a store item change and there's an associated preview item, then we will want to update it in the grid also, so that the properties it gets from the store item are the updated ones
    // If there isn't an associated preview item, then correspondingPreviewItemFromHashTable will be undefined
    // If it's a preview item change, there should always be an associated store item (although we've seen times when the data is bad from the AS400 and it doesn't exist)
    correspondingStoreItemFromHashTable = this.allItemsHashTable['S' + itemCode];
    correspondingPreviewItemFromHashTable = this.allItemsHashTable['P' + itemCode];

    // THIRD get the store item row node and preview store item row node from grid.
    // If either of these don't exist, then they will be undefined
    // If the update is not a preview change and the rowNode is undefined, it will be added in the next step
    // If the update IS a preview change and the previewRowNode is undefined, it will be added in the next step
    // Only one of those can happen because isPreview will either be true or false
    rowNodeToUpdate = this.agGrid.api.getRowNode('S' + itemCode);
    previewRowNodeToUpdate = this.agGrid.api.getRowNode('P' + itemCode);

    //if the update is a store item and it doesn't exist in the grid, then we need to add it
    if (!newData.isPreview && !rowNodeToUpdate) { 
      if (this.isGridDataLoaded) {
        //the data needs to be formatting before adding to grid
        let formattedNewData = this.formatSingleRowData(correspondingStoreItemFromHashTable);
        this.agGrid.api.applyTransaction({ add: [formattedNewData] });
        this.agGrid.api.applyColumnState({
          state: [
            { 
              colId: 'itemCode', 
              sort: 'asc' 
            }
          ]
        })
      }
    }
    //if the update is a preview item and it doesn't exist in the grid, then we need to add it
    else if (newData.isPreview && !previewRowNodeToUpdate) { 
      if (this.isGridDataLoaded) {
        //the data needs to be formatting before adding to grid
        let formattedNewData = this.formatSingleRowData(correspondingPreviewItemFromHashTable);
        this.agGrid.api.applyTransaction({ add: [formattedNewData] });
        this.agGrid.api.applyColumnState({
          state: [
            { 
              colId: 'itemCode', 
              sort: 'asc' 
            }
          ]
        })
      }
    }
    //this item is already in the grid
    else { 
      //update grid
      if (this.isGridDataLoaded) {
        //if store item row node exists, update it with the latest corresponding data from the hash table
        if (rowNodeToUpdate){
          var itemListsData = rowNodeToUpdate.data.itemLists;
          //console.log(itemListsData);
          //set data by formatting data from hash table that corresponds to this change
          if (correspondingStoreItemFromHashTable) rowNodeToUpdate.setData(this.formatSingleRowData(correspondingStoreItemFromHashTable));
          //make sure to set the selector checkbox to false (will uncheck master changes as they get updated)
          rowNodeToUpdate.data.masterChangeCheckbox = false;
          //set the item lists back in
          rowNodeToUpdate.setDataValue('itemLists', itemListsData);
          //force row to refresh
          this.agGrid.api.refreshCells({ rowNodes: [rowNodeToUpdate], force: true });
          this.updateCheckboxesInFooter(rowNodeToUpdate);
          //deselect then select row to force doOnSelectionChanged to fire which updates the pinned row
          //trying to set pinned row data directly here causes errors due to grid refresh timing
          //this.gridOptions.api.deselectAll(); // commented out 10-4-2022
          //rowNodeToUpdate.setSelected(true, true); // commented out 10-4-2022
          this.agGrid.api.setGridOption('pinnedBottomRowData',[rowNodeToUpdate.data]);
          //update footer if this is the selected row
          if (correspondingStoreItemFromHashTable && !this.isEmpty(this.selectedRowNode) && this.selectedRowNode.data.itemCode == rowNodeToUpdate.data.itemCode) this.selectedRowData = this.formatSingleRowData(correspondingStoreItemFromHashTable);
          //only do this on a user change, not signalR change
          if (isUserChange) this._priceManagementDataService.setUpdatedRowNode(rowNodeToUpdate);
        }
        //if preview item row node exists, update it with the latest corresponding data from the hash table
        if (previewRowNodeToUpdate) {
          var itemListsData = previewRowNodeToUpdate.data.itemLists;
          //set data by formatting data from hash table that corresponds to this change
          if (correspondingPreviewItemFromHashTable) previewRowNodeToUpdate.setData(this.formatSingleRowData(correspondingPreviewItemFromHashTable));
          //make sure to set the selector checkbox to false (will uncheck master changes as they get updated)
          previewRowNodeToUpdate.data.masterChangeCheckbox = false;
          //set the item lists back in
          previewRowNodeToUpdate.setDataValue('itemLists', itemListsData);
          //force row to refresh
          this.agGrid.api.refreshCells({ rowNodes: [previewRowNodeToUpdate], force: true });
          this.updateCheckboxesInFooter(previewRowNodeToUpdate);
          //deselect then select row to force doOnSelectionChanged to fire which updates the pinned row
          //trying to set pinned row data directly here causes errors due to grid refresh timing
          this.agGrid.api.deselectAll();
          previewRowNodeToUpdate.setSelected(true, true);
          //update footer if this is the selected row
          if (correspondingPreviewItemFromHashTable && !this.isEmpty(this.selectedRowNode) && this.selectedRowNode.data.itemCode == previewRowNodeToUpdate.data.itemCode) this.selectedRowData = this.formatSingleRowData(correspondingPreviewItemFromHashTable);
          //only do this on a user change, not signalR change
          if (isUserChange) this._priceManagementDataService.setUpdatedRowNode(previewRowNodeToUpdate);
        }
      }
    }
  }

  updateCheckboxesInFooter(rowNode) {
    //update footer if the rowNode is the selected row
    var selectedNode = this.agGrid.api.getSelectedNodes()[0];
    if (typeof selectedNode !== 'undefined' && typeof rowNode !== 'undefined') {
      if (selectedNode.data.isPreview){
        if (selectedNode.data.previewStoreItemId === rowNode.data.previewStoreItemId) {
          this.isKeepSrpInFooterChecked = rowNode.data.keepSrp;
          this.isKeepPercentInFooterChecked = rowNode.data.keepPercent;
        }
      }
      else {
        if (selectedNode.data.storeItemId === rowNode.data.storeItemId) {
          this.isKeepSrpInFooterChecked = rowNode.data.keepSrp;
          this.isKeepPercentInFooterChecked = rowNode.data.keepPercent;
        }
      }
    }
  }

  //!///////////////////////////////
  // All price, percent and book changes go through here
  //////////////////////////////////
  
  sendPricePercentOrBookChange(rowNode, newValues, changeType, isMasterChange){

    //Users with hasReadOnlyRestriction set to true should not even be allowed to invoke this action because it is hidden or disabled in the UI.
    //This is for extra protection. The back end will also be protected.
    if (this.myUser.hasReadOnlyRestriction){
      this._messageService.alertReadOnlyRestriction();
      return;
    }

    // console.log({masterChangeModel: this.masterChangeModel});
    // console.log({newValues: newValues});
    // console.log({changeType: changeType});
    // console.log({rowNode: rowNode});

    //boolean values from master inputs are being set as strings, so I convert them here
    if (newValues?.keepSrp == "true") newValues.keepSrp = true;
    if (newValues?.keepSrp == "false") newValues.keepSrp = false;

    if (newValues?.keepPercent == "true") newValues.keepPercent = true;
    if (newValues?.keepPercent == "false") newValues.keepPercent = false;

    //Checks to make sure a book change (other than C or D) has srpCode set
    if (changeType === 'book' && newValues.book !== 'C' && newValues.book !== 'D' && newValues.srpCode == ''){
      this._messageService.showAlertThenPeformActionOnClose(
        'Please try again.<br/><br/>A Book value other than "C" or "D" must also have a Deal Code value.', 
        () => this.revertFailedCellEditingChangeValuesToPreviousValues(rowNode, this.rowDataBeforeEditing)
      );
      return;
    }

    //Checks to make sure a book change has the book set to something other than C if the srpCode is set
    if (changeType === 'book' && newValues.book === 'C' && newValues.srpCode != ''){
      this._messageService.showAlertThenPeformActionOnClose(
        'Please try again.<br/><br/>A Deal Code value requires the Book value to be set to something other than "C".', 
        () => this.revertFailedCellEditingChangeValuesToPreviousValues(rowNode, this.rowDataBeforeEditing)
      );
      return;
    }

    //GAIR-69
    //Added by RG on 10/9/2023
    //Doesn't let any price change go to back end without first checking value ranges
    let failsRangeChecks:boolean = false;
    let rangeErrorMsg = '';
    // check if multi, price and percent values are in range
    if (newValues && (newValues.multi < 0 || newValues.multi > 99)){
      failsRangeChecks = true;
      rangeErrorMsg += 'Multi must be between 0 and 99.<br/>';
    }
    if (newValues && (newValues.price < 0 || newValues.price > 999.99)){
      failsRangeChecks = true;
      rangeErrorMsg += 'Price must be between 0 and 999.99.<br/>';
    }
    if (newValues && (newValues.percent < -99 || newValues.percent > 99)){
      failsRangeChecks = true;
      rangeErrorMsg += 'Percent must be between -99 and 99.<br/>';
    }

    if (failsRangeChecks){
      this._messageService.alert(rangeErrorMsg);
      if (this.isCellEditMode) this.revertFailedCellEditingChangeValuesToPreviousValues(rowNode, this.rowDataBeforeEditing);
      return;
    }
    //end GAIR-69
    
    // if book is set to C then treat this as a price change
    // this would happen if the user manually sets the book to C.
    if (newValues.book === 'C' && changeType === 'book') changeType = 'price';

    //GAIR 118 block B
    const CHANGED = {
      none: 0,
      book: 1,
      srpCode: 2,
      multi: 4,
      price: 8,
      percent: 16,
      keepPrice: 32,
      keepPercent: 64
    };

    let changeFlags = 0;
    let currentRowData = this.isCellEditMode ? this.rowDataBeforeEditing : rowNode?.data;
    
    if (changeType == 'book'){
      if (currentRowData?.book != newValues?.book) changeFlags += CHANGED.book;
      if (currentRowData?.srpCode != newValues?.srpCode) changeFlags += CHANGED.srpCode;
    }
    else if (changeType == 'price') {
      if (currentRowData?.multi != newValues?.multi) changeFlags += CHANGED.multi;
      if (currentRowData?.price != newValues?.price) changeFlags += CHANGED.price;
    }
    else if (changeType == 'percent') {
      if (currentRowData?.percent != newValues?.percent) changeFlags += CHANGED.percent;
    }

    //newValues coming from a bookEditor change will not have keepSrp or keepPercent values, so set them to what they currently are
    if (newValues.keepSrp == undefined || newValues.keepSrp == null) newValues.keepSrp = currentRowData?.keepSrp;
    if (newValues.keepPercent == undefined || newValues.keepPercent == null) newValues.keepPercent = currentRowData?.keepPercent;

    //detect "keep" changes regardless of changeType. So this also includes "keepOnly" changes
    if (currentRowData?.keepSrp != newValues?.keepSrp) changeFlags += CHANGED.keepPrice;
    if (currentRowData?.keepPercent != newValues?.keepPercent) changeFlags += CHANGED.keepPercent;
    //end GAIR-118 block B

    // //GAIR-118 block A
    // //Only run this if NOT a test store
    // if (!this.testStoreNumbers.includes(this.store.storeNumber)) {
    //   // if this is a percent change for an item on deal, calculate the price and send it as a price change
    //   if (changeType === 'percent' && rowNode.data.dealDate != '') {
    //     changeType = 'price';
    //     newValues.price = this.calculatePriceFromPercent(rowNode, newValues, changeType, isMasterChange);
    //   } 
    // }
    // //end GAIR-118 block A

    //GAIR-140 block A
    //Only run this if NOT a test store and depending on whether Condition2 and Condition6 are enabled
    let isPercentChangeType = changeType === 'percent';
    let isItemOnDeal = rowNode.data.dealDate != '';
    let hasPercentChangedAndKeepPriceChanged = (changeFlags & 16) === 16 && (changeFlags & 32) === 32;
    let hasPercentChangedOnly = changeFlags === 16;
    let isCondition2Enabled = this.config.isCondition2Enabled;
    let isCondition6Enabled = this.config.isCondition6Enabled;
    // if this is a percent change for an item on deal, calculate the price and send it as a price change
    if (!this.testStoreNumbers.includes(this.store.storeNumber) &&
        isPercentChangeType && isItemOnDeal) {
      if ((hasPercentChangedAndKeepPriceChanged && !isCondition2Enabled) ||
          (hasPercentChangedOnly && !isCondition6Enabled)) {
        changeType = 'price';
        newValues.price = this.calculatePriceFromPercent(rowNode, newValues, changeType, isMasterChange);
      } 
    }
    //end GAIR-140 block A

    //GAIR-140 block C - removed if statement to only run for test stores. Now runs for ALL stores
    //if a user changes something other than a checkbox and then changes it back to the original value and then checks a keep,
    //this will handle that because the changeFlags are set based on the value of the fields and not a change event on the fields
    if (changeFlags === 32 || changeFlags === 64 || changeFlags === 96) changeType = 'keepOnly';

    //don't send anything if nothing changed (could show message if needed, but JL thinks not)
    if (changeFlags === 0) {
      //this._messageService.showToastTopRight(`No values changed for item code: ${rowNode.data.itemCode}`);
      return;
    }
    //end GAIR-140 block C

    // check if any pre-priced items are over the maximum
    if (rowNode.data.offPack.indexOf('PP') == 0 && !newValues.passesPrePricedValidation) {
      this.checkMaximumForPrePricedItem(rowNode, newValues, changeType, isMasterChange)
      //sendPricePercentOrBookChange will be called again from checkMaximumForPrePricedItem and bypass this if statement so return here
      return; 
    }

    //remove this property if it exists so that each pre-priced item in a master change will be checked
    if (newValues.hasOwnProperty('passesPrePricedValidation')) delete newValues.passesPrePricedValidation;

    //if a new value is empty, use what the rowNode already has
    var R2MUpdateStoreItemPricingBindingModel = {
			itemId: rowNode.data.itemId,
			storeId: this.store.storeId,
			storeItemId: rowNode.data.storeItemId,
			previewStoreItemId: rowNode.data.previewStoreItemId,
			changeType: changeType,
      changeFlags: changeFlags,
      book: newValues.book ? newValues.book : rowNode.data.book,
      srpCode: newValues.srpCode ? newValues.srpCode : rowNode.data.srpCode,
      multi: newValues.multi ? newValues.multi : rowNode.data.multi,
      price: newValues.price ? Number(newValues.price).toFixed(2) : Number(rowNode.data.price).toFixed(2),
      keepSrp: newValues.keepSrp,
      percent: newValues.percent ? newValues.percent : rowNode.data.percent,
      keepPercent: newValues.keepPercent,
    }

    // //GAIR-118 block D
    // //Only run this if NOT a test store
    // if (!this.testStoreNumbers.includes(this.store.storeNumber)) {
    //   // if it is a keepOnly change and has a book value other than "C" then send it as a price change
    //   // even if that keepOnly change was due to a keepPercent change
    //   if (changeType === 'keepOnly' && rowNode.data.book != 'C') {
    //     R2MUpdateStoreItemPricingBindingModel.changeType = 'price';
    //     R2MUpdateStoreItemPricingBindingModel.book = 'C';
    //     R2MUpdateStoreItemPricingBindingModel.srpCode = '';
    //   }
    // }
    // //end GAIR-118 block D

    // GAIR-140 block D
    //Only run this if NOT a test store and depending on whether Condition7 and Condition8 are enabled
    let isKeepOnlyChangeType = changeType === 'keepOnly';
    let hasKeepPriceChanged = (changeFlags & 32) === 32;
    let hasKeepPercentChanged = (changeFlags & 64) === 64;
    let isCondition7Enabled = this.config.isCondition7Enabled;
    let isCondition8Enabled = this.config.isCondition8Enabled;
    let isBookSet = rowNode.data.book != 'C';
    if (!this.testStoreNumbers.includes(this.store.storeNumber) &&
        isKeepOnlyChangeType && isBookSet) {
      if ((hasKeepPriceChanged && !isCondition7Enabled) ||
          (hasKeepPercentChanged && !isCondition8Enabled) ||
          (hasKeepPriceChanged && hasKeepPercentChanged &&
          !(isCondition7Enabled || isCondition8Enabled))) {
        // if it is a keepOnly change and has a book value other than "C" then send it as a price change
        // even if that keepOnly change was due to a keepPercent change
        R2MUpdateStoreItemPricingBindingModel.changeType = 'price';
        R2MUpdateStoreItemPricingBindingModel.book = 'C';
        R2MUpdateStoreItemPricingBindingModel.srpCode = '';
      }
    }
    //end GAIR-140 block D
    
    // for all price or percent changes, make the book value C
    if (changeType === 'price' || changeType === 'percent') { 
      R2MUpdateStoreItemPricingBindingModel.book = 'C';
      R2MUpdateStoreItemPricingBindingModel.srpCode = '';
    }

    //commented out in GAIR-118
    //if (R2MUpdateStoreItemPricingBindingModel.keepSrp == null) delete R2MUpdateStoreItemPricingBindingModel.keepSrp;
    //if (R2MUpdateStoreItemPricingBindingModel.keepPercent == null) delete R2MUpdateStoreItemPricingBindingModel.keepPercent;

    if (changeType === 'book' && newValues.book === 'D'){ // Deleting
      if (isMasterChange && this.deleteItemMasterConfirmed) { // if master change, delete confirmation should have already taken place
        rowNode.data.isPreview ? this.deletePreviewStoreItem(rowNode, true) : this.deleteStoreItem(rowNode, true);
      }
      else{
        this.showDeleteItemConfirmation(rowNode);
      }
    }
    else{
      console.log(this.config);
      console.log(R2MUpdateStoreItemPricingBindingModel);
      //comment the next line for testing without sending the change.
      rowNode.data.isPreview ? this.updatePreviewStoreItemPricing(rowNode, R2MUpdateStoreItemPricingBindingModel, isMasterChange) : this.updateStoreItemPricing(rowNode, R2MUpdateStoreItemPricingBindingModel, isMasterChange);
    }
  }

  calculatePriceFromPercent(rowNode, newValues, changeType, isMasterChange) {
    var currentUnitCost = rowNode.data.deliveredUnitCost;    
    var percent = newValues.percent;
    var multi = rowNode.data.multi;
        multi = multi == 0 ? 1 : multi;
    var price = Number(currentUnitCost / (1 - (percent / 100)) * multi); //SW10-490.0049 added in Chris's formula?? is it needed?
    var tempPrice = price / multi;
    return Math.round(price * 100)/100;
  }

  checkMaximumForPrePricedItem(rowNode, newValues, changeType, isMasterChange){
    if (changeType == 'book') { //if book change then don't check and just send it
      newValues.passesPrePricedValidation = true;
      this.sendPricePercentOrBookChange(rowNode, newValues, changeType, isMasterChange)
    }
    else {
      var currentUnitCost = rowNode.data.deliveredUnitCost;    
      var priceMaximum = rowNode.data.current1;
      var multi;
      var price;
      var tempPrice;
      if (isMasterChange){ //new values only contain what's needed for change type
        switch(changeType){
          case 'price':
            multi = Number(newValues.multi);
            multi = multi == 0 ? 1 : multi;
            price = Number(newValues.price);
            tempPrice = price / multi;
            break;
          case 'percent':
            multi = Number(rowNode.data.multi); //newValues will not contain price values
            multi = multi == 0 ? 1 : multi;
            price = Number(currentUnitCost / (1 - (newValues.percent / 100)) * multi);
            tempPrice = price / multi;
            break;
        }
      }
      else { //editor or cell editor change
        switch(changeType){
          case 'price':    
            multi = Number(newValues.multi);
            multi = multi == 0 ? 1 : multi;
            price = Number(newValues.price);
            tempPrice = price / multi;
            break;
          case 'percent':
            multi = Number(newValues.multi);
            multi = multi == 0 ? 1 : multi;
            price = Number(currentUnitCost / (1 - (newValues.percent / 100)) * multi);
            tempPrice = price / multi;
            break;
        }
      }
      if (tempPrice > priceMaximum) {
        price = priceMaximum * multi;
        tempPrice = priceMaximum;
        var maxPercent = Math.floor(((priceMaximum - currentUnitCost) / priceMaximum) * 100);
        this.showPrePriceMaximumConfirmation(price, priceMaximum, multi, maxPercent, rowNode, newValues, changeType, isMasterChange);
      }
      else {
        newValues.passesPrePricedValidation = true;
        this.sendPricePercentOrBookChange(rowNode, newValues, changeType, isMasterChange)
      }
    }
  }


  // checkMaximumForPrePricedItem(rowNode, newValues, changeType, isMasterChange){
  //   var currentUnitCost = rowNode.data.deliveredUnitCost;    
  //   var priceMaximum = rowNode.data.current1;
  //   var multi = Number(newValues.multi);
  //       multi = multi == 0 ? 1 : multi;
  //   var price = changeType == 'price' ? newValues.price : Number(currentUnitCost / (1 - (newValues.percent / 100)) * multi);
  //   var tempPrice = price / multi;
  //   if (tempPrice > priceMaximum) {
  //     price = priceMaximum * multi;
  //     tempPrice = priceMaximum;
  //     var maxPercent = Math.round(((priceMaximum - currentUnitCost) / priceMaximum) * 100);
  //     this.showPrePriceMaximumConfirmation(price, priceMaximum, multi, maxPercent, rowNode, newValues, changeType, isMasterChange);
  //   }
  //   else{
  //     newValues.passesPrePricedValidation = true;
  //     this.sendPricePercentOrBookChange(rowNode, newValues, changeType, isMasterChange)
  //   }
  // }

  showPrePriceMaximumConfirmation(price, priceMaximum, multi, maxPercent, rowNode, newValues, changeType, isMasterChange) {
    this.confirmationDialogRef = this._dialog.open(ConfirmationDialogComponent, {
      disableClose: false,
      //width: '600px',
      // data: {
      //   title: 'Pre-priced Item Maximum',
      //   message: `The price cannot exceed $${price.toFixed(2)} (${priceMaximum.toFixed(2)} x ${multi}) 
      //             for this pre-priced item (Item Code: ${rowNode.data.itemCode}).<br/><br/>
      //             Therefore, the maximum percent allowed is ${maxPercent}.<br/><br/>
      //             Would you like to set this item to $${price.toFixed(2)}, ${maxPercent}%?`,
      //   confirmText: 'Yes, proceed with change',
      //   cancelText: 'No, cancel this action!',
      //   abortText: isMasterChange ? 'Cancel all following actions!' : undefined
      // }
      //GAIR-103
      //This item is prepriced at $2.99. You cannot set the price higher than the package price.
      data: {
        title: `Pre-priced Item Maximum (Item Code: ${rowNode.data.itemCode})`,
        message: `This item is prepriced at $${price.toFixed(2)}.
                  You cannot set the price higher than the package price.<br/><br/>
                  Would you like to set this item to $${price.toFixed(2)}?`,
        confirmText: 'Yes, proceed with change',
        cancelText: 'No, cancel this action!',
        abortText: isMasterChange ? 'Cancel all following actions!' : undefined
      }
    });
    this.confirmationDialogRef.afterClosed().subscribe(result => {
      if(result === true) { //if continue
        let prePricedNewValues = this.cloneObject(newValues);
        prePricedNewValues.percent = maxPercent;
        prePricedNewValues.price = price.toFixed(2);
        prePricedNewValues.passesPrePricedValidation = true;
        this.sendPricePercentOrBookChange(rowNode, prePricedNewValues, changeType, isMasterChange);
      }
      else if (result === false) { //if cancel current but continue if master changes
        if (this.isCellEditMode) this.revertFailedCellEditingChangeValuesToPreviousValues(rowNode, this.rowDataBeforeEditing);
        //if canceling a master change, keep going with the rest of the master changes
        else if (isMasterChange) {
          this.masterInputSaveCounter++;
          if (this.masterInputSaveCounter < this.masterChangeRowArray.length) {
            this.handleMasterInputSave();
          }
          else { // finished master changes
            this.onMasterChangesCompleted();
          }
        }
        this.confirmationDialogRef = null;
      }
      else { //abort! cancel all if master changes
        this.confirmationDialogRef = null;
      }
    });
  }


  //!///////////////////////////////
  // Export
  //////////////////////////////////

  setFileNameForExport(){
    let fileName = 'export';
    let activeTab = this.tabModel.activeTab ? this.tabModel.activeTab : null;
    let activeSubTab = this.tabModel.activeSubTab ? this.tabModel.activeSubTab : null;
    let dt = String(new Date().toLocaleString()).replace(/\//g, '-').replace(/:/g, '_');
    if (activeTab && activeSubTab) fileName = 'RPMS_Price_Management_' + activeTab + '_' + activeSubTab + '_Exported_' + dt;
    this.gridOptions.defaultCsvExportParams.fileName = fileName;
    this.gridOptions.defaultExcelExportParams.fileName = fileName;
  }

  onExport(){
    this.setFileNameForExport();
    this.gridOptions.defaultExcelExportParams.allColumns = true;
    this.agGrid.api.exportDataAsExcel();
    this.gridOptions.defaultExcelExportParams.allColumns = false;
  }

  //!///////////////////////////////
  // Formatters and Misc
  //////////////////////////////////

  isEmpty(obj) {
    var hasOwnProperty = Object.prototype.hasOwnProperty;
      if (obj == null) return true;
      if (obj.length > 0)    return false;
      if (obj.length === 0)  return true;
      if (typeof obj !== "object") return true;
      for (var key in obj) {
          if (hasOwnProperty.call(obj, key)) return false;
      }
      return true;
  }

  convertToTwoDecimals(num) {
    if (num && !isNaN(num)) return Number(num.toFixed(2));
    else return;
  }

  //https://stackoverflow.com/questions/728360/how-do-i-correctly-clone-a-javascript-object
  cloneObject(obj) {
    var copy;

    // Handle the 3 simple types, and null or undefined
    if (null == obj || "object" != typeof obj) return obj;

    // Handle Date
    if (obj instanceof Date) {
        copy = new Date();
        copy.setTime(obj.getTime());
        return copy;
    }

    // Handle Array
    if (obj instanceof Array) {
        copy = [];
        for (var i = 0, len = obj.length; i < len; i++) {
            copy[i] = this.cloneObject(obj[i]);
        }
        return copy;
    }

    // Handle Object
    if (obj instanceof Object) {
        copy = {};
        for (var attr in obj) {
            if (obj.hasOwnProperty(attr)) copy[attr] = this.cloneObject(obj[attr]);
        }
        return copy;
    }

    throw new Error("Unable to copy obj! Its type isn't supported.");
  }

  closeDataInfoBar(){
    this.isDataInfoBarVisible = false;
    this._changeDetectorRef.detectChanges();
    setTimeout(() => this.sizeGrid(), 100);
  }

  handleAppearanceOfChangeType(changeType){
    //this gets called when cell editing changes happen
    //if the user types the value in one field and then unfocuses the row to commit, it will call this and these fields won't exist anymore, so test if each exists.
    var bookCell = <HTMLElement>document.getElementById('bookCell');
    var srpCodeCell = <HTMLElement>document.getElementById('srpCodeCell');
    var multiCell = <HTMLElement>document.getElementById('multiCell');
    var priceCell = <HTMLElement>document.getElementById('priceCell');
    var percentCell = <HTMLElement>document.getElementById('percentCell');
    var keepSrpCell = <HTMLElement>document.getElementById('keepSrpCell');
    var keepPercentCell = <HTMLElement>document.getElementById('keepPercentCell');
    //var setPriceKeepPercentCell = <HTMLInputElement>document.getElementById('setPriceKeepPercentCell');
    if (bookCell) bookCell.classList.remove('highlight');
    if (srpCodeCell) srpCodeCell.classList.remove('highlight');
    if (multiCell) multiCell.classList.remove('highlight');
    if (priceCell) priceCell.classList.remove('highlight');
    if (percentCell) percentCell.classList.remove('highlight');
    if (keepSrpCell) keepSrpCell.classList.remove('highlight');
    if (keepPercentCell) keepPercentCell.classList.remove('highlight');

    if (bookCell && srpCodeCell && multiCell && priceCell && keepSrpCell && percentCell && keepPercentCell) {
      switch (changeType){
        case 'book':
          bookCell.classList.add('highlight');
          srpCodeCell.classList.add('highlight');
          break;
        case 'price':
          multiCell.classList.add('highlight');
          priceCell.classList.add('highlight');
          //if (setPriceKeepPercentCell.checked) keepPercentCell.classList.add('highlight');
          //else keepSrpCell.classList.add('highlight');
          break;
        case 'percent':
          percentCell.classList.add('highlight');
          //keepPercentCell.classList.add('highlight');
          break;
        default:
          break;
      }
    }

  }

  showHelpfulTip(event){
    this._helpService.showHelpfulTip(event);
  }

  hideHelpfulTip(event){
    this._helpService.hideHelpfulTip(event);
  }

  showGroupHeadersDialog(){
    this.groupHeadersDialogRef = this._dialog.open(GroupHeadersDialog, {
      width: '850px',
    });
  }

  onViewControlledStoresPricingButtonClick(params){
    this.hideAllEditors();
    this.rowNodeToUpdate = params.node;
    this.setControlledStorePricingVisible(true);
  }

  //keyboard shortcuts

  @HostListener('document:keyup', ['$event'])
  onKeyUp(ev:KeyboardEvent) {
    //console.log(ev);

    //F2 - Clear Filters and focus in ItemCode filter
    if (ev.keyCode === 113) {
      let headerCellElement = <HTMLElement>document.querySelector('.ag-header-cell[col-id="itemCode"]').parentElement.parentElement;
      let input = <HTMLElement>headerCellElement.querySelector('.ag-floating-filter-body input');
      input.focus();
      this.clearFilters();
      this.hideAllEditors();
    }

    //Alt-s - Open store select 
    // if (ev.key === "s" && ev.altKey) {
    //   let storeSelect = <HTMLElement>document.getElementById('pm-store-select');
    //   storeSelect.click();
    //   this.hideAllEditors();
    // }

  }

}

@Component({
  selector: 'app-group-headers-dialog',
  template: `<rpms-group-headers></rpms-group-headers>`,
})
export class GroupHeadersDialog {

  constructor(
    public dialogRef: MatDialogRef<GroupHeadersDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

}




