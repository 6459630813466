<div class="page-heading">
  <h1><i class="fa fa-id-badge"></i>Admin Contacts</h1>
  <div class="page-heading-buttons">
    <button *ngIf="permissions.roleAllowsUserToEditAdminNotificationContacts" mat-raised-button color="accent" [disabled]="myUser.hasReadOnlyRestriction" (click)="goToCreateContact()"><i class="fas fa-plus-circle"></i>Add New Contact(s)</button>
</div>
</div>
<div class="table-toolbar">
  <div class="filter-tools">
      <button (click)="clearFilters()" mat-raised-button title="Clear All Filters"><i class="far fa-ban"></i></button>
      <input id="quick-filter" class="quick-filter" placeholder="Quick Filter..." type="text" [(ngModel)]="quickFilterText" (keyup)="onQuickFilterChange()" />
  </div>
  <div class="column-tools">
    Columns: &nbsp;
    <button (click)="resetColumns()" mat-raised-button title="Reset Columns"><i class="far fa-columns"></i></button>
    <button (click)="fitColumns()" mat-raised-button title="Fit Columns to Width"><i class="far fa-arrows-h"></i></button>
    <button (click)="autoSizeColumns()" mat-raised-button title="Auto Size Columns"><i class="far fa-arrow-from-left"></i></button>
  </div>
</div>
<ag-grid-angular #agGrid
  [gridOptions]="gridOptions"
  style="width: 100%; height: 1px;" 
  class="ag-theme-balham"
  id="ag-grid-wrapper"
  [rowData]="rowData" 
  [context]="context"
  [quickFilterText]="quickFilterText"
  >
</ag-grid-angular>
