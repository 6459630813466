<div class="detail admin-dialog">
  <h1 mat-dialog-title [ngClass]="{'accent': id == '0'}">{{title}}</h1>
  <div mat-dialog-content>
    <p *ngIf="id != '0' && userId != null">This contact was added by choosing it from an existing RPMS user account. It must be edited in the User Accounts section.
      (You can, however, change the Active checkbox below that specifies whether or not they receive retail notifications, while still keeping them in the contact list)
    </p>
    <form [formGroup]="retailNotificationContactDetailForm" id="retail-notification-contact-detail-form" (ngSubmit)="onSubmit()">
      <mat-radio-group formControlName="radioButtonGroup" aria-label="Select an option" 
        (change)="onRadioButtonChange($event.value)" *ngIf="id == '0'">
        <mat-radio-button value="enter">Manually Enter a Single Contact</mat-radio-button>
        <mat-radio-button value="choose">Choose Contacts From User List</mat-radio-button>
      </mat-radio-group>

      <div class="row" *ngIf="!isManualEntry">
        <mat-form-field>
          <mat-label>Users</mat-label>
          <mat-select id="userIds" formControlName="userIds" placeholder="User(s)" multiple>
            <mat-option *ngFor="let user of users" [value]="user.userId">
              {{user.fullNameAndEmail}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div *ngIf="isManualEntry">
        <div class="row">
          <mat-form-field>
            <mat-label>First Name</mat-label>
            <input matInput id="contactFirstName" formControlName="contactFirstName" placeholder="First Name">
            <mat-error *ngIf="retailNotificationContactDetailForm.hasError('required', 'contactFirstName')">Email is required</mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Last Name</mat-label>
            <input matInput id="contactLastName" formControlName="contactLastName" placeholder="Last Name">
            <mat-error *ngIf="retailNotificationContactDetailForm.hasError('required', 'contactLastName')">Email is required</mat-error>
          </mat-form-field>
        </div>
        <div class="row">
          <mat-form-field>
            <mat-label>Phone</mat-label>
            <input matInput id="contactPhone" formControlName="contactPhone" placeholder="Phone" mask='(000) 000-0000'>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Email</mat-label>
            <input matInput id="contactEmail" formControlName="contactEmail" placeholder="Email">
            <mat-error *ngIf="retailNotificationContactDetailForm.hasError('required', 'contactEmail')">Email is required</mat-error>
            <mat-error *ngIf="retailNotificationContactDetailForm.hasError('pattern', 'contactEmail')">Please enter a valid email address</mat-error>
            </mat-form-field>
        </div>
        <div class="checkbox-form-field">
          <mat-checkbox id="active" formControlName="active">Active</mat-checkbox>
        </div>  
      </div>
      <div class="detail-action-buttons">
        <button mat-raised-button color="primary" [disabled]="!retailNotificationContactDetailForm.valid" type="submit">Save</button>
        <button mat-raised-button (click)="closeDialog()" type="button">Cancel</button>
      </div>
    </form>
  </div>
  </div>
