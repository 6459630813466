import { Component, OnInit, ViewChild } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { GridOptions } from 'ag-grid-community';
import { MessageService } from '../shared/message.service';
import { StoreService } from '../shared/store.service';
import { SpinnerService } from '../shared/spinner.service';
import * as moment from 'moment';
import { AdWeekService } from '../shared/ad-week.service';
import { GridService } from '../shared/grid.service';
import { Router, NavigationStart } from '@angular/router';
import { AppConfig } from '../app.config';

@Component({
  selector: 'app-ad-week',
  templateUrl: './ad-week.component.html',
  styleUrls: ['./ad-week.component.css'],
})
export class AdWeekComponent implements OnInit {
  @ViewChild('agGrid') agGrid: AgGridAngular;

  private config = AppConfig.settings;
  gridOptions: GridOptions;
  quickFilterText: string = '';
  context; //required by grid

  isDataLoaded: boolean = false;
  isStoreSelected: boolean = false;
  isWeekSelected: boolean = false;

  stores;
  store;
  weeks;
  week;
  activeTab = 'Grocery';
  rowCount;
  routerSubscription;
  adWeekGridMetadata;

  rowData = [];
  sortedGridDataWithBlanks = [];
  sortedGridDataWithoutBlanks = [];

  blankRowData: any = {
    blank: true,
    store: '',
    adYear: '',
    adNumber: '',
    deptCode: '',
    groupNumber: '',
    itemCode: '',
    size: '',
    description: '',
    feature: '',
    upc: '',
    lastYearsMovementForStore: '',
    yearToDateMovementForStore: '',
    storeItemBook: '',
    storeItemCd: '',
    storeItemN: '',
    storeItemPrice: '',
    storeItemPercent: '',
    n: '',
    price: '',
    percent: '',
    unitCostLessDeliveryFee: '',
    deliveredUnitCost: '',
    units: '',
    baseCost: '',
    baseUnitCost: '',
    dealCost: '',
    dealUnitCost: '',
    vendorNumber: '',
    retailRebate: '',
    coOpRebate: '',
    breakingDate: '',
    rebateBeginDate: '',
    rebateEndDate: '',
    showDeal: '',
    netAdCost: '',
    shippingBeginDate: '',
    shippingEndDate: '',
  };
  
  statusBar;
  columnDefs = [
    {
      headerName: "Store",
      field: "store",
      width: 180,
      cellClass: ["store-col"],
      filter: "agTextColumnFilter",
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Ad Year",
      field: "adYear",
      width: 80,
      cellClass: ["adYear-col"],
      filter: "agNumberColumnFilter",
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Ad Number",
      field: "adNumber",
      width: 80,
      cellClass: ["adNumber-col"],
      filter: "agNumberColumnFilter",
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Breaking Date",
      field: "breakingDate",
      width: 100,
      cellClass: ["breakingDate-col", "tar", "dateFormat"],
      cellRenderer: this._gridService.dateRenderer,
      filter: "agDateColumnFilter",
      filterParams: {
        comparator: this._gridService.dateComparator,
        inRangeInclusive: true
      },
      get headerTooltip() { return this.headerName; }
    },
    {   
      headerName: "Dept Code",
      field: "deptCode",
      width: 80,
      cellClass: ["deptCode-col"],
      filter: "agTextColumnFilter",
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Group #",
      field: "groupNumber",
      width: 90,
      cellClass: ["orderHeaderNumber-col"],
      filter: "agNumberColumnFilter",
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Vendor #",
      field: "vendorNumber",
      width: 100,
      cellClass: ["vendorNumber-col", "tar"],
      filter: "agNumberColumnFilter",
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Feature",
      field: "feature",
      width: 110,
      cellClass: ["feature-col", "featureFormat"],
      cellRenderer: this._gridService.featureRenderer,
      filter: "agSetColumnFilter",
      filterParams: {
        cellRenderer: this._gridService.featureRenderer,
        newRowsAction: 'keep',
      },
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "LY Mvt",
      field: "lastYearsMovementForStore",
      width: 50,
      cellClass: ["lastYearsMovementForStore-col", "tar"],
      filter: "agNumberColumnFilter",
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "YTD Mvt",
      field: "yearToDateMovementForStore",
      width: 50,
      cellClass: ["yearToDateMovementForStore-col", "tar"],
      filter: "agNumberColumnFilter",
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Item Code",
      field: "itemCode",
      width: 90,
      cellClass: ["itemCode-col", "itemCodeFormat"],
      cellRenderer: this._gridService.itemCodeRenderer,
      filter: "agTextColumnFilter",
      filterParams: {
        defaultOption: "startsWith"
      },
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "UPC",
      field: "upc",
      width: 100,
      cellClass: ["upc-col"],
      filter: "agTextColumnFilter",
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Description",
      field: "description",
      width: 300,
      cellClass: ["description-col"],
      filter: "agTextColumnFilter",
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Units",
      field: "units",
      width: 80,
      cellClass: ["units-col", "tar"],
      hide: false,
      filter: "agNumberColumnFilter",
      filterParams: {
        defaultOption: "equals",
        inRangeInclusive: true,
      },
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Size",
      field: "size",
      width: 100,
      cellClass: ["size-col"],
      filter: "agTextColumnFilter",
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "SI Book",
      field: "storeItemBook",
      width: 50,
      cellClass: ["storeItemBook-col", "tac"],
      filter: "agSetColumnFilter",
      filterParams: {
        values: ['1', '2', '3', '4', '5', '6', '7', 'C'],
        newRowsAction: 'keep'
      },
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "SI SRP Code",
      field: "storeItemCd",
      width: 50,
      cellClass: ["storeItemCd-col", "tac"],
      filter: "agSetColumnFilter",
      filterParams: {
        values: ['A', 'B', 'C', 'H', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
        newRowsAction: 'keep'
      },
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "SI Multi",
      field: "storeItemN",
      width: 50,
      cellClass: ["storeItemN-col", "tar"],
      filter: "agNumberColumnFilter",
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "SI Price",
      field: "storeItemPrice",
      width: 80,
      cellClass: ["storeItemPrice-col", "tar", "currencyFormat"],
      cellRenderer: this._gridService.currencyRendererOrEmpty,
      filter: "agNumberColumnFilter",
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "SI %",
      field: "storeItemPercent",
      width: 50,
      cellClass: ["storeItemPercent-col", "tar"],
      filter: "agNumberColumnFilter",
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Base Unit Cost",
      field: "baseUnitCost",
      width: 80,
      cellClass: ["baseUnitCost-col", "tar", "currencyFormat"],
      cellRenderer: this._gridService.currencyRenderer,
      filter: "agNumberColumnFilter",
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Base Cost",
      field: "baseCost",
      width: 80,
      cellClass: ["baseCost-col", "tar", "currencyFormat"],
      cellRenderer: this._gridService.currencyRenderer,
      filter: "agNumberColumnFilter",
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Multi",
      field: "n",
      width: 50,
      cellClass: ["n-col", "tar"],
      filter: "agNumberColumnFilter",
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Price",
      field: "price",
      width: 80,
      cellClass: ["price-col", "tar", "currencyFormat"],
      cellRenderer: this._gridService.currencyRenderer,
      filter: "agNumberColumnFilter",
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "%",
      field: "percent",
      width: 50,
      cellClass: ["percent-col", "tar"],
      filter: "agNumberColumnFilter",
      get headerTooltip() { return this.headerName; }
    },
    // {
    //   headerName: "Unit Cost Less Delivery Fee",
    //   field: "unitCostLessDeliveryFee",
    //   width: 80,
    //   cellClass: ["unitCostLessDeliveryFee-col", "tar", "currencyFormat"],
    //   cellRenderer: this._gridService.currencyRenderer,
    //   filter: "agNumberColumnFilter",
    //   get headerTooltip() { return this.headerName; }
    // },
    // {
    //   headerName: "Delivered Unit Cost",
    //   field: "deliveredUnitCost",
    //   width: 80,
    //   cellClass: ["deliveredUnitCost-col", "tar", "currencyFormat"],
    //   cellRenderer: this._gridService.currencyRenderer,
    //   filter: "agNumberColumnFilter",
    //   get headerTooltip() { return this.headerName; }
    // },
    {
      headerName: "Deal Unit Cost",
      field: "dealUnitCost",
      width: 80,
      cellClass: ["dealUnitCost-col", "tar", "currencyFormat"],
      cellRenderer: this._gridService.currencyRenderer,
      filter: "agNumberColumnFilter",
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Deal Cost",
      field: "dealCost",
      width: 80,
      cellClass: ["dealCost-col", "tar", "currencyFormat"],
      cellRenderer: this._gridService.currencyRenderer,
      filter: "agNumberColumnFilter",
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Off Invoice Deal",
      field: "offInvoiceDeal",
      width: 80,
      cellClass: ["offInvoiceDeal-col", "tar", "currencyFormat"],
      cellRenderer: this._gridService.currencyRendererOrEmpty,
      filter: "agNumberColumnFilter",
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Off Invoice End",
      field: "offInvoiceEnd",
      width: 100,
      cellClass: ["offInvoiceEnd-col", "tar", "dateFormat"],
      cellRenderer: this._gridService.dateRenderer,
      filter: "agDateColumnFilter",
      filterParams: {
        comparator: this._gridService.dateComparator,
        inRangeInclusive: true
      },
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Retail Rebate",
      field: "retailRebate",
      width: 80,
      cellClass: ["retailRebate-col", "tar", "currencyFormat"],
      cellRenderer: this._gridService.currencyRendererOrEmpty,
      filter: "agNumberColumnFilter",
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "CoOp Rebate",
      field: "coOpRebate",
      width: 80,
      cellClass: ["coOpRebate-col", "tar", "currencyFormat"],
      cellRenderer: this._gridService.currencyRendererOrEmpty,
      filter: "agNumberColumnFilter",
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Rebate Begin Date",
      field: "rebateBeginDate",
      width: 100,
      cellClass: ["rebateBeginDate-col", "tar", "dateFormat"],
      cellRenderer: this._gridService.dateRenderer,
      filter: "agDateColumnFilter",
      filterParams: {
        comparator: this._gridService.dateComparator,
        inRangeInclusive: true
      },
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Rebate End Date",
      field: "rebateEndDate",
      width: 100,
      cellClass: ["rebateEndDate-col", "tar", "dateFormat"],
      cellRenderer: this._gridService.dateRenderer,
      filter: "agDateColumnFilter",
      filterParams: {
        comparator: this._gridService.dateComparator,
        inRangeInclusive: true
      },
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Show Deal",
      field: "showDeal",
      width: 100,
      cellClass: ["showDeal-col", "tar", "currencyFormat"],
      cellRenderer: this._gridService.currencyRendererOrEmpty,
      filter: "agNumberColumnFilter",
      filterParams: {
        inRangeInclusive: true
      },
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Net Ad Cost",
      field: "netAdCost",
      width: 100,
      cellClass: ["netAdCost-col", "tar", "currencyFormat"],
      cellRenderer: this._gridService.currencyRendererOrEmpty,
      filter: "agNumberColumnFilter",
      filterParams: {
        inRangeInclusive: true
      },
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Shipping Begin Date",
      field: "shippingBeginDate",
      cellRenderer: this._gridService.dateRenderer,
      width: 100,
      cellClass: ["shippingBeginDate-col", "tar", "dateFormat"],
      filter: "agDateColumnFilter",
      filterParams: {
        comparator: this._gridService.dateComparator,
        inRangeInclusive: true
      },
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Shipping End Date",
      field: "shippingEndDate",
      cellRenderer: this._gridService.dateRenderer,
      width: 100,
      cellClass: ["shippingEndDate-col", "tar", "dateFormat"],
      filter: "agDateColumnFilter",
      filterParams: {
        comparator: this._gridService.dateComparator,
        inRangeInclusive: true
      },
      get headerTooltip() { return this.headerName; }
    },
  ];

  excelStyles = [
    {
      id: 'currencyFormat',
      numberFormat: {
        format: '###,##0.00',
      },
    },
    {
      id: 'dateFormat',
      dataType: 'DateTime',
      numberFormat: {
          format: 'm/d/yyyy'
      }
    },
    { 
      id: 'itemCodeFormat', 
      dataType: 'Number', 
      numberFormat: { 
        format: '000000' 
      } 
    },
  ];

  constructor(
    private _storeService: StoreService,
    private _spinnerService: SpinnerService,
    private _messageService: MessageService,
    private _adWeekService: AdWeekService,
    private _gridService: GridService,
    private _router: Router,
  ) { 
    if (this.config.logInits) console.log('adweek init');
    this._spinnerService.setProgressSpinnerMessage('Processing Request...');
    this.gridOptions = <GridOptions> {
			columnDefs: this.columnDefs,
			rowData: this.rowData,
      rowHeight: 35,    
			headerHeight: 25,
			groupHeaderHeight: 25,
      floatingFiltersHeight: 35,
      enableRangeSelection: true,
      animateRows: false,
      onSortChanged: this.doOnSortChanged,
      onFilterChanged: this.doOnFilterChanged,
      onColumnVisible: this.saveColumnMetadata,
      onColumnPinned: this.saveColumnMetadata,
      onColumnResized: this.saveColumnMetadata,
      onColumnMoved: this.saveColumnMetadata,
      onColumnRowGroupChanged: this.saveColumnMetadata,
      onColumnGroupOpened: this.saveColumnMetadata,
      onDragStopped: this.saveColumnMetadata,
      isFullWidthRow: this.doOnFullWidthRow,
      fullWidthCellRenderer: 'fullWidthCellRenderer',
      components: {
          'fullWidthCellRenderer': FullWidthCellRenderer
      },
      defaultColDef: {
        sortable: true,
        resizable: true,
        enableValue: true,
        enableRowGroup: true,
        enablePivot: true,
        floatingFilter: true,
      },
      defaultCsvExportParams: {
        allColumns: false,
        processCellCallback: function(params) {
          if (!params.node.data.blank){
            let cellClass = params.column.getColDef().cellClass;
            
            if (Array.isArray(cellClass) && cellClass.indexOf('featureFormat') != -1) {
              return params.context.componentParent._gridService.convertFeatureToText(params.value);
            }
            else if (Array.isArray(cellClass) && cellClass.indexOf('currencyFormat') != -1 && params.value && !isNaN(params.value)) {
              return Number(params.value).toFixed(2);
            }
            else if (Array.isArray(cellClass) && cellClass.indexOf('dateFormat') != -1 && params.value && moment(params.value, moment.ISO_8601).isValid()) {
              return moment(params.value).format('M/D/YYYY').toString();
            }
            else if (Array.isArray(cellClass) && cellClass.indexOf('itemCodeFormat') != -1 && params.value && !isNaN(params.value)) {
              var width = 6;
              var z = z || '0';
              var n = params.value + '';
              var v = n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
              return v
            }
            return params.value;
          }
          else { //cell should be blank for a row with blank = true
            return null;
          }
        }
      },
      defaultExcelExportParams: {
        allColumns: false,
        processCellCallback: function(params) {
          if (!params.node.data.blank){
            let cellClass = params.column.getColDef().cellClass;
            
            if (Array.isArray(cellClass) && cellClass.indexOf('featureFormat') != -1) {
              return params.context.componentParent._gridService.convertFeatureToText(params.value);
            }
            else if (Array.isArray(cellClass) && cellClass.indexOf('currencyFormat') != -1 && params.value && !isNaN(params.value)) {
              return Number(params.value).toFixed(2);
            }
            else if (Array.isArray(cellClass) && cellClass.indexOf('itemCodeFormat') != -1 && params.value && !isNaN(params.value)) {
              return Number(params.value);
            }
            // Doesn't need date formatting for the Excel export
            return params.value;
          }
          else { //cell should be blank for a row with blank = true
            return null;
          }
        }
      },
      sideBar: {
        toolPanels: [
            {
              id: 'columns',
              labelDefault: 'Columns',
              labelKey: 'columns',
              iconKey: 'columns',
              toolPanel: 'agColumnsToolPanel',
              toolPanelParams: {
                suppressRowGroups: true,
                suppressValues: true,
                suppressPivotMode: true,
              }    
            },
            {
              id: 'filters',
              labelDefault: 'Filters',
              labelKey: 'filters',
              iconKey: 'filter',
              toolPanel: 'agFiltersToolPanel',
            }
        ],
        defaultToolPanel: ''
      },
    }
    this.statusBar = {
      statusPanels: [
        // {
        //   statusPanel: "agTotalRowCountComponent",
        //   align: "left"
        // },
        { statusPanel: "agFilteredRowCountComponent" },
        { statusPanel: "agSelectedRowCountComponent" },
        { statusPanel: "agAggregationComponent" }
      ]
    };
  
    this.context = { componentParent: this }
  }

  ngOnInit() {
    this.applyStylingFromActiveTab(this.activeTab);
    this.getActiveStores();
  }

  ngAfterContentInit(){
    // subscribe to route changes
    // https://stackoverflow.com/questions/34444822/angular2-watch-for-route-change
    // if the user is logging out, this will save the column state of the grid first
    this.routerSubscription = this._router.events.subscribe((event:any) => {
      if (event instanceof NavigationStart) {
          //console.log(event.url);
          // save metadata before leaving page
          if (event.url !== '/login') {
            var colState = this.agGrid.api.getColumnState();
            var colGroupState = this.agGrid.api.getColumnGroupState();
            var metadata = {
              columnState: colState,
              columnGroupState: colGroupState,
            }
            this._adWeekService.updateAdWeekGridMetadata({ adWeekGridMetadata: JSON.stringify(metadata) })
              .subscribe(
                (data) => {
                },
                (response) => {
                  this._messageService.onFailure('Failed to save grid metadata.', response);
                }
              )
          }
        }
    });
  }

  ngOnDestroy() {
    this.routerSubscription.unsubscribe();
  }

  doOnFullWidthRow(params){
      return params.rowNode.data.blank;
  }

  //grid option column events call this
  saveColumnMetadata(e){
    var awComponent = e.context.componentParent;
    var colState = awComponent.agGrid.api.getColumnState();
    var colGroupState = awComponent.agGrid.api.getColumnGroupState();
    var metadata = {
      columnState: colState,
      columnGroupState: colGroupState,
    }
    awComponent._adWeekService.setColumnMetadata(metadata);
    awComponent.adWeekGridMetadata = metadata;
  }

  getActiveStores(){
    this._storeService.getActiveStores()
      .subscribe(
        (data) => {
          this.stores = data;
          this.getAdWeekGridMetadata();
        },
        (response) => {
          this._messageService.onFailure('Failed to get stores.', response);
        }
      )
  }

  getAdWeekGridMetadata(){
    this._adWeekService.getAdWeekGridMetadata()
      .subscribe(
        (data:any) => {
          this.adWeekGridMetadata = data.adWeekGridMetadata;
          this.getQueryableAdWeekAdNumbers();
        },
        (response) => {
          this._messageService.onFailure('Failed to get grid metadata.', response);
        }
      )
  }

  getQueryableAdWeekAdNumbers(){
    this._adWeekService.getQueryableAdWeekAdNumbers()
      .subscribe(
        (data: any) => {
          this.weeks = data;
        }, 
        (error) => {
          this._messageService.onFailure("Failed to get ad week numbers.", error);
        }
      )
  }
  
  onStoreSelected = function () {
    this.isStoreSelected = true;
    this.isWeekSelected = false;
    this.isDataLoaded = false;
    this.rowData = [];
    this.week = null;
  }

  onWeekSelected(){
    this.isWeekSelected = true;
    this.getAdWeekItemsByActiveTab(this.activeTab);
  }

  getAdWeekItemsByActiveTab(activeTab){
    var params = null;
    switch (activeTab) {
      case 'Grocery': params = { "$filter": "AdItem/Item/DeptCode eq 'A' or AdItem/Item/DeptCode eq 'B' or AdItem/Item/DeptCode eq 'C' or AdItem/Item/DeptCode eq 'K'" }; break;
      case 'Meat': params = { "$filter": "AdItem/Item/DeptCode eq 'M' or AdItem/Item/DeptCode eq 'N'" }; break;
      case 'Produce': params = { "$filter": "AdItem/Item/DeptCode eq 'P'" }; break;
      case 'DeliBakery': params = { "$filter": "AdItem/Item/DeptCode eq 'S' or AdItem/Item/DeptCode eq 'X' or AdItem/Item/DeptCode eq 'Z'" }; break;
      case 'Dairy': params = { "$filter": "AdItem/Item/DeptCode eq 'D'" }; break;
      case 'HBA': params = { "$filter": "AdItem/Item/DeptCode eq 'E' or AdItem/Item/DeptCode eq 'G' or AdItem/Item/DeptCode eq 'H' or AdItem/Item/DeptCode eq 'I'" }; break;
      case 'FrozenFood': params = { "$filter": "AdItem/Item/DeptCode eq 'F'" }; break;
      case 'AllItems': params = null; break;
    }
    this._adWeekService.getAllAdWeekItemsByStoreIdAdYearAndAdNumber(this.store.storeId, this.week.adYear, this.week.adNumber, params)
    .subscribe(
      (data: any) => {
        this.formatRowData(data);
      }, 
      (error) => {
        this._messageService.onFailure("Failed to get ad week items.", error);
      }
    )

  }

  onTabClick(tab){
    this.activeTab = tab;
    this.rowData = [];
    this.applyStylingFromActiveTab(tab)
    this.getAdWeekItemsByActiveTab(tab);
    this.setFileNameForExport();
  }

  applyStylingFromActiveTab(activeTab){
    //this makes the grid and controls match the tabs color
    var wrapperElement = <HTMLElement>document.querySelector('#ad-week-grid-wrapper');
    wrapperElement.className = activeTab;
  }

  onModelUpdated() {
    this.rowCount = this.agGrid.api.getDisplayedRowCount();
  }

  doOnFilterChanged(params){
    params.context.componentParent.handleDataSwap();
  }

  doOnSortChanged(params){
    params.context.componentParent.handleDataSwap();
    params.context.componentParent.saveColumnMetadata(params);
  }

  //GAIR-142
  handleDataSwap(){
    let filterModel = this.agGrid.api.getFilterModel();
    let columnState = this.agGrid.api.getColumnState();
    let isNotSorting = columnState.every(obj => obj.sort === null);
    if (this.isEmpty(filterModel) && isNotSorting) {
      this.agGrid.api.setGridOption("rowData", this.sortedGridDataWithBlanks);
    }
    else {
      this.agGrid.api.setGridOption("rowData", this.sortedGridDataWithoutBlanks);
    }
  }

  clearFilters(){
    this.quickFilterText = '';
    this.agGrid.api.setGridOption('quickFilterText', this.quickFilterText);
    this.agGrid.api.setFilterModel(null);
    this.agGrid.api.onFilterChanged();
  }

  resetColumns(){
    this.agGrid.api.resetColumnState();
    this.autoSizeColumns(); //since this is done by default also

    //GAIR-151
    var colState = this.agGrid.api.getColumnState();
    var colGroupState = this.agGrid.api.getColumnGroupState();
    var metadata = {
      columnState: colState,
      columnGroupState: colGroupState,
    }
    this._adWeekService.setColumnMetadata(metadata);
    this.adWeekGridMetadata = metadata;

    this._adWeekService.updateAdWeekGridMetadata({ adWeekGridMetadata: JSON.stringify(metadata) })
      .subscribe(
        (data) => {
        },
        (response) => {
          this._messageService.onFailure('Failed to save grid metadata.', response);
        }
      )
  }

  autoSizeColumns(){
    var columns = this.agGrid.api.getColumns();
    this.agGrid.api.autoSizeColumns(columns);
  }

  fitColumns(){
    this.agGrid.api.sizeColumnsToFit();
  }

  onQuickFilterChange(){
    this.agGrid.api.setGridOption('quickFilterText', this.quickFilterText);
  }

  clearGrid() {
    this.clearFilters();
    this.rowData = [];
    this.agGrid.api.setGridOption("rowData",this.rowData);
  }

  formatRowData(data) {
    this.isDataLoaded = true;
    var gridData = [];
    for (var i = 0; i < data.length; i++) {
      var singleRowData: any = {};
      var adWeekItem = data[i];
      var si = adWeekItem.storeItem;
      singleRowData = {
        store: this.store.storeNumber + ' - ' + this.store.city + ', ' + this.store.state,
        adYear: adWeekItem.adItem.adYear,
        adNumber: adWeekItem.adItem.adNumber,
        deptCode: adWeekItem.adItem.item.deptCode,
        groupNumber: Number(adWeekItem.adItem.item.groupNumber),
        itemCode: adWeekItem.adItem.item.itemCode,
        size: adWeekItem.adItem.item.size,
        description: adWeekItem.description,
        feature: adWeekItem.adItem.feature,
        upc: adWeekItem.adItem.item.upcCode,
        //lastYearsMovementForStore: si == null ? "" : si.lastYearsMovementForStore,
        //yearToDateMovementForStore: si == null ? "" : si.yearlyMovementForStore,
        lastYearsMovementForStore: si == null ? null : si.lastYearsMovementForStore,
        yearToDateMovementForStore: si == null ? null : si.yearlyMovementForStore,
        storeItemBook: si == null ? "" : si.book,
        storeItemCd: si == null ? "" : si.srpCode,
        storeItemN: si == null ? null : si.multi,
        storeItemPrice: si == null || si.price == 0 ? null : si.price,
        storeItemPercent: si == null ? null : si.percent,
        n: adWeekItem.adItem.multi == 0 ? 1 : adWeekItem.adItem.multi,
        price: adWeekItem.adItem.price,
        percent: adWeekItem.adItem.percent,
        unitCostLessDeliveryFee: adWeekItem.adItem.unitCostLessDeliveryFee,
        deliveredUnitCost: adWeekItem.adItem.deliveredUnitCost,
        units: adWeekItem.adItem.item.units,
        baseCost: adWeekItem.adItem.baseCost,
        baseUnitCost: adWeekItem.adItem.baseUnitCost,
        dealCost: adWeekItem.adItem.dealCost,
        dealUnitCost: adWeekItem.adItem.dealUnitCost,
        vendorNumber: adWeekItem.adItem.item.vendorNumber,
        offInvoiceDeal: si?.dealAmt == 0 ? null : si?.dealAmt,
        offInvoiceEnd: si?.dealDate,
        retailRebate: adWeekItem.adItem.retailRebate == null || adWeekItem.adItem.retailRebate == 0 ? null : adWeekItem.adItem.retailRebate,
        coOpRebate: adWeekItem.adItem.coOpRebate == null || adWeekItem.adItem.coOpRebate == 0 ? null : adWeekItem.adItem.coOpRebate,
        breakingDate: adWeekItem.adItem.breakingDate,
        rebateBeginDate: adWeekItem.adItem.rebateBeginDate,
        rebateEndDate: adWeekItem.adItem.rebateEndDate,
        // breakingDate: adWeekItem.adItem.breakingDate != null ? new Date(adWeekItem.adItem.breakingDate) : '',
        // rebateBeginDate: adWeekItem.adItem.rebateBeginDate != null ? new Date(adWeekItem.adItem.rebateBeginDate) : '',
        // rebateEndDate: adWeekItem.adItem.rebateEndDate != null ? new Date(adWeekItem.adItem.rebateEndDate) : '',
        showDeal: adWeekItem.adItem.showDeal,
        netAdCost: adWeekItem.adItem.netAdCost,
        shippingBeginDate: adWeekItem.adItem.shippingBeginDate,
        shippingEndDate: adWeekItem.adItem.shippingEndDate,
      };
      gridData.push(singleRowData);
    }

    //sort data by groupNumber, then by vendorNumber
    gridData.sort(function(a, b) {
      if (a.groupNumber < b.groupNumber) return -1;
      if (a.groupNumber > b.groupNumber) return 1;
      if (a.vendorNumber < b.vendorNumber) return -1;
      if (a.vendorNumber > b.vendorNumber) return 1;
      return 0;
    });

    this.sortedGridDataWithoutBlanks = JSON.parse(JSON.stringify(gridData));

    //GAIR-142
    //if groupNumber or vendorNumber changes add copy of that row with new property blank set to true
    //the fullWidthRow renderer uses the blank property to render a blank row
    //but the underlying data values all still exist so it will sort and filter correctly
    this.sortedGridDataWithBlanks = [];
    let previousGroupNumber;
    let previousVendorNumber;
    for (let i = 0; i < gridData.length; i++) {
      let singleRowData = gridData[i];
      if (previousGroupNumber != undefined && (singleRowData.groupNumber != previousGroupNumber || singleRowData.vendorNumber != previousVendorNumber)){
        //let previousSingleRowDataCopy = JSON.parse(JSON.stringify(gridData[i - 1]));
        //let rowDataCopy = JSON.parse(JSON.stringify(singleRowData));
        //this.blankRowData.blank = true;
        this.sortedGridDataWithBlanks.push(this.blankRowData);
      } 
      previousGroupNumber = singleRowData.groupNumber;
      previousVendorNumber = singleRowData.vendorNumber;
      this.sortedGridDataWithBlanks.push(singleRowData);
    }

    //set data into grid
    setTimeout(() => { 
      this.agGrid.api.setGridOption("rowData", this.sortedGridDataWithBlanks);
      this._gridService.onWindowResize('#ad-week-ag-grid-wrapper');
      this._gridService.sizeGrid('#ad-week-ag-grid-wrapper');

      // no longer need this since I'm sorting above
      // this.agGrid.api.applyColumnState({
      //   state: [
      //     { colId: 'groupNumber', sort: 'asc', sortIndex: 0 },
      //     { colId: 'vendorNumber', sort: 'asc', sortIndex: 1 },
      //   ],
      //   defaultState: { sort: null },
      // });

      //this.autoSizeColumns();
      if (!this.isEmpty(this.adWeekGridMetadata)){
        //console.log(this.adWeekGridMetadata);
        this.agGrid.api.applyColumnState({state: this.adWeekGridMetadata.columnState, applyOrder: true,});
      }
      this.setFileNameForExport();
    }, 100);
  }

  //this allows for setting a select value with an object
  compareStoreObjects(o1: any, o2: any): boolean {
    return o1 && o2 ? o1.storeId === o2.storeId : false;
  }

  compareWeekObjects(o1: any, o2: any): boolean {
    return o1 && o2 ? o1.value === o2.value : false;
  }

  isEmpty(obj) {
    var hasOwnProperty = Object.prototype.hasOwnProperty;
      if (obj == null) return true;
      if (obj.length > 0)    return false;
      if (obj.length === 0)  return true;
      if (typeof obj !== "object") return true;
      for (var key in obj) {
          if (hasOwnProperty.call(obj, key)) return false;
      }
      return true;
  }

  setFileNameForExport(){
    let fileName = 'export';
    let storeNumber = (this.store && this.store.storeNumber) ? this.store.storeNumber : null;
    let adYear = (this.week && this.week.adYear) ? this.week.adYear : null;
    let adNumber = (this.week && this.week.adNumber) ? this.week.adNumber : null;
    let activeTab = this.activeTab ? this.activeTab : null;
    let dt = String(new Date().toLocaleString()).replace(/\//g, '-').replace(/:/g, '_');
    if (storeNumber && adYear && adNumber && activeTab) fileName = 'RPMS_Ad_Week_Store_' + storeNumber + '_For_' + adYear + '_Week_' + adNumber + '_' + activeTab + '_Exported_' + dt;
    this.gridOptions.defaultCsvExportParams.fileName = fileName;
    this.gridOptions.defaultExcelExportParams.fileName = fileName;
  }

  onExport(){
    this.setFileNameForExport();
    this.gridOptions.defaultExcelExportParams.allColumns = true;

    let filterModel = this.agGrid.api.getFilterModel();
    let columnState = this.agGrid.api.getColumnState();
    let isNotSorting = columnState.every(obj => obj.sort === null);
    if (this.isEmpty(filterModel) && isNotSorting) {
      this.agGrid.api.exportDataAsExcel();
    }
    else {
      this.agGrid.api.applyColumnState({
        defaultState: { sort: null },
      });
      this.agGrid.api.setFilterModel(null);
      this.agGrid.api.onFilterChanged();
      this.agGrid.api.setGridOption("rowData", this.sortedGridDataWithBlanks);
      this.agGrid.api.exportDataAsExcel();
      this.agGrid.api.applyColumnState({ state: columnState });
      this.agGrid.api.setFilterModel(filterModel);
      this.agGrid.api.onFilterChanged(); //triggers onFilterChanged which swaps data back to without blanks
    }

    //this.gridOptions.defaultExcelExportParams.exportedRows = 'all';
    this.gridOptions.defaultExcelExportParams.allColumns = false;
    //this.gridOptions.defaultExcelExportParams.exportedRows = 'filteredAndSorted';
  }

}

function FullWidthCellRenderer() {}

FullWidthCellRenderer.prototype.init = function(params) {
    this.eGui = document.createElement('div');
    this.eGui.innerHTML = ''; // don't render it (have it blank)
};

FullWidthCellRenderer.prototype.getGui = function() {
    return this.eGui;
};


