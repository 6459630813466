<div id="admin-page" *ngIf="permissions.roleAllowsUserToViewAdmin">
  <div class="sidebar-nav">
    <ul>
      <li *ngIf="permissions.roleAllowsUserToViewAdminUsers" routerLink="/admin/users" routerLinkActive="active">
        <i class="fa fa-user"></i>
        <span>User Accounts</span>
      </li>
      <li *ngIf="permissions.roleAllowsUserToViewAdminStores" routerLink="/admin/stores" routerLinkActive="active">
        <i class="fa fa-map-marker-alt"></i>
        <span>Stores</span>
      </li>
      <li *ngIf="permissions.roleAllowsUserToViewAdminStores" routerLink="/admin/store-groups" routerLinkActive="active">
        <i class="far fa-map-marker-alt"></i>
        <span>Store Groups</span>
      </li>
      <li *ngIf="permissions.roleAllowsUserToViewAdminSchedule" routerLink="/admin/schedule" routerLinkActive="active">
        <i class="far fa-calendar-alt"></i>
        <span>Schedule</span>
      </li>
      <li *ngIf="permissions.roleAllowsUserToViewAdminItemFilters" routerLink="/admin/item-filters" routerLinkActive="active">
        <i class="fa fa-filter"></i>
        <span>Item Filters</span>
      </li>
      <li *ngIf="permissions.roleAllowsUserToViewAdminItemLists" routerLink="/admin/item-lists" routerLinkActive="active">
        <i class="fa fa-list-ul"></i>
        <span>Item Lists</span>
      </li>
      <li *ngIf="permissions.roleAllowsUserToViewAdminTagOrders" routerLink="/admin/shelf-tag-orders" routerLinkActive="active">
        <i class="fas fa-tags"></i>
        <span>Shelf Tag Orders</span>
      </li>
      <li *ngIf="permissions.roleAllowsUserToViewAdminTagOrders" routerLink="/admin/tpr-tag-orders" routerLinkActive="active">
        <i class="far fa-tags"></i>
        <span>Compare At Tag Orders</span>
      </li>
      <li *ngIf="permissions.roleAllowsUserToViewAdminPosExport" routerLink="/admin/pos-export" routerLinkActive="active">
        <i class="fa fa-download"></i>
        <span>POS Export</span>
      </li>
      <li *ngIf="permissions.roleAllowsUserToViewAdminPricingRules" routerLink="/admin/pricing-rules" routerLinkActive="active">
        <i class="fa fa-usd-circle"></i>
        <span>Pricing Rules</span>
      </li>
      <li *ngIf="permissions.roleAllowsUserToViewAdminPrivateLabels" routerLink="/admin/private-labels" routerLinkActive="active">
        <i class="fa fa-flag"></i>
        <span>Private Labels</span>
      </li>
      <li title="Announcements" routerLink="/admin/announcements" routerLinkActive="active">
        <i class="fas fa-bullhorn"></i>
        <span>Announcements</span>
      </li>
      <li title="Retail Contacts" routerLink="/admin/retail-notification-contacts" routerLinkActive="active">
        <i class="fas fa-address-book"></i>
        <span>Retail Contacts</span>
      </li>
      <li title="Admin Notification Contacts" routerLink="/admin/admin-notification-contacts" routerLinkActive="active">
        <i class="fas fa-id-badge"></i>
        <span>Admin Contacts</span>
      </li>
      <li title="Application" routerLink="/admin/application" routerLinkActive="active">
        <i class="fa fa-wrench"></i>
        <span>Application</span>
      </li>
    </ul>
  </div>
  <div class="content-with-sidebar">
    <router-outlet></router-outlet>
  </div>
</div>