import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from '../app.config';

@Injectable({
  providedIn: 'root'
})
export class AdminContactsService {

  private _baseUrl = AppConfig.settings.baseUrl;
  private _reqHeader = new HttpHeaders({ 'Accept': 'application/json', 'Content-Type': 'application/json' });

  constructor(
    private _http: HttpClient, 
  ) { }
  
  getAllAdminContacts() {
    return this._http.get(this._baseUrl + '/api/AdminContacts/GetAllAdminContacts', { headers: this._reqHeader });
  }

  getActiveAdminContacts() {
    return this._http.get(this._baseUrl + '/api/AdminContacts/GetActiveAdminContacts', { headers: this._reqHeader });
  }

  getAdminContactById(contactId) {
    return this._http.get(this._baseUrl + '/api/AdminContacts/GetAdminContactById//' + contactId, { headers: this._reqHeader });
  }

  createAdminContact(contact) {
    return this._http.post(this._baseUrl + '/api/AdminContacts/CreateAdminContact', contact, { headers: this._reqHeader });
  }

  setUserAccountBasedAdminContacts(userIds) {
    return this._http.post(this._baseUrl + '/api/AdminContacts/SetUserAccountBasedAdminContacts', userIds, { headers: this._reqHeader });
  }

  updateAdminContact(contactId, contact) {
    return this._http.put(this._baseUrl + '/api/AdminContacts/UpdateAdminContact/' + contactId, contact, { headers: this._reqHeader });
  }

  deleteAdminContact(contactId) {
    return this._http.delete(this._baseUrl + '/api/AdminContacts/DeleteAdminContact/' + contactId, { headers: this._reqHeader });
  }
}
